<div [class]="class" [formGroup]="form" >
  <label [for]="id" [translate]="key"></label>
  <input #range
    type="range"
    [id]="id"
    [formControlName]="controlName"
    [min]="minValue"
    [max]="maxValue"
    [step]="step"
  >
  <span class="range-slider-value" [ngStyle]="sliderStyle">{{ displayValue }}</span>
</div>
