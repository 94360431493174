import { CommercialActionSubjectSuggestionBuilder } from '@/core/models/builder/commercial-action-subject-suggestion.builder';
import { CommercialActionBuilder } from '@/core/models/builder/commercial-action.builder';
import { CommercialAction } from '@/core/models/commercial-action';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const getCommercialActionBody = (
  commercialAction: CommercialAction,
  projectHash: string,
  parentCommercialAction: CommercialAction,
  shareUserHashes: string[],
) => ({
  subject: commercialAction.subject,
  date: commercialAction.date,
  startTime: commercialAction.startTime,
  time: commercialAction.time,
  reminderDate: commercialAction.reminderDate,
  comments: commercialAction.comments,
  commercialActionTypeHash: commercialAction.commercialActionType.hash,
  userHash: commercialAction.user.hash,
  projectHash,
  customerId: commercialAction.customer.id,
  cost: commercialAction.cost,
  contacts: commercialAction.contacts,
  notifiedUserHashes: commercialAction.notifiedUsers?.map(n => n.hash) ?? [],
  parentHash: parentCommercialAction ? parentCommercialAction.hash : null,
  shareUserHashes,
});

@Injectable()
export class CommercialActionApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.apiUrl = `${environment.apiUrl}commercial-actions`;
  }

  getProjectCommercialActions(
    projectHash: string,
    sort: string,
    direction: string
  ): Observable<CommercialAction[]> {
    const params = new HttpParams()
      .append('projectHash', projectHash)
      .append('sort', sort)
      .append('direction', direction);

    return this.httpClient.get(`${this.apiUrl}`, { params })
      .pipe(
        map(CommercialActionBuilder.fromList)
      );
  }

  getMainCommercialActionsFiltered(
    userHash?: string | null,
    customerId?: number | null,
    contactId?: number | null,
    text?: string | null,
    fromDate?: string | null,
    toDate?: string | null,
    includeProjectActions?: boolean | null,
    sort?: string | null,
    direction?: string | null,
  ): Observable<CommercialAction[]> {
    let params = new HttpParams();

    if (userHash) {
      params = params.append('user', userHash);
    }

    if (contactId) {
      params = params.append('contact', contactId.toString());
    }

    if (customerId) {
      params = params.append('customer', customerId.toString());
    }

    if (fromDate) {
      params = params.append('fromDate', fromDate);
    }

    if (toDate) {
      params = params.append('toDate', toDate);
    }

    if (includeProjectActions) {
      params = params.append('includeProjectActions', String(includeProjectActions));
    }

    if (text) {
      params = params.append('text', text);
    }

    if (sort) {
      params = params.append('sort', sort);
    }

    if (direction) {
      params = params.append('direction', direction);
    }

    return this.httpClient.get(`${this.apiUrl}`, { params })
      .pipe(
        map(CommercialActionBuilder.fromList)
      );
  }

  getCommercialActionsExcelFiltered(
    user?: string | null,
    customer?: number | null,
    contact?: number | null,
    text?: string | null,
    fromDate?: string | null,
    toDate?: string | null,
    includeProjectActions?: boolean | null,
    sort?: string | null,
    direction?: string | null
  ) {
    let params = new HttpParams();

    if (user) {
      params = params.append('user', user);
    }

    if (contact) {
      params = params.append('contact', contact.toString());
    }

    if (customer) {
      params = params.append('customer', customer.toString());
    }

    if (fromDate) {
      params = params.append('fromDate', fromDate);
    }

    if (toDate) {
      params = params.append('toDate', toDate);
    }

    if (includeProjectActions) {
      params = params.append('includeProjectActions', String(includeProjectActions));
    }

    if (text) {
      params = params.append('text', text);
    }

    if (sort) {
      params = params.append('sort', sort);
    }

    if (direction) {
      params = params.append('direction', direction);
    }

    const url = `${this.apiUrl}/excel?${params.toString()}`;
    return this.httpClient.get(url, { responseType: 'arraybuffer' });
  }

  getCommercialActionsExcelByProjectHash(hash: string) {
    let params = new HttpParams();
    params = params.append('projectHash', hash);
    const url = `${this.apiUrl}/excel?${params.toString()}`;
    return this.httpClient.get(url, { responseType: 'arraybuffer' });
  }

  shareCommercialActionToUsers(userHashes: string[], commercialActionHash: string): Observable<Object> {
    const putBody = { userHashes };
    return this.httpClient.put(`${this.apiUrl}/${commercialActionHash}/share`, putBody);
  }

  getCommercialAction(hash: string): Observable<CommercialAction> {
    return this.httpClient.get<CommercialAction>(`${this.apiUrl}/${hash}`).pipe(
      map(CommercialActionBuilder.fromJson)
    );
  }

  deleteCommercialAction(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  updateCommercialAction(
    commercialAction: CommercialAction,
    projectHash: string
  ): Observable<CommercialAction> {
    const body = getCommercialActionBody(commercialAction, projectHash, null, null);

    return this.httpClient.put(`${this.apiUrl}/${commercialAction.hash}`, body)
      .pipe(
        map(CommercialActionBuilder.fromJson)
      );
  }

  createCommercialAction(
    commercialAction: CommercialAction,
    projectHash: string,
    parentCommercialAction: CommercialAction,
    shareUserHashes: string[]
  ): Observable<CommercialAction> {
    const body = getCommercialActionBody(commercialAction, projectHash, parentCommercialAction, shareUserHashes);

    return this.httpClient.post(this.apiUrl, body)
      .pipe(
        map(CommercialActionBuilder.fromJson)
      );
  }

  getProjectGroupCommercialActions(projectGroupHash: string): Observable<CommercialAction[]> {
    return this.httpClient.get(`${this.apiUrl}/project-group/${projectGroupHash}`)
      .pipe(
        map(CommercialActionBuilder.fromList)
      );
  }

  getCommercialActionSubjectSuggestions(): Observable<string[]> {
    return this.httpClient.get(`${this.apiUrl}/subject-suggestions`)
      .pipe(
        map(CommercialActionSubjectSuggestionBuilder.fromList)
      );
  }
}
