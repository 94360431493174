import { mapCollection } from '../../utils/collection.utils';
import { Specification } from '../specification';

export class SpecificationBuilder {
  static fromJson(json: any): Specification {
    return (json)
      ? new Specification(
        json.hash,
        json.name
      )
      : null;
  }

  static fromList(list: any): Specification[] {
    return Array.isArray(list)
      ? mapCollection(SpecificationBuilder.fromJson, list)
      : [];
  }
}
