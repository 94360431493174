import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpecificationBuilder } from 'src/app/core/models/builder/specification.builder';
import { Specification } from 'src/app/core/models/specification';
import { environment } from 'src/environments/environment';

const getSpecificationBody = (specification: Specification) => ({
  name: specification.name,
});

@Injectable()
export class SpecificationApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}specifications`;
  }

  getSpecification(hash: string): Observable<Specification> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(SpecificationBuilder.fromJson)
      );
  }

  getSpecifications(): Observable<Specification[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(SpecificationBuilder.fromList)
      );
  }

  deleteSpecification(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  updateSpecification(specification: Specification): Observable<Specification> {
    return this.httpClient.put(`${this.apiUrl}/${specification.hash}`, getSpecificationBody(specification))
      .pipe(
        map(SpecificationBuilder.fromJson)
      );
  }

  createSpecification(specification: Specification): Observable<Specification> {
    return this.httpClient.post(this.apiUrl, getSpecificationBody(specification))
      .pipe(
        map(SpecificationBuilder.fromJson)
      );
  }
}
