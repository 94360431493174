import { Directive, ElementRef, NgZone, OnInit } from '@angular/core';
import { NetworkStatusService } from '@/core/services/network/network-status.service';

@Directive({
  selector: '[appHideWhenOffline]'
})
export class HideWhenOfflineDirective implements OnInit {

  constructor(
    private elementRef: ElementRef,
    private networkStatus: NetworkStatusService,
    private ngZone: NgZone // Inyecta NgZone
  ) { }

  ngOnInit() {
    this.ngZone.run(() => { // Usa NgZone para asegurar la detección de cambios
      if (!navigator.onLine) {
        this.elementRef.nativeElement.style.display = 'none';
      }

      this.networkStatus.isOnline.subscribe(isOnline => {
        if (isOnline) {
          this.elementRef.nativeElement.style.display = '';
        } else {
          this.elementRef.nativeElement.style.display = 'none';
        }
      });
    });
  }
}
