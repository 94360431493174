<form [formGroup]="form" (submit)="onSubmit()" class="form">
  <div class="fields">
    <div class="field">
      <app-input-email-field [form]="form" [id]="'email'" [controlName]="'email'" [key]="'auth.recoverPassword.email'"
        [hideLabel]="true"></app-input-email-field>
    </div>
    <div class="actions">
      <button class="button button-dark" type="submit" translate>auth.recoverPassword.recover</button>
    </div>

    <p class="recover-password">
      <a [routerLink]="['/']" translate>auth.recoverPassword.login</a>
    </p>
  </div>
</form>