import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApplicationBuilder } from '../../models/builder/application.builder';
import { map } from 'rxjs/operators';
import { Application } from '../../models/application';


const getApplicationBody = (application: Application) => ({
  name: application.name
});

@Injectable()
export class ApplicationApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}applications`;
  }

  getApplications() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(ApplicationBuilder.fromList)
      );
  }

  getApplication(hash: string) {
    return this.httpClient.get(`${ this.apiUrl }/${ hash }`)
      .pipe(
        map(ApplicationBuilder.fromJson)
      );
  }

  deleteApplication(hash: string) {
    return this.httpClient.delete(`${ this.apiUrl }/${ hash }`);
  }

  updateApplication(application: Application) {
    return this.httpClient.put(`${ this.apiUrl }/${ application.hash }`, getApplicationBody(application));
  }

  createApplication(application: Application) {
    return this.httpClient.post(this.apiUrl, getApplicationBody(application))
      .pipe(
        map(ApplicationBuilder.fromJson)
      );
  }
}
