import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fullscreen-modal',
  templateUrl: './fullscreen-modal.component.html',
})
export class FullscreenModalComponent {
  @Input() isVisible: boolean = false;

  open() {
    this.isVisible = true;
  }
  close() {
    this.isVisible = false;
  }
}
