import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Process } from '../../../core/models/process';
import { ProcessService } from './processes.service';

@Injectable()
export class ProcessesResolver implements Resolve<Process[]> {
  constructor(private processService: ProcessService) {
  }

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Process[]> | Promise<Process[]> | Process[] {
    return this.processService.resolveProcesses();
  }
}
