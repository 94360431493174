export const checkIfIsPdf = (extension: string) => {
  if (extension === 'pdf') {
    return true;
  }
};

export const getExtension = (path) => {
  return path.split('.').pop();
};

export const checkIfIsImage = (extension: string) => {
  const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'svg', 'webp', 'apng', 'svg', 'bmp', 'heic'];
  if (imageExtensions.includes(extension)) {
    return true;
  }
};

export const checkIfIsHeic = (extension: string) => {
  return extension === 'heic';
};

export const checkIfIsPng = (extension: string) => {
  if (extension === 'png') {
    return true;
  }
};

export const sanitizeFolderName = (folderName: string): string =>
  String(folderName).replace(/<[^>]+>/gm, '');

export const sanitizeFolderPath = (folderPath: string): string =>
  folderPath.replace(/[/]/g, '-');
