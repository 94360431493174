import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SectorBuilder } from '../../models/builder/sector.builder';
import { map } from 'rxjs/operators';

@Injectable()
export class SectorApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}sectors`;
  }


  getSectors() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(SectorBuilder.fromList)
      );
  }

  getSector(hash: string) {
    return this.httpClient.get(`${environment.apiUrl}/${ hash }`)
      .pipe(
        map(SectorBuilder.fromJson)
      );
  }

}
