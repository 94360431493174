import { Customer } from './customer';

export class CustomerGroup {
  constructor(
    public hash?: string,
    public userHash?: string,
    public userName?: string,
    public code?: string,
    public description?: string,
    public customers?: Customer[],
  ) { }

  clone() {
    return new CustomerGroup(
      this.hash,
      this.userHash,
      this.userName,
      this.code,
      this.description,
      this.customers,
    );
  }
}
