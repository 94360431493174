import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { PieceType } from '../../models/piece-type';
import { PieceTypeBuilder } from '../../models/builder/piece-type.builder';

const getPieceTypeBody = (pieceType: PieceType) => ({
  name: pieceType.name,
});

@Injectable()
export class PieceTypeApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}piecetypes`;
  }

  getPieceType(hash: string) {
    return this.httpClient.get(`${ this.apiUrl }/${ hash }`)
      .pipe(
        map(PieceTypeBuilder.fromJson)
      );
  }

  getPieceTypes() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(PieceTypeBuilder.fromList)
      );
  }

  deletePieceType(hash: string) {
    return this.httpClient.delete(`${ this.apiUrl }/${ hash }`);
  }

  updatePieceType(pieceType: PieceType) {
    return this.httpClient.put(`${ this.apiUrl }/${ pieceType.hash }`, getPieceTypeBody(pieceType));
  }

  createPieceType(pieceType: PieceType) {
    return this.httpClient.post(this.apiUrl, getPieceTypeBody(pieceType))
      .pipe(
        map(PieceTypeBuilder.fromJson)
      );
  }
}
