import { CommercialActionType } from './commercial-action-type';

export class CommercialActionChild {
  constructor(
    public hash?: string,
    public subject?: string,
    public date?: string,
    public time?: number,
    public comments?: string,
    public commercialActionType?: CommercialActionType,
    public cost?: number,
    public filesCount?: number,
    public parentHash?: string,
  ) { }

  clone(): CommercialActionChild {
    return new CommercialActionChild(
      this.hash,
      this.subject,
      this.date,
      this.time,
      this.comments,
      this.commercialActionType,
      this.cost,
      this.filesCount,
      this.parentHash,
    );
  }

  get commercialActionTypeName(): string {
    return this.commercialActionType != null
      ? this.commercialActionType.name
      : null;
  }
}
