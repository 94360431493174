<main id="page-login">
  <section>
    <article>
      <img alt="" src="assets/images/logo.svg">
      <div>
        <app-recover-password-form *ngIf="!done" (recover)="onRecover($event)"></app-recover-password-form>
        <app-recover-password-done *ngIf="done"></app-recover-password-done>
      </div>
    </article>
  </section>
</main>