import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-search-field',
  templateUrl: './input-search-field.component.html',
})
export class InputSearchFieldComponent {
  @Input() id: string;
  @Input() controlName: string;
  @Input() key: string;
  @Input() form: FormGroup;
  @Input() class = 'field';
  @Input() hideLabel = false;
  @Input() hideIcon = false;
}
