import { Competitor } from '../competitor';
import { mapCollection } from '../../utils/collection.utils';

export class CompetitorBuilder {
  static fromJson(json: any) {
    return (json)
    ? new  Competitor(
        json.hash,
        json.name,
        json.description,
      )
      : null;
  }

  static fromList(list): Competitor[] {
    return Array.isArray(list) ? mapCollection(CompetitorBuilder.fromJson, list) : [];
  }
}
