import { mapCollection } from '../../utils/collection.utils';
import { Province } from '../province';

export class ProvinceBuilder {
  static fromJson(json: any): Province {
    return new Province(
      json.id,
      json.name,
    );
  }

  static fromList(list: any[]): Province[] {
    return (Array.isArray(list))
      ? mapCollection(ProvinceBuilder.fromJson, list)
      : [];
  }
}
