import { ProjectProfitability } from '../project-profitability';

export class ProjectProfitabilityBuilder {
  static fromJson(json: any): ProjectProfitability {
    return json
      ? new ProjectProfitability(
        json.offeredTotal,
        json.invoicedTotal,
        json.deliveryNotesTotal
      )
      : null;
  }
}
