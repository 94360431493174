import { NetworkStatusService } from '@/core/services/network/network-status.service';
import { OfflineCommercialActionService } from '@/core/services/offline/offline-commercial-action.service';
import { OfflineProjectGroupService } from '@/core/services/offline/offline-project-group.service';
import { OfflineStorageService } from '@/core/services/offline/storage/offline-storage.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-connection-status',
  templateUrl: './connection-status.component.html'
})
export class ConnectionStatusComponent implements OnInit, OnDestroy {
  private statusSubscription: Subscription;

  onlineStatus: boolean = true;
  notSyncedCount: number = 0;

  constructor(
    private networkStatus: NetworkStatusService,
    private offlineCommercialActionService: OfflineCommercialActionService,
    private offlineProjectGroupService: OfflineProjectGroupService,
    private offlineStorageService: OfflineStorageService,
  ) { }

  ngOnInit() {
    this.statusSubscription = this.networkStatus.isOnline.subscribe((online: boolean) => {
      this.onlineStatus = online;
      this.updateStoredActionsCount();
    });

    this.updateStoredActionsCount();
  }

  ngOnDestroy() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
    this.updateStoredActionsCount();
  }

  async onSync() {
    await this.offlineCommercialActionService.syncWithRemote();
    await this.updateStoredActionsCount();
    this.removeAllProjectGroupsForOffline();
  }

  private removeAllProjectGroupsForOffline() {
    this.offlineProjectGroupService.setOfflineProjectGroupHashes([]);
  }

  private async updateStoredActionsCount() {
    const notSyncedCount = await this.offlineStorageService.countStoredElementsToSync();
    this.notSyncedCount = notSyncedCount;
  }
}
