import { ProjectInvoice } from '@/core/models/project-invoice';
import { mapCollection } from '@/core/utils/collection.utils';

export class ProjectInvoiceBuilder {
  static fromJson(json: any): ProjectInvoice {
    return json
      ? new ProjectInvoice(
          json.invoiceNumber
        )
      : null;
  }

  static fromList(list: any): ProjectInvoice[] {
    return Array.isArray(list)
      ? mapCollection(ProjectInvoiceBuilder.fromJson, list)
      : [];
  }
}
