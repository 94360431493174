import { CustomerGroupBuilder } from '@/core/models/builder/customer-group.builder';
import { Customer } from '@/core/models/customer';
import { CustomerGroup } from '@/core/models/customer-group';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const getCustomerGroupBody = (customerGroup: CustomerGroup) => ({
  code: customerGroup.code,
  description: customerGroup.description,
  customerIds: customerGroup.customers.map((customer: Customer) => customer.id),
});

@Injectable()
export class CustomerGroupApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}customer-groups`;
  }

  getCustomerGroups(): Observable<CustomerGroup[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(CustomerGroupBuilder.fromList)
      );
  }

  getCustomerGroup(hash: string): Observable<CustomerGroup> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(CustomerGroupBuilder.fromJson)
      );
  }

  deleteCustomerGroup(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  updateCustomerGroup(customerGroup: CustomerGroup): Observable<CustomerGroup> {
    return this.httpClient.put(`${this.apiUrl}/${customerGroup.hash}`, getCustomerGroupBody(customerGroup))
      .pipe(
        map(CustomerGroupBuilder.fromJson)
      );
  }

  createCustomerGroup(customerGroup: CustomerGroup): Observable<CustomerGroup> {
    return this.httpClient.post(this.apiUrl, getCustomerGroupBody(customerGroup))
      .pipe(
        map(CustomerGroupBuilder.fromJson)
      );
  }
}
