import { OFFLINE_PROJECT_GROUP_LIST_KEY } from '@/core/config/offline-constants';
import {
  getOfflineProjectGroupHashes as getHashes,
  setOfflineProjectGroupHashes as setHashes,
} from '@/core/services/storage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfflineProjectGroupService {
  private storageSub = new BehaviorSubject<string | null>(null);

  constructor() { }

  watchStorage(): Observable<string | null> {
    return this.storageSub.asObservable();
  }

  getOfflineProjectGroupHashes(): string[] {
    return getHashes() ?? [];
  }

  setOfflineProjectGroupHashes(projectGroupHashes: string[]): void {
    setHashes(projectGroupHashes);
    this.storageSub.next(OFFLINE_PROJECT_GROUP_LIST_KEY);
  }
}
