import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { PieceType } from '@/core/models/piece-type';
import { NavigationService } from '@/core/services/navigation.service';
import { ToasterService } from '@/core/services/visual/toaster.service';
import { AdminRoutesInterface } from '../../admin-routes.interface';
import { PieceTypesService } from '../piece-types.service';
import { ROUTES } from '@/core/config/constants';

const editSuccessKey = 'admin.pieceTypes.edit.editSuccess';
const createSuccessKey = 'admin.pieceTypes.create.saveSuccess';


@Component({
  selector: 'app-edit-piece-type',
  templateUrl: './edit-piece-type.component.html'
})
export class EditPieceTypeComponent implements OnInit {
  pieceType$: Observable<PieceType>;
  editMode$: Observable<boolean>;
  fieldErrors: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private pieceTypeService: PieceTypesService,
    private toasterService: ToasterService,
    private navigationService: NavigationService

  ) { }

  ngOnInit() {
    this.pieceType$ = this.activeRoute.data.pipe(pluck('pieceType'));
    this.editMode$ = this.activeRoute.data.pipe(pluck('editMode'));
  }


  async onSave(pieceType: PieceType) {
    const editMode = this.activeRoute.snapshot.data['editMode'];
    if (editMode) {
      await this.updatePieceType(pieceType);
    } else {
      await this.createPieceType(pieceType);
    }
    this.navigationService.redirectTo(ROUTES.ADMIN_PIECE_TYPE);
  }

  async updatePieceType(pieceType: PieceType) {
    try {
      await this.pieceTypeService.updatePieceType(pieceType);
      this.toasterService.showSuccessTranslating(editSuccessKey);
    } catch (errors) {
      this.fieldErrors = errors;
    }
  }

  async createPieceType(pieceType: PieceType) {
    try {
      await this.pieceTypeService.createPieceType(pieceType);
      this.toasterService.showSuccessTranslating(createSuccessKey);
    } catch (errors) {
      this.fieldErrors = errors;
    }
  }

  routes(): AdminRoutesInterface[] {
    const editMode = this.activeRoute.snapshot.data['editMode'];

    if (editMode) {
      return [
        {
          title: 'breadCrumbs.pieceTypes',
          link: 'piecetypes',
        },
        {
          title: 'breadCrumbs.edit',
          link: '',
        }
      ];
    } else {
      return [
        {
          title: 'breadCrumbs.pieceTypes',
          link: 'piecetypes',
        },
        {
          title: 'breadCrumbs.create',
          link: '',
        }
      ];
    }
  }
}
