import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PieceType } from '../../../../core/models/piece-type';
import { PieceTypesService } from '../piece-types.service';

@Injectable()
export class PieceTypesResolver implements Resolve<PieceType[]> {

  constructor(private pieceTypesService: PieceTypesService) {
  }

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<PieceType[]> | Promise<PieceType[]> | PieceType[] {
    return this.pieceTypesService.resolvePieceTypes();
  }
}
