import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExpenseType } from '../../config/constants';
import { ReportSerieBuilder } from '../../models/builder/report-serie.builder';
import { map } from 'rxjs/operators';
import { ReportSerie } from '../../models/report-serie';

const updateExpenseBody = (amount: number) => ({
  amount
});

@Injectable()
export class ExpensesApiService {
  private apiUrl: string;
  private expensesUrl: string;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = `${environment.apiUrl}`;
    this.expensesUrl = this.apiUrl + 'expenses';
  }

  getExpensesInYearByMonth(year: number, expenseType: ExpenseType): Observable<number[]> {
    return this.httpClient.get<number[]>(`${this.expensesUrl}/${expenseType}/${year}`);
  }

  getExpenseInYearInMonth(year: number, month: number, expenseType: ExpenseType): Observable<number> {
    return this.httpClient.get<number>(`${this.expensesUrl}/${expenseType}/${year}/${month}`);
  }


  getExpensesBetweenYears(type: ExpenseType, fromYear: number, toYear: number): Observable<ReportSerie> {
    let params = new HttpParams();

    params = params.append('fromYear', fromYear);
    params = params.append('toYear', toYear);

    return this.httpClient.get(`${this.expensesUrl}/${type}/between-years`, { params }).pipe(
      map(ReportSerieBuilder.fromJson)
    );
  }

  updateExpense(type: ExpenseType, year: number, month: number, amount: number) {
    return this.httpClient.post(`${this.expensesUrl}/${type}/${year}/${month}`, updateExpenseBody(amount));
  }
}
