import { PermissionPrivilege } from './permission-privilege';

export class PermissionSubsection {
  constructor(
    public hash?: string,
    public name?: string,
    public code?: string,
    public permissionPrivileges?: PermissionPrivilege[]
  ) {}

  clone() {
    return new PermissionSubsection(
      this.hash,
      this.name,
      this.code,
      this.permissionPrivileges
    );
  }
}
