import { ProjectGroup } from '../project-group';
import { ContactBuilder } from './contact.builder';
import { CustomerBuilder } from './customer.builder';
import { PieceTypeBuilder } from './piece-type.builder';
import { ProjectGroupCommercialBuilder } from './project-group-commercial.builder';
import { ProjectBuilder } from './project.builder';
import { SectorBuilder } from './sector.builder';

export class ProjectGroupBuilder {
    static fromJson(json): ProjectGroup {
        return (json)
            ? new ProjectGroup(
                json.id,
                json.hash,
                json.name,
                CustomerBuilder.fromJson(json.customer),
                ProjectGroupCommercialBuilder.fromList(json.commercials),
                ContactBuilder.fromList(json.contacts),
                SectorBuilder.fromJson(json.sector),
                PieceTypeBuilder.fromJson(json.pieceType),
                json.planeNumber,
                ProjectBuilder.fromList(json.projects),
                json.image,
                json.dropboxFolderId,
                json.pieceName,
                json.createdAt,
                json.statusDate,
                json.favorite,
                json.closeStatus
            )
            : null;
    }

    static fromList(list): ProjectGroup[] {
        return (Array.isArray(list))
            ? list.map(ProjectGroupBuilder.fromJson)
            : [];
    }
}
