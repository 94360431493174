<div
  [class]="class"
  [formGroup]="form"
  *ngIf="form.get(controlName) as control"
  [class.error]="control.touched && control.errors"
  [class.field--required]="hasRequiredField(control)">
  <label [for]="id">
    {{ key | translate }}
    <span class="required" *ngIf="hasRequiredField(control)"></span>
  </label>
  <textarea
    [id]="id"
    [formControlName]="controlName"
    [rows]="rows"
    [cols]="cols"
  ></textarea>
  <div class="box-error" *ngIf="!hideErrors && control.touched">
    <p class="error" *ngIf="control.hasError('required')" translate>error.required</p>
    <p class="error" *ngIf="control.hasError('custom')">{{ control.getError('custom') }}</p>
  </div>
</div>
