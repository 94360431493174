import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToleranceValueBuilder } from 'src/app/core/models/builder/tolerance-value.builder';
import { ToleranceValue } from 'src/app/core/models/tolerance-values';
import { environment } from 'src/environments/environment';

@Injectable()
export class ToleranceValueApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.apiUrl = `${environment.apiUrl}tolerance-values`;
  }

  getToleranceValues(): Observable<ToleranceValue[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(ToleranceValueBuilder.fromList)
      );
  }
}
