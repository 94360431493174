 <div
  [class]="class"
  [formGroup]="form"
  *ngIf="form.get(controlName) as control"
  [class.field--search]="!hideIcon"
  [attr.data-icon]="dataIcon">
  <label *ngIf="!hideLabel" [for]="id">
    {{ key | translate }}
  </label>
  <input
    [id]="id"
    type="text"
    [formControlName]="controlName"
    [placeholder]="(key) ? (key | translate) : ''">
 </div>
