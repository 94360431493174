import { mapCollection } from '../../utils/collection.utils';
import { Sector } from '../sector';


export class SectorBuilder {
  static fromJson(json: any) {
    return (json)
      ? new Sector(
        json.id,
        json.name
      )
      : null;
  }

  static fromList(list): Sector[] {
    return Array.isArray(list) ? mapCollection(SectorBuilder.fromJson, list) : [];
  }
}
