import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ContactBuilder } from '../../models/builder/contact.builder';
import { Contact } from '../../models/contact';

@Injectable()
export class ContactApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}contacts`;
  }

  getContacts(): Observable<Contact[]> {
    return this.httpClient.get(`${this.apiUrl}`)
      .pipe(
        map(ContactBuilder.fromList)
      );
  }
}
