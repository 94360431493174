export class Process {
  public hash?: string;
  public name?: string;
  public isInternal?: boolean;
  public isExternal?: boolean;
  public axialDepthCutVisibility?: boolean;
  public rollerRadiusVisibility?: boolean;

  constructor(process: Partial<Process>) {
    Object.assign(this, process);
  }

  clone() {
    return new Process({
      hash: this.hash,
      name: this.name,
      isInternal: this.isInternal,
      isExternal: this.isExternal,
      axialDepthCutVisibility: this.axialDepthCutVisibility,
      rollerRadiusVisibility: this.rollerRadiusVisibility,
    });
  }
}
