import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DROPBOX_API_URL } from '../../config/constants';
import { FolderBuilder } from '../../models/builder/folder.builder';
import { Folder } from '../../models/folder';
import { DropboxCopyFolderResponse } from '../../responses/DropboxCopyFolderResponse';
import { sanitizeFolderName } from '../../utils/document.utils';
import { encodeDropboxUrl } from '../../utils/url.utils';

@Injectable()
export class FolderApiService {
  private apiUrl: string;
  private dropboxApiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}folder`;
    this.dropboxApiUrl = DROPBOX_API_URL;
  }

  createFolder(folderName: string, folderPath: string) {
    const postBody = new FormData();
    postBody.append('folderName', sanitizeFolderName(folderName));
    postBody.append('folderPath', folderPath);
    return this.httpClient.post(this.apiUrl, postBody)
      .pipe(
        map(FolderBuilder.fromJson)
      );
  }

  getFolders(folderPath: string): Observable<Folder[]> {
    const params = new HttpParams().set('folderPath', folderPath);
    const encodedUrl = `${this.apiUrl}?${encodeDropboxUrl(params)}`;

    return this.httpClient.get(encodedUrl).pipe(
      map(FolderBuilder.fromList)
    );
  }

  getFolder(id: string): Observable<Folder> {
    return this.httpClient.get(`${this.apiUrl}/${id}`)
      .pipe(
        map(FolderBuilder.fromJson)
      );
  }

  copyFolder(fromPath: string, toPath: string): Observable<DropboxCopyFolderResponse> {
    return this.httpClient.post<DropboxCopyFolderResponse>(
      `${this.dropboxApiUrl}/files/copy_v2`, { from_path: fromPath, to_path: toPath, autorename: true }
    );
  }

  deleteFolder(path: string) {
    return this.httpClient.post(`${this.dropboxApiUrl}/files/delete_v2`, { path });
  }

  deleteFolders(paths: string[]) {
    const entries = paths.map(path => ({ path }));
    return this.httpClient.post(`${this.dropboxApiUrl}/files/delete_batch`, { entries });
  }
}
