<ngx-smart-modal
  #modal
  identifier="modal"
  [customClass]="modalClass"
  [closable]="false"
  [dismissable]="dismissable"
  [escapable]="escapable"
  [hideDelay]="0"
  (onClose)="onClose()"
  (onDismiss)="onClose()"
  (onEscape)="onClose()"
>
  <div class="modal-header" *ngIf="!hideHeader">
    <h4 *ngIf="title">{{ title | translate }}</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" class="form">
      <div class="container">
        <div class="fields">
          <div class="field">
            <app-input-password-field
              [id]="'password'"
              [form]="form"
              [controlName]="'password'"
              [key]="'admin.users.edit.password'"
            ></app-input-password-field>
          </div>
          <div class="field">
            <app-input-password-field
              [id]="'passwordRepeat'"
              [form]="form"
              [controlName]="'passwordRepeat'"
              [key]="'admin.users.edit.passwordRepeat'"
            ></app-input-password-field>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="button" (click)="close()" [innerHTML]="cancelLabel | translate"></button>
    <button type="button" class="button button-primary" [disabled]="form.invalid" (click)="onConfirm()" [innerHTML]="confirmLabel | translate"></button>
  </div>
</ngx-smart-modal>
