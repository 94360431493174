<form class="form" [formGroup]="form" (submit)="onSubmit()">
  <div class="fields">
    <app-input-email-field
      [id]="'email'"
      [form]="form"
      [controlName]="'email'"
    ></app-input-email-field>
    <app-input-password-field
      [id]="'password'"
      [form]="form"
      [controlName]="'password'"
    ></app-input-password-field>
    <div appHideWhenOffline class="actions">
      <button type="submit" class="button button-dark" [disabled]="form.invalid" translate>auth.login.button</button>
    </div>
    <p appHideWhenOffline class="recover-password">
      <a [routerLink]="['/recover-password']" translate>auth.login.recoverPassword</a>
    </p>
  </div>
</form>
