<table #table>
  <thead>
    <tr>
      <th
        *ngFor="let column of columns"
        appFormColumn
        [title]="column.title"
        [icon]="column.icon"
        [style.width]="column.width"
        [style.min-width]="column.minWidth"
      ></th>
    </tr>
  </thead>
  <tbody class="scroll" perfectScrollbar>
    <tr *ngFor="let element of elements.controls; index as i" [ngClass]="getRowClass(element)">
      <td
        *ngFor="let column of columns"
        [style.width]="column.width"
        [style.min-width]="column.minWidth"
        [ngClass]="column.styleColumn && column.styleColumn(element)"
        appTitle
      >
        <a *ngIf="column.linkable; else cell" (click)="column.clicked.emit(element)">
          <app-form-cell
            [column]="column"
            [element]="element"
            [index]="i"
          ></app-form-cell>
        </a>
        <ng-template #cell>
          <app-form-cell
            [column]="column"
            [element]="element"
            [index]="i"
          ></app-form-cell>
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>
