import { Offer } from '../offer';
import { OfferLineBuilder } from './offer-line.builder';

export class OfferBuilder{
  static fromJson(json: any): Offer {
    return json ?
      new Offer(
        json.number,
        OfferLineBuilder.fromList(json.offerLines)
      ) : null;
  }
}
