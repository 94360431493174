<form [formGroup]="form" (submit)="onSubmitted()" class="form">
  <div class="fields">
    <div class="field">
        <label for="">Contraseña</label>
    <app-input-password-field
      [form]="form"
      [id]="'password'"
      [controlName]="'password'"
      [key]="'auth.resetPassword.password'"
      [class]="'field'"
      [hideLabel]="true"
    ></app-input-password-field>
    </div>
    <div class="field">
      <label for="">Repetir contraseña</label>
      <app-input-password-field
      [form]="form"
      [id]="'passwordRepeat'"
      [controlName]="'passwordRepeat'"
      [key]="'auth.resetPassword.passwordRepeat'"
      [class]="'field'"
      [hideLabel]="true"
    ></app-input-password-field>
    </div>
    <div  class="actions">
      <button type="submit" class="button button-dark" [disabled]="form.invalid" translate>auth.resetPassword.submit</button>
  </div>
  </div>
</form>
