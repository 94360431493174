<section id="not-found-404">
  <div class="not-found-container">
    <img src="assets/images/logo.svg" alt="" class="logo">
    <img src="assets/images/404.png" alt="" class="img-404">
    <h3 class="title" translate>error.notFound.title</h3>
    <p class="subtitle">
      <span (click)="onClick()" class="pointer" translate>error.notFound.subtitle</span>
    </p>
  </div>
</section>
