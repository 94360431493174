import { mapCollection } from '../../utils/collection.utils';
import { Contact } from '../contact';

export class ContactBuilder {
  static fromJson(json): Contact {
    return (json)
      ? new Contact(
        json.id,
        json.name
      )
      : null;
  }

  static fromList(list): Contact[] {
    return Array.isArray(list)
      ? mapCollection(ContactBuilder.fromJson, list)
      : [];
  }
}
