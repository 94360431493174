import { Hardness } from './hardness';
import { Machine } from './machine';
import { Material } from './material';
import { MaterialDenomination } from './material-denomination';
import { MaterialIso } from './material-iso';
import { PreMachinedMethod } from './pre-machined-method';
import { Project } from './project';
import { Specification } from './specification';

export class MachiningStudy {
  constructor(
    public hash?: string,
    public project?: Project,
    public machine?: Machine,
    public stability?: string,
    public isStabilityOk?: boolean,
    public machineComments?: string,
    public lubricantType?: string,
    public emulsionPercentage?: number,
    public internalCooling?: boolean,
    public refrigerantPressure?: number,
    public lubricantComments?: string,
    public material?: Material,
    public materialDenomination?: MaterialDenomination,
    public materialIso?: MaterialIso,
    public materialIsoOld?: string,
    public hardness?: Hardness,
    public hardnessValue?: number,
    public specification?: Specification,
    public noOfParts?: number,
    public boresPerPart?: number,
    public totalBores?: number,
    public pieceDescription?: string,
    public preMachinedMethod?: PreMachinedMethod,
    public preMachinedDiameter?: number,
    public preMachinedRoughnessType?: string,
    public preMachinedRoughnessValue?: number,
    public cuttingDiameter?: number,
    public cuttingDepth?: number,
    public toleranceModeSimple?: boolean,
    public tolerancePositionSimple?: string,
    public toleranceQualitySimple?: number,
    public tolerancePosition?: string,
    public toleranceQuality?: string,
    public tolerance?: number,
    public holeType?: string,
    public toolLength?: number,
    public additionalRequirements?: string,
    public interruptedCut?: boolean,
    public interruptedCutComments?: string,
    public targetTime?: number,
    public targetQuantity?: number,
    public roughnessType?: string,
    public roughnessValue?: number,
    public cycleTime?: number,
    public pieceRequirementsComments?: string,
    public createdAt?: string,
  ) { }

  clone(): MachiningStudy {
    return new MachiningStudy(
      this.hash,
      this.project,
      this.machine,
      this.stability,
      this.isStabilityOk,
      this.machineComments,
      this.lubricantType,
      this.emulsionPercentage,
      this.internalCooling,
      this.refrigerantPressure,
      this.lubricantComments,
      this.material,
      this.materialDenomination,
      this.materialIso,
      this.materialIsoOld,
      this.hardness,
      this.hardnessValue,
      this.specification,
      this.noOfParts,
      this.boresPerPart,
      this.totalBores,
      this.pieceDescription,
      this.preMachinedMethod,
      this.preMachinedDiameter,
      this.preMachinedRoughnessType,
      this.preMachinedRoughnessValue,
      this.cuttingDiameter,
      this.cuttingDepth,
      this.toleranceModeSimple,
      this.tolerancePositionSimple,
      this.toleranceQualitySimple,
      this.tolerancePosition,
      this.toleranceQuality,
      this.tolerance,
      this.holeType,
      this.toolLength,
      this.additionalRequirements,
      this.interruptedCut,
      this.interruptedCutComments,
      this.targetTime,
      this.targetQuantity,
      this.roughnessType,
      this.roughnessValue,
      this.cycleTime,
      this.pieceRequirementsComments,
      this.createdAt,
    );
  }
}
