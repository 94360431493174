import { mapCollection } from '../../utils/collection.utils';
import { Customer } from '../customer';

export class CustomerBuilder {
  static fromJson(json: any): Customer {
    return (json)
      ? new Customer(
          json.id,
          json.name,
          json.contactPerson,
          json.phone,
          json.email,
          json.zone,
          json.city,
          json.interested,
          json.province,
        )
      : null;
  }

  static fromList(list: any): Customer[] {
    return (Array.isArray(list))
      ? mapCollection(CustomerBuilder.fromJson, list)
      : [];
  }
}
