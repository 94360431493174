import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { MaterialBuilder } from '../../models/builder/material.builder';
import { Material } from '../../models/material';

const getMaterialBody = (material: Material) => ({
  group: material.group,
  subgroup: material.subgroup,
  denomination: material.denomination
});

@Injectable()
export class MaterialApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}materials`;
  }

  getMaterial(hash: string) {
    return this.httpClient.get(`${ this.apiUrl }/${ hash }`)
      .pipe(
        map(MaterialBuilder.fromJson)
      );
  }

  getMaterials() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(MaterialBuilder.fromList)
      );
  }

  deleteMaterial(hash: string) {
    return this.httpClient.delete(`${ this.apiUrl }/${ hash }`);
  }

  updateMaterial(material: Material) {
    return this.httpClient.put(`${ this.apiUrl }/${ material.hash }`, getMaterialBody(material));
  }

  createMaterial(material: Material) {
    return this.httpClient.post(this.apiUrl, getMaterialBody(material))
      .pipe(
        map(MaterialBuilder.fromJson)
      );
  }
}
