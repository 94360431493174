import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ToleranceValue } from '../models/tolerance-values';
import { ToleranceValueApiService } from './api/tolerance-value-api.service';
import { ResolverService } from './base/resolver.service';

@Injectable()
export class ToleranceValueService {
  constructor(
    private toleranceValueApiSercive: ToleranceValueApiService,
    private resolverService: ResolverService
  ) { }

  getToleranceValues(): Observable<ToleranceValue[]> {
    return this.toleranceValueApiSercive.getToleranceValues();
  }

  resolveToleranceValues(): Observable<ToleranceValue[]> {
    this.resolverService.onResolverStart();
    return this.getToleranceValues()
      .pipe(
        catchError(() => of(null)),
        finalize(this.resolverService.onResolverStop)
      );
  }
}
