import { Province } from './province';

export class Customer {
  constructor(
    public id?: number,
    public name?: string,
    public contactPerson?: string,
    public phone?: string,
    public email?: string,
    public zone?: string,
    public city?: string,
    public interested?: boolean,
    public province?: Province,
  ) { }

  clone(): Customer {
    return new Customer(
      this.id,
      this.name,
      this.contactPerson,
      this.phone,
      this.email,
      this.zone,
      this.city,
      this.interested,
      this.province,
    );
  }
}
