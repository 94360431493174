import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { ACCESS_LEVEL_READ } from '../../../../core/config/access-level-constants';
import { PERMISSION_PIECE_TYPES } from '../../../../core/config/permission-constants';
import { PieceType } from '../../../../core/models/piece-type';
import { ToasterService } from '../../../../core/services/visual/toaster.service';
import { sortByNumberOrString } from '../../../../core/utils/collection.utils';
import { ConfirmModalComponent } from '../../../../shared/components/modals/confirm-modal/confirm-modal.component';
import { AdminRoutesInterface } from '../../admin-routes.interface';
import { PieceTypesService } from '../piece-types.service';

const deleteSuccessKey = 'admin.pieceTypes.delete.deleteSuccess';


@Component({
  selector: 'app-piece-types-table',
  templateUrl: './piece-types-table.component.html'
})
export class PieceTypesTableComponent implements OnInit {
  pieceTypes$: Observable<PieceType[]>;
  sortField = 'date';
  sortReversed = false;
  private pieceTypeToDelete: PieceType;
  accessLevel = ACCESS_LEVEL_READ;
  permission = PERMISSION_PIECE_TYPES;

  @ViewChild(ConfirmModalComponent) deletePieceTypeModal: ConfirmModalComponent;

  constructor(
    private activeRoute: ActivatedRoute,
    private pieceTypesService: PieceTypesService,
    private toasterService: ToasterService,
    private router: Router
  ) { }

  ngOnInit() {
    this.pieceTypes$ = this.activeRoute.data.pipe(pluck('pieceTypes'));
  }

  onEdit(pieceType: PieceType) {
    if (pieceType && pieceType.hash) {
      this.router.navigate([pieceType.hash], { relativeTo: this.activeRoute });
    }
  }

  async onDeleteConfirmed() {
    try {
      await this.pieceTypesService.deletePieceType(this.pieceTypeToDelete.hash);
      this.toasterService.showSuccessTranslating(deleteSuccessKey);
      this.pieceTypes$ = this.getPieceTypes();
    } catch (errors) {
      return;
    }
  }

  private getPieceTypes(): Observable<PieceType[]> {
    return this.pieceTypesService.resolvePieceTypes();
  }

  onDelete(pieceType: PieceType) {
    if (pieceType) {
      this.pieceTypeToDelete = pieceType;
      this.deletePieceTypeModal.open();
    }
  }

  routes(): AdminRoutesInterface[] {
    return [
      {
        title: 'breadCrumbs.pieceTypes',
        link: 'piecetypes'
      }
    ];
  }

  resetSortDirection() {
    this.sortReversed = false;
  }

  toggleSortDirection() {
    this.sortReversed = !this.sortReversed;
  }

  async sortTable(fieldName: string) {
    if (fieldName === this.sortField) {
      this.toggleSortDirection();
    } else {
      this.resetSortDirection();
    }
    this.sortField = fieldName;

    this.pieceTypes$ = this.pieceTypes$
      .pipe(
        map(items => {
          return sortByNumberOrString(fieldName, this.sortReversed, items);
        })
      );
  }
}
