import { ProjectInvoiceBuilder } from '@/core/models/builder/project-invoice.builder';
import { ProjectInvoice } from '@/core/models/project-invoice';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class InvoiceApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}invoices`;
  }

  getInvoicesByNumbers(projectInvoices: number[]): Observable<ProjectInvoice[]> {
    const filteredInvoices = projectInvoices.filter(invoice => invoice !== undefined && invoice !== null);
    const params: HttpParams = new HttpParams()
      .set('invoiceNumbers', filteredInvoices.join(','));

    return this.httpClient.get(`${this.apiUrl}/by-numbers`, { params })
      .pipe(
        map(ProjectInvoiceBuilder.fromList)
      );
  }
}
