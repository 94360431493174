const server = 'https://api.dev.iraupen.diversius.com/';

export const environment = {
  production: false,
  apiUrl: `${server}v1/`,
  imageUrl: `${server}images/`,
  toasterTimeout: 6000,
  pageElements: 8,
  excelMaxSize: 32, // In MBs
  camMaxSize: 32, // In MBs
  dropboxToken: 'RgyNgXed84cAAAAAAAAAAVguIA5w6nnejJvSIopPVdUB1dwScXN5KuLfoZI56PzH',
  enableServiceWorker: true,
  connectionCheckUrl: 'https://app.dev.iraupen.diversius.com/',
};
