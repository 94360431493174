import { ToleranceValue } from 'src/app/core/models/tolerance-values';
import { mapCollection } from 'src/app/core/utils/collection.utils';

export class ToleranceValueBuilder {
  static fromJson(json: any): ToleranceValue {
    return (json)
      ? new ToleranceValue(
          json.minDiameter,
          json.maxDiameter,
          json.toleranceQuality,
          json.tolerancePosition,
          json.value,
        )
      : null;
  }

  static fromList(list: any): ToleranceValue[] {
    return Array.isArray(list)
      ? mapCollection(ToleranceValueBuilder.fromJson, list)
      : [];
  }
}
