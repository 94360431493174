export class Folder {
  constructor(
    public name?: string,
    public path?: string,
    public id?: string

  ) { }

  clone() {
    return new Folder(
      this.name,
      this.path,
      this.id
    );
  }
}
