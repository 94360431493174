import { TrialLineFileBuilder } from '@/core/models/builder/trial-line-file.builder';
import { TrialLineFile } from '@/core/models/trial-line-file';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class TrialLineFileApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}trial-line-files`;
  }

  getTrialLineFiles(trialLineHash: string): Observable<TrialLineFile[]> {
    const params = new HttpParams()
      .append('trialLineHash', trialLineHash);

    return this.httpClient.get(`${this.apiUrl}`, { params })
      .pipe(
        map(TrialLineFileBuilder.fromList)
      );
  }

  uploadTrialLineFile(file: File, trialLineHash: string) {
    const formData = new FormData();
    formData.append('trialLineHash', trialLineHash);
    formData.append('file', file);

    return this.httpClient.post<FormData>(`${this.apiUrl}`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
  deleteTrialLineFile(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }
}
