import { Machine } from '../machine';
import { mapCollection } from '../../utils/collection.utils';

export class MachineBuilder {
  static fromJson(json: any) {
    return (json)
    ? new Machine(
        json.hash,
        json.name,
        json.power,
        json.type,
        json.spindleClamp,
        json.restrictions,
        json.isHorizontal,
        json.isVertical,
        json.isRotative,
        json.noOfAxis
      )
      : null;
  }

  static fromList(list): Machine[] {
    return Array.isArray(list)
      ? mapCollection(MachineBuilder.fromJson, list)
      : [];
  }
}
