<ngx-smart-modal
  #modal
  identifier="modal"
  [customClass]="modalClass"
  [closable]="false"
  [dismissable]="dismissable"
  [escapable]="escapable"
  [hideDelay]="0"
  (onClose)="onClose()"
  (onDismiss)="onClose()"
  (onEscape)="onClose()"
>
  <div class="modal-header" *ngIf="!hideHeader">
    <h4 *ngIf="title">{{ title | translate }}</h4>
  </div>

  <div class="modal-body">
    <p class="info" [innerHTML]="message | translate: messageParams"></p>
  </div>

  <div class="modal-footer">
    <button type="button" class="button" (click)="close()" [innerHTML]="cancelLabel | translate"></button>
    <button type="button" class="button button-primary" (click)="onConfirm()" [innerHTML]="confirmLabel | translate"></button>
  </div>
</ngx-smart-modal>
