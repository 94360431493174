import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { PieceTypesModule } from './dashboard/admin/piece-types/piece-types.module';
import { ProcessesModule } from './dashboard/admin/processes/processes.module';
import { NotificationsService } from './dashboard/notifications/notifications.service';
import { MachiningStudyService } from './dashboard/projects/analysis/machining-study/machining-study.service';
import {
  ProjectHeaderSharedService
} from './dashboard/projects/projects-shared/components/project-header-shared/project-header-shared.service';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    NgChartsModule,
    PieceTypesModule,
    ProcessesModule,
  ],
  providers: [
    MachiningStudyService,
    NotificationsService,
    ProjectHeaderSharedService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
