import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { AuthService } from '../../../../auth/auth.service';
import { User } from '../../../../core/models/User';
import { ToasterService } from '../../../../core/services/visual/toaster.service';
import { fieldsMatch } from '../../../../core/validators/fields-match.validator';


@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css']
})
export class ChangePasswordModalComponent implements OnInit {
  form: FormGroup;
  @Input() user: User;
  @Input() title: string;
  @Input() message: string;
  @Input() messageParams = {};
  @Input() confirmLabel = 'shared.confirm.confirm';
  @Input() cancelLabel = 'shared.confirm.cancel';
  @Input() hideHeader = false;
  @Input() dismissable = true;
  @Input() escapable = true;
  @Input() modalClass = 'modal--confirmation';
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @ViewChild(NgxSmartModalComponent) modal: NgxSmartModalComponent;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      passwordRepeat: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16),
          fieldsMatch('password')
        ]
      ]
    });
  }

  open() {
    this.modal.open();
  }

  close() {
    this.modal.close();
  }

  onClose() {
    this.cancel.emit();
  }

  async onConfirm() {
    try {
      const { password, passwordRepeat } = this.form.value;
      await this.authService.changePassword(this.user.hash, password, passwordRepeat);
      this.toasterService.showSuccessTranslating('admin.users.edit.changePasswordSuccess');
    } catch (e) {
      this.toasterService.showErrorTranslating('admin.users.edit.changePasswordError');
    }
    this.form.reset();
    this.close();
  }
}
