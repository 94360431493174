import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDaysAgo'
})
export class LocalizedDaysAgoPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(value: Date | string | number): string {
    if (!value) {
      return this.translate.instant('date.noDateProvided');
    }

    const date = new Date(value);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (isNaN(days)) {
      return this.translate.instant('date.invalidDate');
    } else if (days === 0) {
      return this.translate.instant('date.today');
    } else if (days === 1) {
      return this.translate.instant('date.yesterday');
    } else {
      return this.translate.instant('date.daysAgo', { days });
    }
  }
}
