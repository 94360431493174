import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { Document } from '../../../../core/models/document';
import { DropboxTemporaryUrlResponse } from '../../../../core/responses/DropboxGetTemporaryUrlResponse';
import { checkIfIsHeic, checkIfIsImage, checkIfIsPdf, getExtension } from '../../../../core/utils/document.utils';
import { arrayBufferToBase64, toBlob } from '../../../../core/utils/file.utils';
import { isEmpty } from '../../../../core/utils/object.utils';
import { DocumentsService } from '../../../../dashboard/documents/documents.service';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.css']
})
export class PreviewModalComponent implements OnChanges {
  @Input() dismissable = true;
  @Input() escapable = true;
  @Input() modalClass = 'modal--preview';
  @Input() document: Document;
  @Input() carouselLength: number;
  @Input() currentDocumentIndex: number;
  @Output() nextPreview = new EventEmitter<void>();
  @Output() previousPreview = new EventEmitter<void>();
  @ViewChild(NgxSmartModalComponent) modal: NgxSmartModalComponent;

  isPdf = false;
  isImage = false;
  isHeic = false;
  previewIsUnavailable = false;
  fakeThumbnail: SafeUrl;
  previewDocument: DropboxTemporaryUrlResponse;
  zoomAmount = 1;

  constructor(
    private documentsService: DocumentsService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnChanges() {
    this.resetPreview();

    if (!isEmpty(this.document)) {
      this.checkExtension();

      if (this.isHeic) {
        this.getBigThumbnailAsPreview();
      } else {
        this.getTemporaryDocumentUrl();
      }
    }
  }

  open() {
    this.modal.open();
  }

  close() {
    this.modal.close();
  }

  async onDownload() {
    await this.downloadDocumentPdf(this.document.path);
    this.modal.close();
  }

  getImageZoom() {
    return this.zoomAmount;
  }

  onZoomIn() {
    this.zoomAmount += 1;
  }

  onZoomOut() {
    this.zoomAmount -= 1;
  }

  private checkExtension() {
    const ext = getExtension(this.document.path);
    this.isImage = checkIfIsImage(ext);
    this.isPdf = checkIfIsPdf(ext);
    this.isHeic = checkIfIsHeic(ext);
  }

  private async downloadDocumentPdf(path: string) {
    const pdf = await this.documentsService.downloadDocument(path);
    saveAs(toBlob(pdf), path);
  }

  private async getTemporaryDocumentUrl() {
    this.previewDocument = await this.documentsService.getTemporaryUrl(this.document.path);
    this.getInitialImageSize();
  }

  private async getBigThumbnailAsPreview() {
    try {
      const response = await this.documentsService.getBigThumbnail(this.document.path);
      this.fakeThumbnail = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + arrayBufferToBase64(response));
      this.getInitialImageSize();
    } catch (e) {
      console.log(`Could not get big thumbnail for document ${this.document.name}`);
      this.previewIsUnavailable = true;
    }
  }

  private getInitialImageSize() {
    const img = new Image();
    img.onload = () => {
      const initialZoom = (img.width / window.innerWidth) * 100;
      this.zoomAmount = initialZoom > 100 ? 100 : initialZoom;
    };

    img.src = this.isHeic ? this.fakeThumbnail['changingThisBreaksApplicationSecurity'] : this.previewDocument.link;
  }

  private resetPreview() {
    this.isPdf = false;
    this.isImage = false;
    this.isHeic = false;
    this.previewIsUnavailable = false;
    this.previewDocument = undefined;
    this.fakeThumbnail = undefined;
    this.zoomAmount = 1;
  }

  onNextPreview() {
    this.nextPreview.emit();
  }

  onPreviousPreview() {
    this.previousPreview.emit();
  }

  thereArePreviousDocuments() {
    return !this.carouselLength || this.currentDocumentIndex === 0;
  }

  thereAreNextDocuments() {
    return !this.carouselLength || this.currentDocumentIndex === this.carouselLength - 1;
  }
}
