import {
  NOTIFICATION_TYPE_COMMERCIAL_ACTION,
  NOTIFICATION_TYPE_INTERNAL_ACTION,
  NOTIFICATION_TYPE_PROJECT_WITHOUT_SUCCESSFUL_TRIAL,
  NOTIFICATION_TYPE_PROJECT_WITHOUT_INVOICE,
  NOTIFICATION_TYPE_PROJECT_WITHOUT_OFFER,
  NOTIFICATION_TYPE_WORK_GROUP,
  NOTIFICATION_TYPE_COMMERCIAL_ACTION_REMINDER,
  NOTIFICATION_TYPE_INTERNAL_ACTION_REMINDER,
  NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_TEMPLATE,
  NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_RESPONSE
} from '../../config/constants';
import { mapCollection } from '../../utils/collection.utils';
import { CommercialAction } from '../commercial-action';
import { InternalAction } from '../internal-action';
import { Notification } from '../notification';
import { WorkGroup } from '../work-group';
import { CommercialActionBuilder } from './commercial-action.builder';
import { InternalActionBuilder } from './internal-action.builder';
import { ProjectBuilder } from './project.builder';
import { WorkGroupBuilder } from './work-group.builder';

export class NotificationBuilder {
  static fromJson(json: any) {
    return (json)
      ? new Notification(
        json.hash,
        json.type,
        json.referenceHash,
        NotificationBuilder.referenceFromJson(json),
        json.text,
        json.date,
        json.isRead,
        json.isDone,
        json.createdAt,
      )
      : null;
  }

  static fromList(list): Notification[] {
    return Array.isArray(list)
      ? mapCollection(NotificationBuilder.fromJson, list)
      : [];
  }

  private static referenceFromJson(json: any): CommercialAction | InternalAction | WorkGroup {
    if (json.type === NOTIFICATION_TYPE_COMMERCIAL_ACTION || json.type === NOTIFICATION_TYPE_COMMERCIAL_ACTION_REMINDER) {
      return CommercialActionBuilder.fromJson(json.reference);
    } else if (json.type === NOTIFICATION_TYPE_INTERNAL_ACTION || json.type === NOTIFICATION_TYPE_INTERNAL_ACTION_REMINDER) {
      return InternalActionBuilder.fromJson(json.reference);
    } else if (json.type === NOTIFICATION_TYPE_WORK_GROUP) {
      return WorkGroupBuilder.fromJson(json.reference);
    } else if (json.type === NOTIFICATION_TYPE_PROJECT_WITHOUT_OFFER
      || json.type === NOTIFICATION_TYPE_PROJECT_WITHOUT_SUCCESSFUL_TRIAL
      || json.type === NOTIFICATION_TYPE_PROJECT_WITHOUT_INVOICE
      || json.type === NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_TEMPLATE
      || json.type === NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_RESPONSE) {
      return ProjectBuilder.fromJson(json.reference);
    }

    return json.reference;
  }
}
