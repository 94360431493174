import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { InternalActionFileBuilder } from '../../models/builder/internal-action-file.builder';
import { Observable } from 'rxjs';

const getInternalActionFileBody = (internalActionHash: String, fileId: string) => ({
  internalActionHash,
  fileId,
});

@Injectable()
export class InternalActionFileApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}internal-actions-files`;
  }

  createInternalActionFile(internalActionHash: String, fileId: string) {
    return this.httpClient.post(this.apiUrl, getInternalActionFileBody(internalActionHash, fileId))
      .pipe(
        map(InternalActionFileBuilder.fromJson)
      );
  }

  getInternalActionFiles(
    internalActionHash?: string
  ) {
    let params = new HttpParams();

    params = params.append('internalActionHash', internalActionHash);

    return this.httpClient.get(`${this.apiUrl}`, { params })
      .pipe(
        map(InternalActionFileBuilder.fromList)
      );
  }

  deleteInternalActionFile(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }
}
