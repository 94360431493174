import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditPieceTypeComponent } from './edit-piece-type/edit-piece-type.component';
import { PieceTypeResolver } from './edit-piece-type/piece-type.resolver';
import { PieceTypesTableComponent } from './piece-types-table/piece-types-table.component';
import { PieceTypesResolver } from './piece-types-table/piece-types.resolver';

const pieceTypesRoutes: Routes = [
  {
    path: '',
    component: PieceTypesTableComponent,
    resolve: { pieceTypes: PieceTypesResolver }
  },
  {
    path: 'new',
    component: EditPieceTypeComponent
  },
  {
    path: ':hash',
    data: { editMode: true },
    component: EditPieceTypeComponent,
    resolve: { pieceType: PieceTypeResolver }
  }
];

@NgModule({
  imports: [RouterModule.forChild(pieceTypesRoutes)],
  exports: [RouterModule]
})
export class PieceTypesRoutingModule { }
