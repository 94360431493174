export class ToleranceValue {
  constructor(
    public minDiameter: number,
    public maxDiameter: number,
    public toleranceQuality: string,
    public tolerancePosition: string,
    public value: number,
  ) { }

  clone() {
    return new ToleranceValue(
      this.minDiameter,
      this.maxDiameter,
      this.toleranceQuality,
      this.tolerancePosition,
      this.value,
    );
  }
}
