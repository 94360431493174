import { mapCollection } from '../../utils/collection.utils';
import { DeliveryNote } from '../delivery-note';
import { CustomerBuilder } from './customer.builder';
import { InvoiceBuilder } from './invoice.builder';

export class DeliveryNoteBuilder {
  static fromJson(json: any): DeliveryNote {
    return json
      ? new DeliveryNote(
          json.id,
          json.number,
          json.totalPrice,
          CustomerBuilder.fromJson(json.customer),
          json.reviewed,
          InvoiceBuilder.fromJson(json.invoice),
        )
      : null;
  }

  static fromList(list: any): DeliveryNote[] {
    return Array.isArray(list) ? mapCollection(DeliveryNoteBuilder.fromJson, list) : [];
  }
}
