import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InternalActionSubjectSuggestionBuilder } from 'src/app/core/models/builder/internal-action-subject-suggestion-builder';
import { InternalActionBuilder } from 'src/app/core/models/builder/internal-action.builder';
import { InternalAction } from 'src/app/core/models/internal-action';
import { environment } from 'src/environments/environment';

const getInternalActionBody = (internalAction: InternalAction) => ({
  subject: internalAction.subject,
  date: internalAction.date,
  time: internalAction.time,
  reminderDate: internalAction.reminderDate,
  notifyWorkGroupUsers: internalAction.notifyWorkGroupUsers,
  comments: internalAction.comments,
  cost: internalAction.cost,
  actionTypeHash: internalAction.actionType.hash,
  workGroupHash: internalAction.workGroup.hash
});

@Injectable()
export class InternalActionApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}internal-actions`;
  }

  getInternalActions() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(InternalActionBuilder.fromList)
      );
  }

  getInternalAction(hash: string): Observable<InternalAction> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(InternalActionBuilder.fromJson)
      );
  }

  deleteInternalAction(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  updateInternalAction(internalAction: InternalAction): Observable<InternalAction> {
    return this.httpClient.put(`${this.apiUrl}/${internalAction.hash}`, getInternalActionBody(internalAction))
      .pipe(
        map(InternalActionBuilder.fromJson)
      );
  }

  createInternalAction(internalAction: InternalAction): Observable<InternalAction> {
    return this.httpClient.post(this.apiUrl, getInternalActionBody(internalAction))
      .pipe(
        map(InternalActionBuilder.fromJson)
      );
  }

  getInternalActionsFiltered(
    workGroup?: string | null,
    text?: string | null,
    fromDate?: string | null,
    toDate?: string | null,
    sort?: string | null,
    direction?: string | null,
  ): Observable<InternalAction[]> {
    let params = new HttpParams();

    if (workGroup) {
      params = params.append('workGroup', workGroup);
    }

    if (fromDate) {
      params = params.append('fromDate', fromDate);
    }

    if (toDate) {
      params = params.append('toDate', toDate);
    }

    if (text) {
      params = params.append('text', text);
    }

    if (sort) {
      params = params.append('sort', sort);
    }

    if (direction) {
      params = params.append('direction', direction);
    }

    return this.httpClient.get(`${this.apiUrl}`, { params })
      .pipe(
        map(InternalActionBuilder.fromList)
      );
  }

  getInternalActionSubjectSuggestions(): Observable<string[]> {
    return this.httpClient.get(`${this.apiUrl}/subject-suggestions`)
      .pipe(
        map(InternalActionSubjectSuggestionBuilder.fromList)
      );
  }
}
