import { AfterViewChecked, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appColorMaterial]'
})
export class ColorMaterialDirective implements OnInit, AfterViewChecked {
  private element: HTMLElement;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.element = this.elementRef.nativeElement;
  }

  ngAfterViewChecked() {
    const items = this.element.querySelectorAll('.ng-option');
    items.forEach(item => {
      const materialSubgroup = item.firstElementChild.textContent.split(' ')[0];
      const backgroundColor = this.selectMaterialColor(materialSubgroup);
      this.renderer.addClass(item, backgroundColor);
    });
  }

  selectMaterialColor(materialSubgroup) {
    if (materialSubgroup.startsWith('P')) {
      return 'background-steel';
    }

    if (materialSubgroup.startsWith('M')) {
      return 'background-stainless';
    }

    if (materialSubgroup.startsWith('K')) {
      return 'background-cast-iron';
    }

    if (materialSubgroup.startsWith('N')) {
      return 'background-non-ferrous';
    }

    if (materialSubgroup.startsWith('SM')) {
      return 'background-powder';
    }

    if (materialSubgroup.startsWith('S')) {
      return 'background-super-alloys';
    }

    if (materialSubgroup.startsWith('O')) {
      return 'background-composite';
    }

    if (materialSubgroup.startsWith('H')) {
      return 'background-hardened-steel';
    }
  }
}
