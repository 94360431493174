import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Process } from '../../../core/models/process';
import { ProcessApiService } from '../../../core/services/api/process-api.service';
import { ResolverService } from '../../../core/services/base/resolver.service';
import { getEmptyAsyncArray } from '../../../core/utils/collection.utils';

@Injectable()
export class ProcessService {
  constructor(
    private processApiService: ProcessApiService,
    private resolverService: ResolverService
  ) {
  }

  getProcesses(): Observable<Process[]> {
    return this.processApiService.getProcesses();
  }

  getProcess(hash: string) {
    return this.processApiService.getProcess(hash);
  }

  updateProcess(process: Process) {
    return this.processApiService.updateProcess(process).toPromise();
  }

  createProcess(process: Process) {
    return this.processApiService.createProcess(process).toPromise();
  }

  resolveProcesses() {
    this.resolverService.onResolverStart();
    return this.getProcesses()
      .pipe(
        catchError(getEmptyAsyncArray),
        finalize(this.resolverService.onResolverStop)
      );
  }

  resolveProcess(hash: string) {
    this.resolverService.onResolverStart();
    return this.getProcess(hash)
      .pipe(
        catchError(() => of(null)),
        finalize(this.resolverService.onResolverStop)
      );
  }

  deleteProcess(hash: string) {
    return this.processApiService.deleteProcess(hash).toPromise();
  }
}
