import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ACCESS_LEVEL_HIDDEN } from '../config/access-level-constants';
import { PERMISSION_DASHBOARD } from '../config/permission-constants';
import { homeRoute, initialRoute } from '../config/routes.config';
import { getUserPermissions } from '../utils/jwt.utils';

@Injectable()
export class DashboardAccessGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkCanActivate(_route.data.section);
  }

  private checkCanActivate(section: String) {
    const userPermission = getUserPermissions().find(value => value.subsectionCode === section);

    if (!userPermission) {
      return false;
    }

    if (userPermission.subsectionCode.toLowerCase() === initialRoute
      && userPermission.accessLevelCode === ACCESS_LEVEL_HIDDEN
    ) {
      this.redirectToHomeOrFirstVisibleRoute();
    }

    return !(userPermission && userPermission.accessLevelCode === ACCESS_LEVEL_HIDDEN);
  }

  private redirectToHomeOrFirstVisibleRoute() {
    const homePermission = getUserPermissions().find(
      value => value.subsectionCode === PERMISSION_DASHBOARD
    );

    if (homePermission && homePermission.accessLevelCode !== ACCESS_LEVEL_HIDDEN) {
      return this.router.navigateByUrl(homeRoute);
    }

    return this.redirectToFirstVisibleRoute();
  }

  private redirectToFirstVisibleRoute() {
    const alternative = getUserPermissions().find(
      value => value.accessLevelCode !== ACCESS_LEVEL_HIDDEN
    );

    this.router.navigateByUrl(alternative.subsectionCode.toLowerCase());
  }
}
