import { Competitor } from '../competitor';

export class TrialParameters {
  constructor(
    public toolDiameter?: number,
    public cuttingDepth?: number,
    public noOfParts?: number,
    public boresPerPart?: number,
    public totalBores?: number,
    public competitor?: Competitor,
    public urmaProduct?: string,
  ) {
    this.toolDiameter = toolDiameter;
    this.cuttingDepth = cuttingDepth;
    this.noOfParts = noOfParts;
    this.boresPerPart = boresPerPart;
    this.totalBores = totalBores;
    this.competitor = competitor;
    this.urmaProduct = urmaProduct;
  }

  clone() {
    return new TrialParameters(
      this.toolDiameter,
      this.cuttingDepth,
      this.noOfParts,
      this.boresPerPart,
      this.totalBores,
      this.competitor,
      this.urmaProduct,
    );
  }

  get hasCompetitor(): boolean {
    return !!this.competitor && !!this.competitor.hash;
  }

  get hasUrmaProduct(): boolean {
    return !!this.urmaProduct;
  }
}
