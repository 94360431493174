import { TrialLineBuilder } from '@/core/models/builder/trial-line.buider';
import { TrialLine } from '@/core/models/trial-line';
import { TrialLineResultInterface } from '@/core/models/trial-line-result.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getTrialLineBody, getTrialLineResultBody } from './builder/trial-line-request.builder';

@Injectable()
export class TrialLineApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}trial-lines`;
  }

  getTrialTrialLines(trialHash: string): Observable<TrialLine[]> {
    return this.httpClient.get(`${environment.apiUrl}trials/${trialHash}/trial-lines`)
      .pipe(
        map(TrialLineBuilder.fromList)
      );
  }

  createTrialLine(trialLine: TrialLine): Observable<TrialLine> {
    return this.httpClient.post(this.apiUrl, { ...getTrialLineBody(trialLine) })
      .pipe(
        map(TrialLineBuilder.fromJson)
      );
  }

  deleteTrialLine(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  updateTrialLine(trialLine: TrialLine): Observable<TrialLine> {
    return this.httpClient.put(`${this.apiUrl}/${trialLine.hash}`, getTrialLineBody(trialLine))
      .pipe(
        map(TrialLineBuilder.fromJson)
      );
  }

  updateTrialLineResults(trialLineResults: TrialLineResultInterface): Observable<TrialLine> {
    const { trialLineHash } = trialLineResults;
    return this.httpClient.put(`${this.apiUrl}/${trialLineHash}/results`, getTrialLineResultBody(trialLineResults))
      .pipe(
        map(TrialLineBuilder.fromJson)
      );
  }

}
