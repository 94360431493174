import { Process } from '@/core/models/process';
import { mapCollection } from '@/core/utils/collection.utils';

export class ProcessBuilder {
  static fromJson(json: any) {
    return json
      ? new Process({
          hash: json.hash,
          name: json.name,
          isInternal: json.isInternal,
          isExternal: json.isExternal,
          axialDepthCutVisibility: json.axialDepthCutVisibility,
          rollerRadiusVisibility: json.rollerRadiusVisibility,
        })
      : null;
  }

  static fromList(list: any): Process[] {
    return Array.isArray(list)
      ? mapCollection(ProcessBuilder.fromJson, list)
      : [];
  }
}
