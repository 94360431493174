import { mapCollection } from '../../utils/collection.utils';
import { InternalActionFile } from '../internal-action-file';

export class InternalActionFileBuilder {
  static fromJson(json: any): InternalActionFile {
    return (json)
      ? new InternalActionFile(
          json.hash,
          json.fileId,
          json.fileName,
          json.path,
        )
      : null;
  }

  static fromList(list: any): InternalActionFile[] {
    return Array.isArray(list)
      ? mapCollection(InternalActionFileBuilder.fromJson, list)
      : [];
  }
}
