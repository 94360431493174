import { mapCollection } from '../../utils/collection.utils';
import { TrialLineFile } from '../trial-line-file';

export class TrialLineFileBuilder {
  static fromJson(json: any): TrialLineFile {
    return (json)
      ? new TrialLineFile(
          json.hash,
          json.fileId,
          json.fileName,
          json.path,
        )
      : null;
  }

  static fromList(list): TrialLineFile[] {
    return Array.isArray(list)
      ? mapCollection(TrialLineFileBuilder.fromJson, list)
      : [];
  }
}
