export class TrialLineFile {
  constructor(
    public hash?: string,
    public fileId?: string,
    public fileName?: string,
    public path?: string,
  ) { }

  clone() {
    return new TrialLineFile(
      this.hash,
      this.fileId,
      this.fileName,
      this.path
    );
  }
}
