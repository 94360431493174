import { isNullOrUndefined } from './object.utils';

export const capitalize = (value: string) => {
  return (value) ? value.charAt(0).toUpperCase() + value.slice(1) : value;
};

const reduceCapitalized = (acc: string, word: string) => acc.concat(' ', capitalize(word));

export const capitalizeAll = (value: string) => {
  return (value) ? value.split(' ').reduce(reduceCapitalized, '') : value;
};

export const matches = (haystack: string, needle: string) => {
  const lowHaystack = (haystack) ? haystack.toLowerCase() : '';
  const lowNeedle = (needle) ? needle.toLowerCase() : '';

  return (!lowNeedle || (lowHaystack && lowHaystack.includes(lowNeedle)));
};

export const compare = (a: string, b: string) => a.localeCompare(b);

export const underscore = (value: string) => (value) ? value.replace(' ', '_') : value;

export const valueOf = (value: Object) => (isNullOrUndefined(value)) ? null : value.toString();

export const encodeNonAsciiCharacters = (value: string) => {
  let out = '';
  for (let i = 0; i < value.length; i++) {
      const ch = value.charAt(i);
      const chn = ch.charCodeAt(0);
      if (chn <= 127) { out += ch; } else {
          let hex = chn.toString(16);
          if (hex.length < 4) {
              hex = '000'.substring(hex.length - 1) + hex;
          }
          out += '\\u' + hex;
      }
  }
  return out;
};

export const isEmpty = (value: string) => isNullOrUndefined(value) || !value.trim();
