import { Directive, ElementRef, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[appHideOnProd]'
})
export class HideOnProdDirective implements OnInit {
  private element: HTMLElement;
  private isProdMode: boolean;

  constructor(private elementRef: ElementRef) {
    this.isProdMode = environment.production;
  }

  ngOnInit(): void {
    this.element = this.elementRef.nativeElement;
    if (this.isProdMode) {
      this.element.style.display = 'none';
    }
  }

}
