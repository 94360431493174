import { mapCollection } from '../../utils/collection.utils';
import { Privilege } from '../privilege';

export class PrivilegeBuilder {
  static fromJson(json: any): Privilege {
    return (json)
      ? new Privilege(
          json.hash,
          json.name,
          json.code
        )
        : null;
  }

  static fromList(list: any): Privilege[] {
    return Array.isArray(list)
      ? mapCollection(PrivilegeBuilder.fromJson, list)
      : [];
  }
}
