import { mapCollection } from '../../utils/collection.utils';
import { TrialObjective } from '../trial-objective';


export class TrialObjectiveBuilder {
  static fromJson(json: any): TrialObjective {
    return (json)
      ? new TrialObjective(
        json.hash,
        json.name,
        json.defaultValue,
      )
      : null;
  }

  static fromList(list: any): TrialObjective[] {
    return Array.isArray(list)
      ? mapCollection(TrialObjectiveBuilder.fromJson, list)
      : [];
  }
}
