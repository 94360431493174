export class ProjectProfitability {
  public offeredTotal: number | null;
  public invoicedTotal: number | null;
  public deliveryNotesTotal: number | null;

  constructor(
    offeredTotal?: number | null,
    invoicedTotal?: number | null,
    deliveryNotesTotal?: number | null,
  ) {
    this.offeredTotal = offeredTotal;
    this.invoicedTotal = invoicedTotal;
    this.deliveryNotesTotal = deliveryNotesTotal;
  }

  clone(): ProjectProfitability {
    return new ProjectProfitability(
      this.offeredTotal,
      this.invoicedTotal,
      this.deliveryNotesTotal,
    );
  }
}
