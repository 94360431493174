import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HardnessBuilder } from 'src/app/core/models/builder/hardness.builder';
import { Hardness } from 'src/app/core/models/hardness';
import { environment } from 'src/environments/environment';

const getHardnessBody = (hardness: Hardness) => ({
  name: hardness.name,
});

@Injectable()
export class HardnessApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}hardnesses`;
  }

  getHardness(hash: string): Observable<Hardness> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(HardnessBuilder.fromJson)
      );
  }

  getHardnesses(): Observable<Hardness[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(HardnessBuilder.fromList)
      );
  }

  deleteHardness(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  updateHardness(hardness: Hardness): Observable<Hardness> {
    return this.httpClient.put(`${this.apiUrl}/${hardness.hash}`, getHardnessBody(hardness))
      .pipe(
        map(HardnessBuilder.fromJson)
      );
  }

  createHardness(hardness: Hardness): Observable<Hardness> {
    return this.httpClient.post(this.apiUrl, getHardnessBody(hardness))
      .pipe(
        map(HardnessBuilder.fromJson)
      );
  }
}
