import { mapCollection } from '../../utils/collection.utils';
import { CommercialActionChild } from '../commercial-action-child';

export class CommercialActionChildBuilder {
  static fromJson(json: any): CommercialActionChild {
    return (json)
      ? new CommercialActionChild(
          json.hash,
          json.subject,
          json.date,
          json.time,
          json.comments,
          json.commercialActionType,
          json.cost,
          json.filesCount,
          json.parentHash,
        )
      : null;
  }

  static fromList(list: any): CommercialActionChild[] {
    return Array.isArray(list)
      ? mapCollection(CommercialActionChildBuilder.fromJson, list)
      : [];
  }
}
