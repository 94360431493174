import { mapCollection } from '@/core/utils/collection.utils';
import { ProjectDeliveryNote } from '../project-delivery-note';

export class ProjectDeliveryNoteBuilder {
  static fromJson(json: any): ProjectDeliveryNote {
    return json
      ? new ProjectDeliveryNote(
          json.deliveryNoteNumber
        )
      : null;
  }

  static fromList(list: any): ProjectDeliveryNote[] {
    return Array.isArray(list)
      ? mapCollection(ProjectDeliveryNoteBuilder.fromJson, list)
      : [];
  }
}
