import { mapCollection } from '../../utils/collection.utils';
import { MachiningStudy } from '../machining-study';

export class MachiningStudyBuilder {
  static fromJson(json: any): MachiningStudy {
    return (json)
      ? new MachiningStudy(
        json.hash,
        json.project,
        json.machine,
        json.stability,
        json.isStabilityOk,
        json.machineComments,
        json.lubricantType,
        json.emulsionPercentage,
        json.internalCooling,
        json.refrigerantPressure,
        json.lubricantComments,
        json.material,
        json.materialDenomination,
        json.materialIso,
        json.materialIsoOld,
        json.hardness,
        json.hardnessValue,
        json.specification,
        json.noOfParts,
        json.boresPerPart,
        json.totalBores,
        json.pieceDescription,
        json.preMachinedMethod,
        json.preMachinedDiameter,
        json.preMachinedRoughnessType,
        json.preMachinedRoughnessValue,
        json.cuttingDiameter,
        json.cuttingDepth,
        json.toleranceModeSimple,
        json.tolerancePositionSimple,
        json.toleranceQualitySimple,
        json.tolerancePosition,
        json.toleranceQuality,
        json.tolerance,
        json.holeType,
        json.toolLength,
        json.additionalRequirements,
        json.interruptedCut,
        json.interruptedCutComments,
        json.targetTime,
        json.targetQuantity,
        json.roughnessType,
        json.roughnessValue,
        json.cycleTime,
        json.pieceRequirementsComments,
        json.createdAt,
      )
      : null;
  }

  static fromList(list): MachiningStudy[] {
    return (Array.isArray(list))
      ? mapCollection(MachiningStudyBuilder.fromJson, list)
      : [];
  }
}
