import { DROPBOX_API_URL, DROPBOX_CONTENT_API_URL } from '@/core/config/constants';
import { DocumentBuilder } from '@/core/models/builder/document.builder';
import { Document } from '@/core/models/document';
import { DropboxBatchDeleteResponse } from '@/core/responses/DropboxBatchDeleteResponse';
import { DropboxTemporaryUrlResponse } from '@/core/responses/DropboxGetTemporaryUrlResponse';
import { encodeNonAsciiCharacters } from '@/core/utils/string.utils';
import { encodeDropboxUrl } from '@/core/utils/url.utils';
import { ProjectGroupReportAssistant } from '@/dashboard/project-groups/project-group-report-assistant/project-group-report-assistant';
import { MoveDocumentInterface } from '@/dashboard/shared/commercial-actions-shared/edit-commercial-actions-shared/move-document.interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class DropboxError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, DropboxError.prototype);
  }
}

const getProjectGroupGeneralReportBody = (assistant: ProjectGroupReportAssistant) => ({
  projectGroupHash: assistant.projectGroup.hash,
  projectGroupFiles: assistant.getProjectGroupSelectedFiles(),
  projectFiles: assistant.selectedProjects.map(project => ({
    projectHash: project.hash,
    files: assistant.getProjectSelectedFiles(project.hash),
  }))
});

@Injectable()
export class DocumentApiService {
  private apiUrl: string;
  private dropboxApiUrl: string;
  private dropboxContentApiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}documents/`;
    this.dropboxApiUrl = DROPBOX_API_URL;
    this.dropboxContentApiUrl = DROPBOX_CONTENT_API_URL;
  }

  getDocuments(folderPath: string): Observable<Document[]> {
    const params = new HttpParams().set('folderPath', folderPath);
    const encodedUrl = `${this.apiUrl}?${encodeDropboxUrl(params)}`;

    return this.httpClient.get(encodedUrl)
      .pipe(
        map(DocumentBuilder.fromList)
      );
  }

  downloadDocument(path: string) {
    const params = new HttpParams().set('path', path);
    const encodedUrl = `${this.apiUrl}download?${encodeDropboxUrl(params)}`;

    return this.httpClient.get(encodedUrl, { responseType: 'arraybuffer' });
  }

  uploadDocument(data: FormData) {
    return this.httpClient.post<FormData>(`${this.apiUrl}upload`, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  uploadFile(file: File, path: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);

    return this.uploadDocument(formData);
  }

  copyDocument(fromPath: string, toPath: string): Observable<Document[]> {
    const params = new HttpParams()
      .set('fromPath', fromPath)
      .set('toPath', toPath);
    const encodedUrl = `${this.apiUrl}copy?${encodeDropboxUrl(params)}`;

    return this.httpClient.get(encodedUrl).pipe(
      map(DocumentBuilder.fromList)
    );
  }

  moveDocument(paths: MoveDocumentInterface[]): Observable<Boolean[]> {
    return this.httpClient.post(`${this.apiUrl}move`, { documents: paths })
      .pipe(
        map((json) => json['results'])
      );
  }

  deleteDocument(path: string): Observable<Object> {
    return this.httpClient.post(`${this.dropboxApiUrl}/files/delete_v2`, { path })
      .pipe(
        catchError(() => {
          return throwError(new DropboxError(`Error deleting file ${path}`));
        })
      );
  }

  checkDeleteBatch(jobId) {
    return this.httpClient.post(`${this.dropboxApiUrl}/files/delete_batch/check`, { async_job_id: jobId });
  }

  deleteDocuments(paths: string[]): Observable<DropboxBatchDeleteResponse> {
    const entries = paths.map(path => ({ path }));
    return this.httpClient.post<DropboxBatchDeleteResponse>(`${this.dropboxApiUrl}/files/delete_batch`, { entries });
  }

  getTemporaryUrl(path: string): Observable<DropboxTemporaryUrlResponse> {
    return this.httpClient.post<DropboxTemporaryUrlResponse>(`${this.dropboxApiUrl}/files/get_temporary_link`, { path });
  }

  getThumbnail(path: string): Observable<ArrayBuffer> {
    const header = new HttpHeaders().set(
      'Dropbox-API-Arg',
      `{"resource": {".tag": "path","path": "${encodeNonAsciiCharacters(path)}"},"format": "jpeg","size": "w128h128","mode": "strict"}`
    );
    return this.httpClient.post(
      `${this.dropboxContentApiUrl}/files/get_thumbnail_v2`, null, { headers: header, responseType: 'arraybuffer' }
    );
  }

  getBigThumbnail(path: string): Observable<ArrayBuffer> {
    const header = new HttpHeaders().set(
      'Dropbox-API-Arg',
      `{"resource": {".tag": "path","path": "${encodeNonAsciiCharacters(path)}"},"format": "jpeg","size": "w256h256","mode": "strict"}`
    );
    return this.httpClient.post(
      `${this.dropboxContentApiUrl}/files/get_thumbnail_v2`, null, { headers: header, responseType: 'arraybuffer' }
    );
  }

  generateProjectReport(paths: string[], projectHash) {
    const params = { documents: paths, project: projectHash };
    return this.httpClient.post(`${this.apiUrl}generate-project-report`, null, { params, responseType: 'arraybuffer' });
  }

  generateProjectGroupReport(paths: string[], projectGroupHash) {
    const params = { documents: paths, projectGroup: projectGroupHash };
    return this.httpClient.post(`${this.apiUrl}generate-project-group-report`, null, { params, responseType: 'arraybuffer' });
  }

  generateProjectGroupGeneralReport(assistant: ProjectGroupReportAssistant): Observable<ArrayBuffer> {
    const params = getProjectGroupGeneralReportBody(assistant);
    return this.httpClient.post(`${this.apiUrl}generate-project-group-general-report`, params, { responseType: 'arraybuffer' });
  }
}
