import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { NotificationBuilder } from '../../models/builder/notification.builder';
import { Notification } from '../../models/notification';

@Injectable()
export class NotificationApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}notifications`;
  }

  getNotifications(): Observable<Notification[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(NotificationBuilder.fromList)
      );
  }

  readNotification(hash: string) {
    return this.httpClient.put(`${this.apiUrl}/read/${hash}`, {});
  }

  markNotificationDone(hash: string) {
    return this.httpClient.put(`${this.apiUrl}/done/${hash}`, {});
  }

  markNotificationUndone(hash: string) {
    return this.httpClient.put(`${this.apiUrl}/undone/${hash}`, {});
  }
}
