import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ProvinceBuilder } from '../../models/builder/province.builder';
import { Province } from '../../models/province';

@Injectable()
export class ProvinceApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}provinces`;
  }

  getProvinces(): Observable<Province[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(ProvinceBuilder.fromList)
      );
  }

  gerProvince(provinceId: number): Observable<Province> {
    return this.httpClient.get(`${this.apiUrl}/${provinceId}`)
      .pipe(
        map(ProvinceBuilder.fromJson)
      );
  }
}
