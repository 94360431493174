export class RoiComparator {
  constructor(
    /** Herramienta / Equipamiento */
    public compensation?: number,
    /** Consumible */
    public insert?: number,
    public insertErpOfferLineIds?: number[],
    /** Número de dientes (z) */
    public noOfTeeth?: number,
    /** Velocidad de corte (VcO) original */
    public cuttingSpeedOriginal?: number,
    /** Velocidad de corte porcentaje (Vc %) */
    public cuttingSpeedPercentage?: number,
    /** Velocidad de corte (Vc) */
    public cuttingSpeed?: number,
    /** Revoluciones (n) */
    public revolutions?: number,
    /** Avance por diente (fz) */
    public feedPerTooth?: number,
    /** Avance por minuto (vf) */
    public tableFeed?: number,
    /** Tiempo de mecanizado por agujero (te) */
    public machiningTimePerBore?: number,
    /** Vida consumible */
    public toolLifePerInsert?: number,
    /** Coste de máquina por hora */
    public machineCostPerHour?: number,
    /** Número de consumibles para el lote total */
    public noOfInsertUsesPerSerie?: number,
    /** Número de htas. nuevas para el lote total */
    public noOfNewToolsPerSerie?: number,
    /** Coste de total de hta. para el lote */
    public totalToolCostPerSerie?: number,
    /** Tiempo cambio consumible (tn1) */
    public insertChangeTime?: number,
    /** Tiempo ajuste (Salto / Set up) (tn2) */
    public adjustmentTime?: number,
    /** Intervalo del ajuste del salto */
    public runOutInterval?: number,
    /** Número de re-afilados posibles */
    public noOfPossibleReconditionings: number = 0,
    /** Coste cambio ajuste consumible */
    public totalCostPerInsertChange?: number,
    /** Coste de re-afilar */
    public reconditioningCost?: number,
    /** Coste de logística para re-afilar */
    public logisticsCostPerReconditioning?: number,
    /** Coste total para re-afilar */
    public totalCostForReconditioning?: number,
    /** Factor de vida para las htas. reafilados */
    public toolLifeFactorReconditioning: number = 80,
    /** Coste total de hta y coste adicional por lote */
    public totalToolAndAdditionalCostForSerie?: number,
    /** Tiempo de mecanizado */
    public machiningTotalTime?: number,
    /** Coste de mecanizado */
    public machiningCost?: number,
    /** Coste por agujero */
    public costPerBore?: number,
    /** Coste total de las Htas. */
    public totalToolCost?: number,
    public compensationErpOfferLineIds?: number[],
    /** Tiempo mecanizado por pieza */
    public machiningTimePerPart?: number,
  ) { }

  clone() {
    return new RoiComparator(
      this.compensation,
      this.insert,
      this.insertErpOfferLineIds,
      this.noOfTeeth,
      this.cuttingSpeedOriginal,
      this.cuttingSpeedPercentage,
      this.cuttingSpeed,
      this.revolutions,
      this.feedPerTooth,
      this.tableFeed,
      this.machiningTimePerBore,
      this.toolLifePerInsert,
      this.machineCostPerHour,
      this.noOfInsertUsesPerSerie,
      this.noOfNewToolsPerSerie,
      this.totalToolCostPerSerie,
      this.insertChangeTime,
      this.adjustmentTime,
      this.runOutInterval,
      this.noOfPossibleReconditionings,
      this.totalCostPerInsertChange,
      this.reconditioningCost,
      this.logisticsCostPerReconditioning,
      this.totalCostForReconditioning,
      this.toolLifeFactorReconditioning,
      this.totalToolAndAdditionalCostForSerie,
      this.machiningTotalTime,
      this.machiningCost,
      this.costPerBore,
      this.totalToolCost,
      this.compensationErpOfferLineIds,
      this.machiningTimePerPart,
    );
  }
}
