import { mapCollection } from '../../utils/collection.utils';
import { Supplier } from '../supplier';

export class SupplierBuilder {
  static fromJson(json: any) {
    return (json)
      ? new Supplier(
          json.hash,
          json.name,
          json.logo
        )
      : null;
  }

  static fromList(list): Supplier[] {
    return Array.isArray(list)
      ? mapCollection(SupplierBuilder.fromJson, list)
      : [];
  }
}
