import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { PieceType } from '../../../core/models/piece-type';
import { PieceTypeApiService } from '../../../core/services/api/piece-type.api.service';
import { ResolverService } from '../../../core/services/base/resolver.service';
import { getEmptyAsyncArray } from '../../../core/utils/collection.utils';

@Injectable({
  providedIn: 'root'
})
export class PieceTypesService {
  constructor(
    private pieceTypeApiService: PieceTypeApiService,
    private resolverService: ResolverService
  ) { }

  getPieceTypes(): Observable<PieceType[]> {
    return this.pieceTypeApiService.getPieceTypes();
  }

  deletePieceType(hash: string) {
    return this.pieceTypeApiService.deletePieceType(hash).toPromise();
  }

  getPieceType(hash: string) {
    return this.pieceTypeApiService.getPieceType(hash);
  }

  updatePieceType(pieceType: PieceType) {
    return this.pieceTypeApiService.updatePieceType(pieceType).toPromise();
  }

  createPieceType(pieceType: PieceType) {
    return this.pieceTypeApiService.createPieceType(pieceType).toPromise();
  }

  resolvePieceTypes(): Observable<PieceType[]> {
    this.resolverService.onResolverStart();
    return this.getPieceTypes()
      .pipe(
        catchError(getEmptyAsyncArray),
        finalize(this.resolverService.onResolverStop)
      );
  }

  resolvePieceType(hash: string) {
    this.resolverService.onResolverStart();
    return this.getPieceType(hash)
      .pipe(
        catchError(() => of(null)),
        finalize(this.resolverService.onResolverStop)
      );
  }
}
