import { CommercialActionType } from './commercial-action-type';
import { Contact } from './contact';
import { Customer } from './customer';
import { Project } from './project';
import { User } from './User';
import { CommercialActionChild } from './commercial-action-child';
import { NotifiedUser } from './notified-user';

export class CommercialAction {
  constructor(
    public hash?: string,
    public subject?: string,
    public date?: string,
    public startTime?: number,
    public time?: number,
    public reminderDate?: string,
    public comments?: string,
    public user?: User,
    public commercialActionType?: CommercialActionType,
    public cost?: number,
    public customer?: Customer,
    public contacts?: Contact[],
    public notifiedUsers?: NotifiedUser[],
    public project?: Project,
    public filesCount?: number,
    public parentHash?: string,
    public children?: CommercialActionChild[],
  ) { }

  clone(): CommercialAction {
    return new CommercialAction(
      this.hash,
      this.subject,
      this.date,
      this.startTime,
      this.time,
      this.reminderDate,
      this.comments,
      this.user,
      this.commercialActionType,
      this.cost,
      this.customer,
      this.contacts,
      this.notifiedUsers,
      this.project,
      this.filesCount,
      this.parentHash,
      this.children,
    );
  }

  get isGlobalAction(): boolean {
    return this.project == null;
  }

  get hasParent(): boolean {
    return this.parentHash != null;
  }

  get customerName(): string {
    return this.customer != null
      ? this.customer.name
      : null;
  }

  get commercialActionTypeName(): string {
    return this.commercialActionType != null
      ? this.commercialActionType.name
      : null;
  }

  get userNick(): string {
    return this.user != null
      ? this.user.nick
      : null;
  }
}
