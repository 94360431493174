import { mapCollection } from '../../utils/collection.utils';
import { Hardness } from '../hardness';

export class HardnessBuilder {
  static fromJson(json: any): Hardness {
    return (json)
      ? new Hardness(
        json.hash,
        json.name
      )
      : null;
  }

  static fromList(list: string[]): Hardness[] {
    return Array.isArray(list)
      ? mapCollection(HardnessBuilder.fromJson, list)
      : [];
  }
}
