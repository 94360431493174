import { mapCollection } from '../../utils/collection.utils';
import { Commercial } from '../commercial';

export class CommercialBuilder {
  static fromJson(json) {
    return (json)
      ? new Commercial(
        json.id,
        json.name
      )
      : null;
  }

  static fromList(list) {
    return (Array.isArray(list)) ? mapCollection(CommercialBuilder.fromJson, list) : [];
  }
}
