<main id="page-login">
  <app-connection-status class="c-connection-status__container"></app-connection-status>
  <section>
    <article>
      <img alt="" src="../../../assets/images/logo.svg">
      <div>
        <app-login-form (login)="onLogin($event)"></app-login-form>
      </div>
    </article>
  </section>
</main>
