import { DEFAULT_LANG, LANGS } from '@/core/config/constants';
import { OFFLINE_PROJECT_GROUP_LIST_KEY } from '@/core/config/offline-constants';
import { findElementByPropertyValue } from '@/core/utils/collection.utils';

const sessionKey = 'session';
const collapseKey = 'collapse';
const commercialKey = 'commercial';
const langKey = 'lang';

const setItem = (key: string, value: any): void => localStorage.setItem(key, JSON.stringify(value));

const getItem = (key: string): any => {
  const value = localStorage.getItem(key);

  return (value) ? JSON.parse(value) : null;
};

export const setSession = (session): void => {
  setItem(sessionKey, session);
};

export const setUserCommercialId = (commercialId): void => {
  setItem(commercialKey, commercialId);
};

export const getUserCommercialId = (): number | null => {
  const commercialId = getItem(commercialKey);
  return commercialId ? Number(commercialId) : null;
};

export const getOfflineProjectGroupHashes = (): string[] => {
  return getItem(OFFLINE_PROJECT_GROUP_LIST_KEY) ?? [];
};

export const setOfflineProjectGroupHashes = (projectGroupHashes: string[]): void => {
  setItem(OFFLINE_PROJECT_GROUP_LIST_KEY, projectGroupHashes);
};

export const getSession = (): any => {
  return getItem(sessionKey);
};

export const deleteSession = (): void => {
  setSession(null);
  setUserCommercialId(null);
};

export const setCollapse = (collapse: boolean): void => {
  setItem(collapseKey, collapse);
};

export const getCollapse = (): boolean => {
  return getItem(collapseKey);
};

export const deleteCollapse = (): void => {
  setSession(null);
};

export const setLang = (lang: string): void => {
  setItem(langKey, lang);
};

export const getLang = (): string => {
  let lang = getItem(langKey);
  if (!lang || !findElementByPropertyValue(LANGS, 'value', lang)) {
    lang = DEFAULT_LANG.value;
    setLang(lang);
  }

  return lang;
};

export const deleteLang = (): void => {
  setLang(null);
};
