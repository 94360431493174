export class Competitor {

  constructor(
    public hash?: string,
    public name?: string,
    public description?: string,
  ) {}

  clone() {
    return new Competitor(
      this.hash,
      this.name,
      this.description,
    );
  }
}
