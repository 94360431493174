<ngx-smart-modal
  #modal
  identifier="modal"
  [customClass]="modalClass"
  [closable]="false"
  [dismissable]="dismissable"
  [escapable]="escapable"
  [hideDelay]="0"
>
  <div class="modal-header">
    <a (click)="close()" class="close">✕</a>

    <div class="title">
      <h3>{{ document?.name }}</h3>
    </div>

    <div class="actions" *ngIf="isImage">
      <button (click)="onZoomIn()" class="zoomIn"><span class="icon icon--zoom-in"></span></button>
      <button (click)="onZoomOut()" class="zoomOut"><span class="icon icon--zoom-out"></span></button>
    </div>
  </div>

  <div class="modal-body" [ngClass]="{'is-pdf': isPdf && previewDocument?.link !== undefined}">
    <button (click)="onPreviousPreview()" [disabled]="thereArePreviousDocuments()" class="icon-navigation icon--arrow-left"></button>
    <button (click)="onNextPreview()" [disabled]="thereAreNextDocuments()" class="icon-navigation icon--arrow-right"></button>
    <pdf-viewer
      *ngIf="isPdf && previewDocument?.link !== undefined"
      [src]="previewDocument?.link"
      [render-text]="true"
      [zoom]="zoomAmount"
      [original-size]="false"
    ></pdf-viewer>

    <div class="image">
      <drag-scroll>
        <img
          *ngIf="isImage && previewDocument?.link !== undefined"
          alt="{{ document?.name }}"
          [src]="previewDocument?.link"
          [ngStyle]="{'width' : getImageZoom() + '%' }"
          class="preview-img">

        <img
          *ngIf="isHeic && fakeThumbnail !== undefined"
          alt="{{ document?.name }}"
          [src]="fakeThumbnail"
          [ngStyle]="{'width' : getImageZoom() + '%' }"
          class="preview-img">
      </drag-scroll>
    </div>


    <div class="info text-center" *ngIf="!previewDocument && !fakeThumbnail && !previewIsUnavailable">
      <p translate>project.shared.documents.loadingPreview</p>
    </div>

    <div class="info text-center" *ngIf="previewIsUnavailable">
      <p translate>project.shared.documents.previewNotAvailable</p>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="button button-dark" (click)="onDownload()" [innerHTML]="'project.shared.documents.download' | translate"></button>
  </div>
</ngx-smart-modal>
