import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PreMachinedMethodBuilder } from 'src/app/core/models/builder/pre-machined-method.builder';
import { PreMachinedMethod } from 'src/app/core/models/pre-machined-method';
import { environment } from 'src/environments/environment';

const getPreMachinedMethodBody = (preMachinedMethod: PreMachinedMethod) => ({
  name: preMachinedMethod.name,
});

@Injectable()
export class PreMachinedMethodApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}pre-machined-method`;
  }

  getPreMachinedMethod(hash: string): Observable<PreMachinedMethod> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(PreMachinedMethodBuilder.fromJson)
      );
  }

  getPreMachinedMethods(): Observable<PreMachinedMethod[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(PreMachinedMethodBuilder.fromList)
      );
  }

  deletePreMachinedMethod(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  updatePreMachinedMethod(preMachinedMethod: PreMachinedMethod): Observable<PreMachinedMethod> {
    return this.httpClient.put(`${this.apiUrl}/${preMachinedMethod.hash}`, getPreMachinedMethodBody(preMachinedMethod))
      .pipe(
        map(PreMachinedMethodBuilder.fromJson)
      );
  }

  createPreMachinedMethod(preMachinedMethod: PreMachinedMethod): Observable<PreMachinedMethod> {
    return this.httpClient.post(this.apiUrl, getPreMachinedMethodBody(preMachinedMethod))
      .pipe(
        map(PreMachinedMethodBuilder.fromJson)
      );
  }
}
