import { mapCollection } from '../../utils/collection.utils';
import { CommercialActionType } from '../commercial-action-type';

export class CommercialActionTypeBuilder {
  static fromJson(json: any) {
    return (json)
      ? new CommercialActionType(
        json.hash,
        json.name,
        json.code,
      )
      : null;
  }

  static fromList(list): CommercialActionType[] {
    return Array.isArray(list)
      ? mapCollection(CommercialActionTypeBuilder.fromJson, list)
      : [];
  }
}
