import { CommercialActionType } from './commercial-action-type';
import { WorkGroup } from './work-group';

export class InternalAction {
  constructor(
    public hash?: string,
    public subject?: string,
    public date?: string,
    public time?: number,
    public reminderDate?: string,
    public notifyWorkGroupUsers?: boolean,
    public comments?: string,
    public cost?: number,
    public workGroup?: WorkGroup,
    public actionType?: CommercialActionType,
    public filesCount?: number,
  ) { }

  clone() {
    return new InternalAction(
      this.hash,
      this.subject,
      this.date,
      this.time,
      this.reminderDate,
      this.notifyWorkGroupUsers,
      this.comments,
      this.cost,
      this.workGroup,
      this.actionType,
      this.filesCount
    );
  }

  get actionTypeName(): string {
    return this.actionType != null
      ? this.actionType.name
      : null;
  }

  get workGroupName(): string {
    return this.workGroup != null
      ? this.workGroup.name
      : null;
  }
}
