import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit?: number, includeEllipsis = true): string {
    if (!value) {
      return '';
    }

    const truncatedValue = value.substring(0, limit || 3);
    return includeEllipsis && value.length > limit ? truncatedValue + '...' : truncatedValue;
  }
}
