import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { loginRoute } from '../../config/routes.config';
import { isBadRequest, isUnauthorized } from '../../utils/response.utils';
import {
  getAnonymousHeader,
  getAnonymousOptions,
  HEADER_AVOID_ERROR_HANDLING,
  HEADER_PENDING_NO_BLOCKING
} from '../base/headers';
import { deleteSession, getSession, setUserCommercialId, setSession } from '../storage.service';

@Injectable()
export class AuthApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.apiUrl = `${environment.apiUrl}auth`;
  }

  checkPasswordRecoveringHash(hash) {
    return this.httpClient.get(`${this.apiUrl}/recover-password/${hash}`, getAnonymousOptions());
  }

  login(email: string, password: string) {
    const url = `${this.apiUrl}/login`;
    const body = { email, password };
    const headers = getAnonymousHeader().set(HEADER_AVOID_ERROR_HANDLING, 'true');

    return this.httpClient.post(url, body, { headers })
      .pipe(
        map(this.saveToken)
      );
  }

  recoverPassword(email) {
    const postBody = { email };
    const headers = getAnonymousHeader().set(HEADER_AVOID_ERROR_HANDLING, 'true');
    const options = { headers };
    return this.httpClient.post(`${this.apiUrl}/recover-password`, postBody, options);
  }

  refreshToken() {
    const url = `${this.apiUrl}/token`;
    const session = getSession();
    const refreshToken = (session) ? session.refreshToken : null;
    const headers = getAnonymousHeader().set(HEADER_PENDING_NO_BLOCKING, 'true').set(HEADER_AVOID_ERROR_HANDLING, 'true');
    const options = { params: { refresh_token: refreshToken }, headers };

    return this.httpClient.post(url, null, options)
      .pipe(
        map(this.saveToken),
        catchError((errorAfterRefresh) => {
          if (isBadRequest(errorAfterRefresh) || isUnauthorized(errorAfterRefresh)) {
            this.logout();
          }

          return throwError(errorAfterRefresh);
        }),
      );
  }

  resetPassword(hash, password, passwordRepeat) {
    const postBody = { hash, password, passwordRepeat };
    return this.httpClient.post(`${this.apiUrl}/reset-password`, postBody, getAnonymousOptions());
  }

  changePassword(userHash: string, password: string, passwordRepeat: string) {
    const postBody = { userHash, password, passwordRepeat };
    return this.httpClient.post(`${this.apiUrl}/change-password`, postBody);
  }

  private logout() {
    deleteSession();
    this.router.navigate([loginRoute]);
  }

  private saveToken = (data: any) => {
    const session = { token: data.token, refreshToken: data.refreshToken };
    const commercial =  data?.commercialId ?? '';
    setSession(session);
    setUserCommercialId(commercial);
  };
}
