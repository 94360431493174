import { User } from './User';

export class WorkGroup {
  constructor(
    public hash?: string,
    public name?: string,
    public users?: User[],
  ) { }

  clone() {
    return new WorkGroup(
      this.hash,
      this.name,
      this.users
    );
  }
}
