import { Document } from '@/core/models/document';
import { Folder } from '@/core/models/folder';
import { DropboxBatchDeleteResponse } from '@/core/responses/DropboxBatchDeleteResponse';
import { DropboxTemporaryUrlResponse } from '@/core/responses/DropboxGetTemporaryUrlResponse';
import { DocumentApiService } from '@/core/services/api/documents-api.service';
import { FolderApiService } from '@/core/services/api/folder-api.service';
import { ResolverService } from '@/core/services/base/resolver.service';
import { getEmptyAsyncArray, sortByString } from '@/core/utils/collection.utils';
import { ProjectGroupReportAssistant } from '@/dashboard/project-groups/project-group-report-assistant/project-group-report-assistant';
import { MoveDocumentInterface } from '@/dashboard/shared/commercial-actions-shared/edit-commercial-actions-shared/move-document.interface';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable()
export class DocumentsService {
  constructor(
    private documentsApiService: DocumentApiService,
    private folderApiService: FolderApiService,
    private resolverService: ResolverService
  ) { }

  copyDocument(fromPath: string, toPath: string): Promise<Document[]> {
    return this.documentsApiService.copyDocument(fromPath, toPath).toPromise();
  }

  getDocuments(folderPath: string): Observable<Document[]> {
    return this.documentsApiService.getDocuments(folderPath)
      .pipe(
        map((documents: Document[]) => sortByString('name', documents))
      );
  }

  getFolders(folderPath: string): Observable<Folder[]> {
    return this.folderApiService.getFolders(folderPath);
  }

  downloadDocument(path: string): Promise<ArrayBuffer> {
    return this.documentsApiService.downloadDocument(path).toPromise();
  }

  uploadDocument(file: FormData) {
    return this.documentsApiService.uploadDocument(file);
  }

  uploadFile(file: File, path: string) {
    return this.documentsApiService.uploadFile(file, path);
  }

  moveDocument(paths: MoveDocumentInterface[]): Promise<Boolean[]> {
    return this.documentsApiService.moveDocument(paths).toPromise();
  }

  createFolder(folderName: string, folderPath: string) {
    return this.folderApiService.createFolder(folderName, folderPath).toPromise();
  }

  deleteDocument(path: string): Promise<Object> {
    return this.documentsApiService.deleteDocument(path).toPromise();
  }

  deleteDocuments(folderPaths: string[]): Promise<DropboxBatchDeleteResponse> {
    return this.documentsApiService.deleteDocuments(folderPaths).toPromise();
  }

  checkDeleteBatch(jobId): Promise<Object> {
    return this.documentsApiService.checkDeleteBatch(jobId).toPromise();
  }

  getTemporaryUrl(path: string): Promise<DropboxTemporaryUrlResponse> {
    return this.documentsApiService.getTemporaryUrl(path).toPromise();
  }

  getThumbnail(path: string): Promise<ArrayBuffer> {
    return this.documentsApiService.getThumbnail(path).toPromise();
  }

  getBigThumbnail(path: string): Promise<ArrayBuffer> {
    return this.documentsApiService.getBigThumbnail(path).toPromise();
  }

  resolveDocuments(folderPath: string): Observable<Document[]> {
    this.resolverService.onResolverStart();
    return this.getDocuments(folderPath)
      .pipe(
        catchError(getEmptyAsyncArray),
        finalize(this.resolverService.onResolverStop),
      );
  }

  resolveFolders(folderPath: string): Observable<Folder[]> {
    this.resolverService.onResolverStart();
    return this.getFolders(folderPath)
      .pipe(
        catchError(getEmptyAsyncArray),
        finalize(this.resolverService.onResolverStop)
      );
  }

  resolveGenerateProjecGroupReport(assistant: ProjectGroupReportAssistant): Observable<ArrayBuffer> {
    this.resolverService.onResolverStart();
    return this.documentsApiService.generateProjectGroupGeneralReport(assistant)
      .pipe(
        catchError(() => of(null)),
        finalize(this.resolverService.onResolverStop),
      );
  }
}
