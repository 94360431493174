export class ProjectDeliveryNote {
  constructor(
    public deliveryNoteNumber: number
  ) {}

  clone(): ProjectDeliveryNote {
    return new ProjectDeliveryNote(
      this.deliveryNoteNumber
    );
  }
}
