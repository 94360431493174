import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { hasReadAccessForAnySubsection } from '../../core/utils/jwt.utils';

@Directive({
  selector: '[appRequireReadAccessForAnySection]'
})
export class RequireReadAccessForAnySectionDirective implements OnInit {
  @Input() subsections: string[];

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    const hasReadAccesses = hasReadAccessForAnySubsection(this.subsections);

    if (!hasReadAccesses) {
      this.elementRef.nativeElement.remove();
    }
  }
}
