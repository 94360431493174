<app-input-field
  [form]="form"
  type="text"
  [id]="id"
  [controlName]="controlName"
  [key]="key"
  [class]="class"
  [minlength]="minlength"
  [maxlength]="maxlength"
  [hideErrors]="hideErrors"
  [hideLabel]="hideLabel"
  [readonly]="readonly"
  [list]="list"
></app-input-field>
