export class TrialObjective {
  constructor(
    public hash?: string,
    public name?: string,
    public defaultValue?: Boolean
  ) {}

  clone() {
    return new TrialObjective(
      this.hash,
      this.name,
      this.defaultValue,
    );
  }
}
