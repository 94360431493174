export class TrialLine {
  constructor(
    public hash?: string,
    public trialHash?: string,
    /** Nº pc. */
    public noOfParts?: string,
    /** Proceso previo */
    public previousProcess?: string,
    /** Ap (mm) */
    public axialDepthCut?: number,
    /** Presión */
    public pressure?: number,
    /** vc [m/min] */
    public cuttingSpeed?: number,
    /** n [1/min] */
    public revolutions?: number,
    /** Avance por diente (fz [mm]) */
    public feedPerTooth?: number,
    /** Avance por revolución (f [mm/rev]) */
    public feedPerRevolution?: number,
    /** vf [mm/min] */
    public tableFeed?: number,
    /** salida [mm/min] */
    public output?: string,
    /** tc [s] */
    public machiningTime?: number,
    /** Coste por agujero */
    public costPerBore?: number,
    /** Radio de rodillo */
    public rollerRadius?: number,
    /** Notas */
    public notes?: string,
    /** Resultados */
    public resultTotalBores?: number,
    public resultRoughness?: number,
    public resultDiameter?: number,
    public resultIsOk?: Boolean,
    public resultsUpdated?: string,
    public filesCount?: number,
  ) {}
}
