import { STORAGE_NAME, STORAGE_SYNC_TABLE_NAME, STORED_ITEM_ACTIONS, STORED_ITEM_TYPES } from '@/core/config/storage-constants';
import { CommercialAction } from '@/core/models/commercial-action';
import { first } from '@/core/utils/collection.utils';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';

type StoredItemType = typeof STORED_ITEM_TYPES[number];
type StoredItemActions = typeof STORED_ITEM_ACTIONS[number];
type ConstructorFunction<T> = (description: Object) => T;

interface ISyncItem {
  id?: number;
  type: StoredItemType;
  action: StoredItemActions;
  hash: string;
  description: CommercialAction;
}

@Injectable()
export class OfflineStorageService extends Dexie {
  syncQueue: Dexie.Table<ISyncItem, number>;

  constructor() {
    super(STORAGE_NAME);

    this.version(2).stores({
      syncQueue: '++id, entityId, hash, type, action'
    });

    this.syncQueue = this.table(STORAGE_SYNC_TABLE_NAME);
  }

  async addToSyncQueue(syncItem: ISyncItem): Promise<number | void> {
    const existingItems = await this.syncQueue
      .where('hash')
      .equals(syncItem.hash)
      .toArray();

    const existingItem = existingItems.find(item => item.type === syncItem.type);

    if (existingItem) {
      await this.syncQueue.update(existingItem.id, { description: syncItem.description });
    } else {
      return await this.syncQueue.add(syncItem);
    }
  }

  async findByHashAndType<T>(hash: string, type: StoredItemType, constructorFn: ConstructorFunction<T>): Promise<T | null> {
    const item: ISyncItem = await this.findSyncItemByHashAndType(hash, type);
    if (item === null) {
      return null;
    } else {
      return constructorFn(item.description);
    }
  }

  async findByActionAndType<T>(
    itemType: StoredItemType,
    itemAction: StoredItemActions,
    constructorFn: ConstructorFunction<T>
  ): Promise<T[] | []> {
    const items: ISyncItem[] = await this.findSyncItemsByActionAndType(itemAction, itemType);
    return items.map(item => constructorFn(item.description));
  }

  async countStoredElementsToSync(): Promise<number> {
    return await this.syncQueue.count();
  }

  async deleteByHashAndType(hash: string, type: StoredItemType): Promise<void> {
    const items = await this.syncQueue
      .where({ hash, type })
      .toArray();

    for (const item of items) {
      if (item.id !== undefined) {
        await this.syncQueue.delete(item.id);
      } else {
        console.error('The item has not id', item);
      }
    }
  }

  private async findSyncItemByHashAndType(hash: string, type: StoredItemType): Promise<ISyncItem | null> {
    const existingItems = await this.syncQueue
      .where({ hash, type })
      .toArray();

    return first(existingItems);
  }

  private async findSyncItemsByActionAndType(action: StoredItemActions, type: StoredItemType): Promise<ISyncItem[]> {
    const matchingItems = await this.syncQueue
      .where({ type, action })
      .toArray();

    return matchingItems;
  }
}
