import { mapCollection } from '../../utils/collection.utils';
import { MaterialIso } from '../material-iso';

export class MaterialIsoBuilder {
  static fromJson(json: any): MaterialIso {
    return (json)
      ? new MaterialIso(
        json.hash,
        json.name,
      )
      : null;
  }

  static fromList(list: any): MaterialIso[] {
    return Array.isArray(list)
      ? mapCollection(MaterialIsoBuilder.fromJson, list)
      : [];
  }
}
