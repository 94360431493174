import { mapCollection } from '../../utils/collection.utils';
import { User } from '../User';
import { PrivilegeBuilder } from './privilege.builder';

export class UserBuilder {
  static fromJson(json) {
    return (json)
      ? new User(
        json.hash,
        json.firstName,
        json.lastName,
        json.nick,
        json.email,
        PrivilegeBuilder.fromJson(json.privilege)
      )
      : null;
  }

  static fromList(list) {
    return (Array.isArray(list)) ? mapCollection(UserBuilder.fromJson, list) : [];
  }
}
