export const PERMISSION_NOTIFICATIONS = 'NOTIFICATIONS';

export const PERMISSION_TRIAL_SHEETS = 'TRIAL_SHEETS';

export const PERMISSION_ACTIONS = 'ACTIONS';

export const PERMISSION_INTERNAL_ACTIONS = 'INTERNAL_ACTIONS';

export const PERMISSION_WORK_GROUPS = 'WORK_GROUPS';

export const PERMISSION_PROSPECTING = 'PROSPECTING';

export const PERMISSION_DASHBOARD = 'DASHBOARD';

export const PERMISSION_DOCUMENTS = 'DOCUMENTS';

export const PERMISSION_VIEW_ALL_PROJECTS = 'VIEW_ALL_PROJECTS';

export const PERMISSION_APPLICATIONS = 'APPLICATIONS';

export const PERMISSION_MATERIALS = 'MATERIALS';

export const PERMISSION_MACHINES = 'MACHINES';

export const PERMISSION_COMPETITORS = 'COMPETITORS';

export const PERMISSION_PROCESSES = 'PROCESSES';

export const PERMISSION_PIECE_TYPES = 'PIECE_TYPES';

export const PERMISSION_SPECIFICATIONS = 'SPECIFICATIONS';

export const PERMISSION_PRE_MACHINED_METHODS = 'PRE_MACHINED_METHODS';

export const PERMISSION_HARDNESSES = 'HARDNESSES';

export const PERMISSION_TRIAL_OBJECTIVES = 'TRIAL_OBJECTIVES';

export const PERMISSION_PROJECT_SINKING_REASONS = 'PROJECT_SINKING_REASONS';

export const PERMISSION_USERS = 'USERS';

export const PERMISSION_PRIVILEGES = 'PRIVILEGES';

export const PERMISSION_PERMISSIONS = 'PERMISSIONS';

export const PERMISSION_BUDGETS = 'BUDGETS';

export const PERMISSION_BUDGET_DASHBOARD = 'BUDGET_DASHBOARD';

export const PERMISSION_REPORTS = 'REPORTS';

export const PERMISSION_WEEKLY_REPORT = 'WEEKLY_REPORT';

export const PERMISSION_NOTIFICATION_PROJECT_WITHOUT_OFFER = 'NOTIFICATION_PROJECT_WITHOUT_OFFER';

export const PERMISSION_NOTIFICATION_PROJECT_PENDING_SUPPLIER_RESPONSE = 'NOTIFICATION_PROJECT_PENDING_SUPPLIER_RESPONSE';

export const ADMIN_SUBSECTIONS = [
  PERMISSION_APPLICATIONS,
  PERMISSION_MATERIALS,
  PERMISSION_MACHINES,
  PERMISSION_COMPETITORS,
  PERMISSION_PROCESSES,
  PERMISSION_PIECE_TYPES,
  PERMISSION_SPECIFICATIONS,
  PERMISSION_PRE_MACHINED_METHODS,
  PERMISSION_HARDNESSES,
  PERMISSION_USERS,
  PERMISSION_PRIVILEGES,
  PERMISSION_PERMISSIONS,
  PERMISSION_TRIAL_OBJECTIVES,
  PERMISSION_PROJECT_SINKING_REASONS,
];
