<ngx-smart-modal
  #modal
  identifier="modal"
  [customClass]="modalClass"
  [closable]="false"
  [dismissable]="dismissable"
  [escapable]="escapable"
  [hideDelay]="0"
  (onClose)="onModalClose()"
  (onDismiss)="onModalClose()"
  (onEscape)="onModalClose()"
>
  <div class="modal-header">
    <a (click)="close()" class="close">✕</a>

    <h4 *ngIf="title">
      {{ title | translate: titleParams }}
    </h4>
  </div>

  <div class="modal-body">
    <ng-content></ng-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="button" (click)="onCancel()" [innerHTML]="cancelLabel | translate"></button>
    <button type="button" class="button button-primary" (click)="onSave()" [disabled]="saveDisabled" [innerHTML]="saveLabel | translate"></button>
  </div>
</ngx-smart-modal>
