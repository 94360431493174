<div [formGroup]="form" *ngIf="form.get(controlName) as control">
  <div class="box-error" *ngIf="!hideErrors && control.touched">
    <p class="error" *ngIf="control.hasError('required')">{{ getCompleteKey('required') | translate }}</p>
    <p class="error" *ngIf="control.hasError('email')">{{ getCompleteKey('email') | translate }}</p>
    <p class="error" *ngIf="control.hasError('maxlength')">{{ getCompleteKey('maxlength') | translate }}</p>
    <p class="error" *ngIf="control.hasError('minlength')">{{ getCompleteKey('minlength') | translate }}</p>
    <p class="error" *ngIf="control.hasError('pattern')">{{ getCompleteKey('pattern') | translate }}</p>
    <p class="error" *ngIf="control.hasError('match')">{{ getCompleteKey('password_mismatch') | translate }}</p>
    <p class="error" *ngIf="control.hasError('required')">{{ getCompleteKey('required') | translate }}</p>
    <p class="error" *ngIf="control.hasError('mimeType')">{{ getCompleteKey('mime_type') | translate }}</p>
    <p class="error" *ngIf="control.hasError('fileSize')">{{ getCompleteKey('file_size') | translate }}</p>
    <p class="error" *ngIf="control.hasError('custom')">{{ control.getError('custom') }}</p>
  </div>
</div>
