import { mapCollection } from '../../utils/collection.utils';
import { TrialLine } from '../trial-line';

export class TrialLineBuilder {
  static fromJson(json: any): TrialLine {
    return (json)
      ? new TrialLine(
          json.hash,
          json.trialHash,
          json.noOfParts,
          json.previousProcess,
          json.axialDepthCut,
          json.pressure,
          json.cuttingSpeed,
          json.revolutions,
          json.feedPerTooth,
          json.feedPerRevolution,
          json.tableFeed,
          json.output,
          json.machiningTime,
          json.costPerBore,
          json.rollerRadius,
          json.notes,
          json.resultTotalBores,
          json.resultRoughness,
          json.resultDiameter,
          json.resultIsOk,
          json.resultUpdated,
          json.filesCount,
        )
        : null;
  }

  static fromList(list: any): TrialLine[] {
    return Array.isArray(list)
      ? mapCollection(TrialLineBuilder.fromJson, list)
      : [];
  }
}
