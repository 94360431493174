import { DeliveryNote } from './delivery-note';

export class DeliveryNoteGroup {
  constructor(
    public customerId: number,
    public customerName: string,
    public totalPriceEstimated: number,
    public totalPrice: number,
    public reviewed: boolean,
    public deliveryNotes: DeliveryNote[],
  ) {}

  clone(): DeliveryNoteGroup {
    return new DeliveryNoteGroup(
      this.customerId,
      this.customerName,
      this.totalPriceEstimated,
      this.totalPrice,
      this.reviewed,
      this.deliveryNotes,
    );
  }
}
