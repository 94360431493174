<div
  [class]="class"
  [formGroup]="form"
  *ngIf="form.get(controlName) as control"
  [class.error]="control.touched && control.errors"
  [class.field--required]="hasRequiredField(control)">
  <label [for]="id" [translate]="key"></label>
  <app-file-input
    [id]="id"
    [form]="form"
    [controlName]="controlName"
    [accept]="accept"
    (fileChanged)="fileChanged.emit($event)"
  ></app-file-input>
  <div class="box-error" *ngIf="!hideErrors && control.touched">
    <p class="error" *ngIf="control.hasError('required')" when="touched" translate>error.required</p>
    <p class="error" *ngIf="control.hasError('mimeType')" when="touched" translate>error.mime_type</p>
    <p class="error" *ngIf="control.hasError('fileSize')" when="touched" translate [translateParams]="{ size: control.getError('fileSize') }">error.file_size</p>
    <p class="error" *ngIf="control.hasError('custom')" when="touched">{{ control.getError('custom') }}</p>
  </div>
</div>
