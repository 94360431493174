import { mapCollection } from 'src/app/core/utils/collection.utils';

export class InternalActionSubjectSuggestionBuilder {
  static fromJson(name: string): string {
    return (name)
      ? name
      : null;
  }

  static fromList(list: string[]): string[] {
    return Array.isArray(list)
      ? mapCollection(InternalActionSubjectSuggestionBuilder.fromJson, list)
      : [];
  }
}
