import { PROCESS_TYPE_EXTERNAL } from '../config/constants';
import { Contact } from './contact';
import { Customer } from './customer';
import { PieceType } from './piece-type';
import { Process } from './process';
import { ProjectCommercial } from './project-commercial';
import { ProjectDeliveryNote } from './project-delivery-note';
import { ProjectInvoice } from './project-invoice';
import { ProjectSinkingReason } from './project-sinking-reason';
import { Sector } from './sector';
import { Supplier } from './supplier';

export class Project {
  constructor(
    public id?: number,
    public hash?: string,
    public name?: string,
    public status?: string,
    public customer?: Customer,
    public commercials?: ProjectCommercial[],
    public contacts?: Contact[],
    public planeNumber?: string,
    public application?: string,
    public image?: string,
    public dropboxFolderId?: string,
    public endStatus?: string,
    public sinkingReason?: ProjectSinkingReason,
    public endReason?: string,
    public startupMonths?: number,
    public pieceName?: string,
    public pieceType?: PieceType,
    public process?: Process,
    public processType?: number,
    public alertsCount?: number,
    public commercialActionsCount?: number,
    public trialSheetsCount?: number,
    public sector?: Sector,
    public supplier?: Supplier,
    public projectGroupHash?: string,
    public projectGroupName?: string,
    public projectGroupDropboxFolderId?: string,
    public createdAt?: Date,
    public statusDate?: Date,
    public favorite?: boolean,
    public erpOfferNumber?: number,
    public erpOfferRevisionNumber?: number,
    public deliveryNotes?: ProjectDeliveryNote[],
    public invoices?: ProjectInvoice[],
    public projectWarnings?: string[],
    public pendingSupplierTemplate?: boolean,
    public pendingSupplierResponse?: boolean,
  ) { }

  clone(): Project {
    return new Project(
      this.id,
      this.hash,
      this.name,
      this.status,
      this.customer,
      this.commercials,
      this.contacts,
      this.planeNumber,
      this.application,
      this.image,
      this.dropboxFolderId,
      this.endStatus,
      this.sinkingReason,
      this.endReason,
      this.startupMonths,
      this.pieceName,
      this.pieceType,
      this.process,
      this.processType,
      this.alertsCount,
      this.commercialActionsCount,
      this.trialSheetsCount,
      this.sector,
      this.supplier,
      this.projectGroupHash,
      this.projectGroupName,
      this.projectGroupDropboxFolderId,
      this.createdAt,
      this.statusDate,
      this.favorite,
      this.erpOfferNumber,
      this.erpOfferRevisionNumber,
      this.deliveryNotes,
      this.invoices,
      this.projectWarnings,
      this.pendingSupplierTemplate,
      this.pendingSupplierResponse,
    );
  }

  get projectGroupAndProjectName(): string {
    return this.projectGroupName + ' > ' + this.application;
  }

  get isExternalProcess(): boolean {
    return this.processType.toString() === PROCESS_TYPE_EXTERNAL;
  }

  get hasOffer(): boolean {
    return this.erpOfferNumber !== null;
  }
}
