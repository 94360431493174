<ngx-smart-modal
  #modal
  identifier="modal"
  [customClass]="modalClass"
  [closable]="false"
  [dismissable]="dismissable"
  [escapable]="escapable"
  [hideDelay]="0"
  (onDismiss)="onAccept()"
>
  <div class="modal-header">
    <h4 *ngIf="title">{{ title | translate: titleParams }}</h4>
  </div>

  <div class="modal-body">
    <ng-content></ng-content>
    <ng-container *ngIf="!content">
      <p [innerHTML]="message | translate"></p>
      <p *ngFor="let messageParam of messageParams" [translate]="messageParam"></p>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button type="button" class="button" (click)="onAccept()" [innerHTML]="acceptLabel | translate"></button>
  </div>
</ngx-smart-modal>

