export class ProjectSinkingReason {
  constructor(
    public hash?: string,
    public name?: string,
  ) { }

  clone(): ProjectSinkingReason {
    return new ProjectSinkingReason(
      this.hash,
      this.name
    );
  }

}
