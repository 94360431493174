import { MachiningStudy } from '@/core/models/machining-study';

export const getMachiningStudyBody = (machiningStudy: MachiningStudy) => ({
  projectHash: machiningStudy.project.hash,
  machineHash: machiningStudy.machine.hash,
  stability: machiningStudy.stability,
  isStabilityOk: machiningStudy.isStabilityOk,
  machineComments: machiningStudy.machineComments,
  lubricantType: machiningStudy.lubricantType,
  emulsionPercentage: machiningStudy.emulsionPercentage,
  internalCooling: machiningStudy.internalCooling,
  refrigerantPressure: machiningStudy.refrigerantPressure,
  lubricantComments: machiningStudy.lubricantComments,
  materialHash: machiningStudy.material.hash,
  materialDenominationHash: machiningStudy.materialDenomination.hash,
  materialIsoHash: machiningStudy.materialIso.hash,
  materialIsoOld: machiningStudy.materialIsoOld ?? '',
  hardnessHash: machiningStudy.hardness.hash,
  hardnessValue: machiningStudy.hardnessValue,
  specificationHash: machiningStudy.specification.hash,
  noOfParts: machiningStudy.noOfParts,
  boresPerPart: machiningStudy.boresPerPart,
  totalBores: machiningStudy.totalBores,
  pieceDescription: machiningStudy.pieceDescription,
  preMachinedMethodHash: machiningStudy.preMachinedMethod?.hash,
  preMachinedDiameter: machiningStudy.preMachinedDiameter,
  preMachinedRoughnessType: machiningStudy.preMachinedRoughnessType,
  preMachinedRoughnessValue: machiningStudy.preMachinedRoughnessValue,
  cuttingDiameter: machiningStudy.cuttingDiameter,
  cuttingDepth: machiningStudy.cuttingDepth,
  toleranceModeSimple: machiningStudy.toleranceModeSimple,
  tolerancePositionSimple: machiningStudy.tolerancePositionSimple,
  toleranceQualitySimple: machiningStudy.toleranceQualitySimple,
  tolerancePosition: machiningStudy.tolerancePosition,
  toleranceQuality: machiningStudy.toleranceQuality,
  tolerance: machiningStudy.tolerance,
  holeType: machiningStudy.holeType,
  toolLength: machiningStudy.toolLength,
  additionalRequirements: machiningStudy.additionalRequirements,
  interruptedCut: machiningStudy.interruptedCut,
  interruptedCutComments: machiningStudy.interruptedCutComments,
  targetTime: machiningStudy.targetTime,
  targetQuantity: machiningStudy.targetQuantity,
  roughnessType: machiningStudy.roughnessType,
  roughnessValue: machiningStudy.roughnessValue,
  cycleTime: machiningStudy.cycleTime,
  pieceRequirementsComments: machiningStudy.pieceRequirementsComments,
});
