import { mapCollection } from '../../utils/collection.utils';
import { CommercialActionFile } from '../commercial-action-file';

export class CommercialActionFileBuilder {
  static fromJson(json: any) {
    return (json)
      ? new CommercialActionFile(
          json.hash,
          json.fileId,
          json.fileName,
          json.path,
        )
      : null;
  }

  static fromList(list): CommercialActionFile[] {
    return Array.isArray(list)
      ? mapCollection(CommercialActionFileBuilder.fromJson, list)
      : [];
  }
}
