import { Machine } from './machine';
import { Material } from './material';
import { MaterialDenomination } from './material-denomination';
import { MaterialIso } from './material-iso';
import { TrialLine } from './trial-line';
import { TrialObjective } from './trial-objective';
import { RoiComparator } from './trial/roi-comparator';
import { TrialParameters } from './trial/trial-parameters';

export class Trial {
  constructor(
    public hash?: string,
    public created?: Date,
    public document?: string,
    public trialObjective?: TrialObjective,
    public plate?: string,
    public jump?: string,
    public reamer?: string,
    public material?: Material,
    public machine?: Machine,
    public materialDenomination?: MaterialDenomination,
    public materialIso?: MaterialIso,
    public materialIsoOld?: string,
    public parameters: TrialParameters = new TrialParameters(),
    public urma: RoiComparator = new RoiComparator(),
    public competitor: RoiComparator = new RoiComparator(),
    public totalToolCostDiff?: number,
    public totalToolCostDiffPercent?: number,
    public savingOfMachiningTime?: number,
    public savingOfMachiningTimePercent?: number,
    public savingPerMachiningTime?: number,
    public savingPerBore?: number,
    public savingPerBorePercent?: number,
    public savingPerSerieOrYear?: number,
    public trialLines?: TrialLine[],
    public currentTrialLine?: TrialLine,
  ) {}

  trialLineIsCurrent(trialLine: TrialLine): boolean {
    return this.currentTrialLine?.hash === trialLine.hash;
  }
}
