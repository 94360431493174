import { Project } from '@/core/models/project';
import { mapCollection } from '@/core/utils/collection.utils';
import { ContactBuilder } from './contact.builder';
import { CustomerBuilder } from './customer.builder';
import { ProjectCommercialBuilder } from './project-commercial.builder';
import { ProjectDeliveryNoteBuilder } from './project-delivery.builder';
import { ProjectInvoiceBuilder } from './project-invoice.builder';
import { SupplierBuilder } from './supplier.builder';
import { ProjectSinkingReasonBuilder } from './project-sinking-reason.builder';

export class ProjectBuilder {
  static fromJson(json: any): Project {
    return (json)
      ? new Project(
          json.id,
          json.hash,
          json.name,
          json.status,
          CustomerBuilder.fromJson(json.customer),
          ProjectCommercialBuilder.fromList(json.commercials),
          ContactBuilder.fromList(json.contacts),
          json.planeNumber,
          json.application,
          json.image,
          json.dropboxFolderId,
          json.endStatus,
          ProjectSinkingReasonBuilder.fromJson(json.sinkingReason),
          json.endReason,
          json.startupMonths,
          json.pieceName,
          json.pieceType,
          json.process,
          json.processType,
          json.alertsCount,
          json.commercialActionsCount,
          json.trialSheetsCount,
          json.sector,
          SupplierBuilder.fromJson(json.supplier),
          json.projectGroupHash,
          json.projectGroupName,
          json.projectGroupDropboxFolderId,
          json.createdAt,
          json.statusDate,
          json.favorite,
          json.erpOfferNumber,
          json.erpOfferRevisionNumber,
          ProjectDeliveryNoteBuilder.fromList(json.deliveryNotes),
          ProjectInvoiceBuilder.fromList(json.invoices),
          json.projectWarnings,
          json.pendingSupplierTemplate,
          json.pendingSupplierResponse,
        )
      : null;
  }

  static fromList(list: any): Project[] {
    return (Array.isArray(list))
      ? mapCollection(ProjectBuilder.fromJson, list)
      : [];
  }
}
