import { mapCollection } from '../../utils/collection.utils';
import { OfferLine } from '../offer-line';

export class OfferLineBuilder{
  static fromJson(json: any): OfferLine {
    return json ?
      new OfferLine(
        json.id,
        json.articleId,
        json.description,
        json.quantity,
        json.amount
      ) : null;
  }
  static fromList(list: any): OfferLine[] {
    return Array.isArray(list)
    ? mapCollection(OfferLineBuilder.fromJson, list)
    : [];
  }
}
