import { mapCollection } from '@/core/utils/collection.utils';
import { NotifiedUser } from '../notified-user';

export class NotifiedUserBuilder {
  static fromJson(json): NotifiedUser {
    return (json)
      ? new NotifiedUser(
        json.hash,
        json.firstName,
        json.lastName
      )
      : null;
  }

  static fromList(list): NotifiedUser[] {
    return Array.isArray(list)
      ? mapCollection(NotifiedUserBuilder.fromJson, list)
      : [];
  }
}
