import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.css']
})
export class ColumnComponent {
  @Input() sortable = false;
  @Input() defaultSorting = false;
  @Input() title: string;
  @Input() icon: string;
  @Input() property: string;
  @Input() type = 'string';
  @Input() width: string;
  @Input() minWidth: string;
  @Input() iconColumn: (value: any) => string = null;
  @Input() transformation: (value: any) => string;
  @Input() imageUrl: (value: any) => string;
  @Input() linkable = false;
  @Input() styleColumn: (value: any) => string = null;
  @Output() clicked = new EventEmitter<any>();
}
