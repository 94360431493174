export class Document {
  constructor(
    public name?: string,
    public path?: string,

  ) { }
  clone() {
    return new Document(
      this.path,
      this.name
    );
  }
}
