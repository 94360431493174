import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProcessesRoutingModule } from './processes-routing.module';
import { ProcessesTableComponent } from './process-table/processes-table.component';
import { EditProcessComponent } from './edit-process/edit-process.component';
import { EditProcessFormComponent } from './edit-process/edit-process-form/edit-process-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared/shared.module';
import { ProcessService } from './processes.service';
import { ProcessesResolver } from './processes.resolver';
import { ProcessResolver } from './process.resolver';

@NgModule({
  declarations: [
    ProcessesTableComponent,
    EditProcessComponent,
    EditProcessFormComponent
  ],
  imports: [
    ProcessesRoutingModule,
    TranslateModule,
    CommonModule,
    SharedModule
  ],
  providers: [
    ProcessService,
    ProcessesResolver,
    ProcessResolver
  ]
})
export class ProcessesModule { }
