import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { notFoundRoute } from '../config/routes.config';
import { ErrorApiService } from '../services/api/error-api.service';
import { MESSAGE } from './route-error';

const isRouterError = (error) => (error && error.rejection && error.rejection.message === MESSAGE);

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  private router: Router;
  private errorApiService: ErrorApiService;

  constructor(
    private injector: Injector
  ) { }

  async handleError(error: any) {
    this.getDependencies();
    if (isRouterError(error)) {
      this.router.navigateByUrl(notFoundRoute);
      return;
    }

    await this.errorApiService.save(error.message).toPromise();
    throw error;
  }

  private getDependencies() {
    if (!this.router) {
      this.router = this.injector.get(Router);
    }
    if (!this.errorApiService) {
      this.errorApiService = this.injector.get(ErrorApiService);
    }
  }
}
