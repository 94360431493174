
export class Supplier {
  constructor(
    public hash?: string,
    public name?: string,
    public logo?: string
  ) { }

  clone() {
    return new Supplier(
      this.hash,
      this.name,
      this.logo
    );
  }
}
