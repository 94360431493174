export class Privilege {
  constructor(
    public hash?: string,
    public name?: string,
    public code?: string
  ) {}

  clone() {
    return new Privilege(
      this.hash,
      this.name,
      this.code
    );
  }
}
