import { ProjectSinkingReason } from '@/core/models/project-sinking-reason';
import { mapCollection } from '@/core/utils/collection.utils';

export class ProjectSinkingReasonBuilder {
  static fromJson(json: any): ProjectSinkingReason {
    return (json) ? new ProjectSinkingReason(
      json.hash,
      json.name
    ) : null;
  }

  static fromList(list: any): ProjectSinkingReason[] {
    return (Array.isArray(list))
      ? mapCollection(ProjectSinkingReasonBuilder.fromJson, list)
      : [];
  }

}
