<div
  class="connection-status"
  [ngClass]="onlineStatus ? 'connected' : 'disconnected'"
>
  <div *ngIf="onlineStatus; else offlineTemplate">
    <span class="icon icon--online"></span>
    <a *ngIf="onlineStatus && notSyncedCount > 0" (click)="onSync()" translate>shared.connectionStatus.syncNow</a>
    <span *ngIf="notSyncedCount > 0"> ({{ notSyncedCount }})</span>
  </div>
  <ng-template #offlineTemplate>
    <span class="icon icon--offline"></span>
  </ng-template>
</div>
