import { UserApiService } from '@/core/services/api/user-api.service';
import { Injectable } from '@angular/core';
import { CommercialActionTypeApiService } from '../api/commercial-action-type-api.service';
import { CommercialApiService } from '../api/commercial-api.service';
import { CustomerApiService } from '../api/customer-api.service';
import { HardnessApiService } from '../api/hardness-api.service';
import { MachineApiService } from '../api/machine-api.service';
import { MaterialApiService } from '../api/material-api.service';
import { PieceTypeApiService } from '../api/piece-type.api.service';
import { PreMachinedMethodApiService } from '../api/pre-machined-method-api.service';
import { ProcessApiService } from '../api/process-api.service';
import { ProjectSinkingReasonApiService } from '../api/project-sinking-reason-api.service';
import { SectorApiService } from '../api/sector-api.service';
import { SpecificationApiService } from '../api/specification-api.service';
import { SupplierApiService } from '../api/supplier-api.service';
import { ToleranceValueApiService } from '../api/tolerance-value-api.service';

@Injectable()
export class OfflineService {
  constructor(
    private customerApiService: CustomerApiService,
    private commercialApiService: CommercialApiService,
    private pieceTypeApiService: PieceTypeApiService,
    private processApiService: ProcessApiService,
    private sectorApiService: SectorApiService,
    private supplierApiService: SupplierApiService,
    private projectSinkingReasonApiService: ProjectSinkingReasonApiService,
    private machineApiService: MachineApiService,
    private materialApiService: MaterialApiService,
    private specificationApiService: SpecificationApiService,
    private preMachinedMethodApiService: PreMachinedMethodApiService,
    private hardnessApiService: HardnessApiService,
    private toleranceValueApiService: ToleranceValueApiService,
    private commercialActionTypeApiService: CommercialActionTypeApiService,
    private userApiService: UserApiService,
  ) { }

  async cacheNonEntitySpecificResources() {
    await this.customerApiService.getCustomers().toPromise();
    await this.commercialApiService.getCommercials().toPromise();
    await this.commercialApiService.getActiveCommercials().toPromise();
    await this.pieceTypeApiService.getPieceTypes().toPromise();
    await this.processApiService.getProcesses().toPromise();
    await this.sectorApiService.getSectors().toPromise();
    await this.supplierApiService.getSuppliers().toPromise();
    await this.projectSinkingReasonApiService.getAll().toPromise();
    await this.machineApiService.getMachines().toPromise();
    await this.materialApiService.getMaterials().toPromise();
    await this.specificationApiService.getSpecifications().toPromise();
    await this.preMachinedMethodApiService.getPreMachinedMethods().toPromise();
    await this.hardnessApiService.getHardnesses().toPromise();
    await this.toleranceValueApiService.getToleranceValues().toPromise();
    await this.commercialActionTypeApiService.getCommercialActionTypes().toPromise();
    await this.userApiService.getUsers().toPromise();
  }
}
