import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Process } from '@/core/models/process';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '@/core/services/visual/toaster.service';
import { pluck } from 'rxjs/operators';
import { AdminRoutesInterface } from '../../admin-routes.interface';
import { ProcessService } from '../processes.service';
import { NavigationService } from '@/core/services/navigation.service';
import { ROUTES } from '@/core/config/constants';

const editSuccessKey = 'admin.processes.edit.editSuccess';
const createSuccessKey = 'admin.processes.create.saveSuccess';

@Component({
  selector: 'app-edit-process',
  templateUrl: './edit-process.component.html'
})

export class EditProcessComponent implements OnInit {
  process$: Observable<Process>;
  editMode$: Observable<boolean>;
  fieldErrors: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private processService: ProcessService,
    private toasterService: ToasterService,
    private navigationService: NavigationService
  ) {
  }

  ngOnInit() {
    this.process$ = this.activeRoute.data.pipe(pluck('process'));
    this.editMode$ = this.activeRoute.data.pipe(pluck('editMode'));
  }

  async onSave(process: Process) {
    const editMode = this.activeRoute.snapshot.data['editMode'];
    if (editMode) {
      await this.updateProcess(process);
    } else {
      await this.createProcess(process);
    }
    this.navigationService.redirectTo(ROUTES.ADMIN_PROCESSES);
  }

  async updateProcess(process: Process) {
    try {
      await this.processService.updateProcess(process);
      this.toasterService.showSuccessTranslating(editSuccessKey, { name: process.name });
    } catch (errors) {
      this.fieldErrors = errors;
    }

  }

  async createProcess(process: Process) {
    try {
      await this.processService.createProcess(process);
      this.toasterService.showSuccessTranslating(createSuccessKey, { name: process.name });
    } catch (errors) {
      this.fieldErrors = errors;
    }
  }

  routes(): AdminRoutesInterface[] {
    const editMode = this.activeRoute.snapshot.data['editMode'];

    if (editMode) {
      return [
        {
          title: 'breadCrumbs.processes',
          link: 'processes',
        },
        {
          title: 'breadCrumbs.edit',
          link: '',
        }
      ];
    } else {
      return [
        {
          title: 'breadCrumbs.processes',
          link: 'processes',
        },
        {
          title: 'breadCrumbs.create',
          link: '',
        }
      ];
    }
  }
}
