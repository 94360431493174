import { mapCollection } from '../../utils/collection.utils';
import { PermissionSubsection } from '../permission-subsection';
import { PermissionPrivilegeBuilder } from './permission-privilege.builder';

export class PermissionSubsectionBuilder {
  static fromJson(json: any): PermissionSubsection {
    return (json)
      ? new PermissionSubsection(
          json.hash,
          json.name,
          json.code,
          PermissionPrivilegeBuilder.fromList(json.permissionPrivileges)
        )
      : null;
  }

  static fromList(list: any): PermissionSubsection[] {
    return Array.isArray(list)
      ? mapCollection(PermissionSubsectionBuilder.fromJson, list)
      : [];
  }
}
