import { Contact } from './contact';
import { Customer } from './customer';
import { PieceType } from './piece-type';
import { Project } from './project';
import { ProjectGroupCommercial } from './project-group-commercial';
import { Sector } from './sector';

export class ProjectGroup {
  constructor(
    public id?: number,
    public hash?: string,
    public name?: string,
    public customer?: Customer,
    public commercials?: ProjectGroupCommercial[],
    public contacts?: Contact[],
    public sector?: Sector,
    public pieceType?: PieceType,
    public planeNumber?: string,
    public projects?: Project[],
    public image?: string,
    public dropboxFolderId?: string,
    public pieceName?: string,
    public createdAt?: Date,
    public statusDate?: Date,
    public favorite?: Boolean,
    public closeStatus?: string
  ) {}

  clone() {
    return new ProjectGroup(
        this.id,
        this.hash,
        this.name,
        this.customer,
        this.commercials,
        this.contacts,
        this.sector,
        this.pieceType,
        this.planeNumber,
        this.projects,
        this.image,
        this.dropboxFolderId,
        this.pieceName,
        this.createdAt,
        this.statusDate,
        this.favorite,
        this.closeStatus
    );
  }

  get isClosed(): boolean {
    return this.closeStatus !== null;
  }
}
