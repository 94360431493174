<div [formGroup]="form">
  <input type="hidden" [formControlName]="controlName">
</div>
<input
  #fileInput
  type="file"
  [id]="id"
  [ngClass]="class"
  [accept]="acceptedExtensions"
  (change)="onFileChange()"
>
