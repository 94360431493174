import { ProcessBuilder } from '@/core/models/builder/process.builder';
import { Process } from '@/core/models/process';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const getProcessBody = (process: Process) => ({
  name: process.name,
  isInternal: process.isInternal,
  isExternal: process.isExternal,
  axialDepthCutVisibility: process.axialDepthCutVisibility,
  rollerRadiusVisibility: process.rollerRadiusVisibility
});

@Injectable()
export class ProcessApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}process`;
  }

  getProcesses() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(ProcessBuilder.fromList)
      );
  }

  getProcess(hash: string) {
    return this.httpClient.get(`${ this.apiUrl }/${ hash }`)
      .pipe(
        map(ProcessBuilder.fromJson)
      );
  }

  deleteProcess(hash: string) {
    return this.httpClient.delete(`${ this.apiUrl }/${ hash }`);
  }

  updateProcess(process: Process) {
    return this.httpClient.put(`${ this.apiUrl }/${ process.hash }`, getProcessBody(process));
  }

  createProcess(process: Process) {
    return this.httpClient.post(this.apiUrl, getProcessBody(process))
      .pipe(
        map(ProcessBuilder.fromJson)
      );
  }
}
