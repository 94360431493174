import * as R from 'ramda';

import { isNullOrUndefined } from './object.utils';
import { isEmpty as isEmptyString } from './string.utils';
import { isEmpty as isEmptyCollection } from './collection.utils';

export const parseNumber = (value: string | number) => {
  if (typeof value === 'number') {
    return value;
  }

  if (value != null) {
    value = value.trim();
  }

  /* Guard to avoid parsing a 0, if 0 gets parsed it will return an undesired null*/
  if (value === '0') {
    return 0;
  }

  return (isNullOrUndefined(value)) || isEmptyString(value) ? null : +value;
};

export const round = (value: number, decimals = 2) => (!isNullOrUndefined(value)) ? +value.toFixed(decimals) : null;

export const compare = (a: number, b: number) => a - b;

export const getModule = (value: number, max: number, reverse = false) => {
  return (reverse) ? max - (value % max) - 1 : value % max;
};

export const sum = (a: number, b: number) => a + b;

export const isDivisibleBy = R.curry((base: number, value: number) => value % base === 0);

export const isEven = (value: number) => isDivisibleBy(2, value);

export const isNumeric = (value: string) => !isNaN(parseNumber(value));

export const isFalsyNumber = (value: number) => (value === 0 || value === null || value === undefined);

export const percentage = (value: number, base: number, decimals = 2) =>
  (
    !(typeof value !== 'number'
      || typeof base !== 'number'
      || isNaN(value)
      || isNaN(base))
    && base !== 0
  ) ? round(value * 100 / base, decimals) : null;

export const maxNumber = (numbers: any[]): number | null => {
  if (isEmptyCollection(numbers)) {
    return null;
  }

  if (!numbers.every(element => typeof element === 'number')) {
    throw new TypeError('All elements must be numbers');
  }

  return R.reduce(R.max, -Infinity, numbers);
};

export const getNumberRange = (min: number, max: number): number[] => {
  if (min > max) {
    return [];
  }
  return Array.from({ length: max - min + 1 }, (_, i) => i + min);
};
