import { mapCollection } from '../../utils/collection.utils';
import { Invoice } from '../invoice';

export class InvoiceBuilder {
  static fromJson(json: any): Invoice {
    return json ? new Invoice(json.id, json.totalPrice) : null;
  }

  static fromList(list: any): Invoice[] {
    return Array.isArray(list) ? mapCollection(InvoiceBuilder.fromJson, list) : [];
  }
}
