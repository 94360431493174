import { MachiningStudyChange } from '@/core/interfaces/machining-study-change.interface';
import { Machine } from '@/core/models/machine';
import { MachiningStudy } from '@/core/models/machining-study';
import { MachineApiService } from '@/core/services/api/machine-api.service';
import { MachiningStudyApiService } from '@/core/services/api/machining-study-api.service';
import { ResolverService } from '@/core/services/base/resolver.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { RecalculatedTrials } from '../../projects-shared/components/trials-shared/trials-shared.service';

@Injectable()
export class MachiningStudyService {

  public isCompleted = new BehaviorSubject<boolean>(false);
  isComplete$ = this.isCompleted.asObservable();

  constructor(
    private machiningStudyApiService: MachiningStudyApiService,
    private resolverService: ResolverService,
    private machineApiService: MachineApiService,
  ) { }

  createMachine(machine: Machine) {
    return this.machineApiService.createMachine(machine).toPromise();
  }

  getMachiningStudy(hash: string): Observable<MachiningStudy> {
    return this.machiningStudyApiService.getMachiningStudy(hash);
  }

  createMachiningStudy(machiningStudy: MachiningStudy) {
    return this.machiningStudyApiService.createMachiningStudy(machiningStudy).toPromise();
  }

  downloadMachiningStudyPdf(hash: string, locale: string) {
    return this.machiningStudyApiService.downloadMachiningStudyPdf(hash, locale).toPromise();
  }

  updateMachiningStudy(machiningStudy: MachiningStudy) {
    return this.machiningStudyApiService.updateMachiningStudy(machiningStudy).toPromise();
  }

  updateMachiningStudyWithTrialsAndTrialLineResults(
    machiningStudy: MachiningStudy,
    recalculatedTrials: RecalculatedTrials,
  ): Observable<MachiningStudy> {
    return this.machiningStudyApiService.updateMachiningStudyAndTrialsAndTrialLineResults(
      machiningStudy,
      recalculatedTrials
    );
  }

  resolveMachiningStudy(hash: string): Observable<MachiningStudy> {
    this.resolverService.onResolverStart();
    return this.getMachiningStudy(hash)
      .pipe(
        catchError(() => of(null)),
        finalize(this.resolverService.onResolverStop)
      );
  }

  getTrialRelevantMachiningStudyChanges(
    initialMachiningStudy: MachiningStudy,
    machiningStudyToSave: MachiningStudy
  ): MachiningStudyChange[] {
    if (machiningStudyToSave.hash === null) {
      return [];
    }

    return this.getChangesFromComparingMachiningStudies(initialMachiningStudy, machiningStudyToSave);
  }

  /**
   * Returns only the relevant changes for trials
   */
  private getChangesFromComparingMachiningStudies(study1: MachiningStudy, study2: MachiningStudy): MachiningStudyChange[] {
    const changes: MachiningStudyChange[] = [];

    if (study1.cuttingDiameter !== study2.cuttingDiameter) {
      changes.push({
        property: 'cuttingDiameter',
        oldValue: study1.cuttingDiameter,
        newValue: study2.cuttingDiameter
      });
    }

    if (study1.noOfParts !== study2.noOfParts) {
      changes.push({
        property: 'noOfParts',
        oldValue: study1.noOfParts,
        newValue: study2.noOfParts
      });
    }

    if (study1.boresPerPart !== study2.boresPerPart) {
      changes.push({
        property: 'boresPerPart',
        oldValue: study1.boresPerPart,
        newValue: study2.boresPerPart
      });
    }

    if (study1.cuttingDepth !== study2.cuttingDepth) {
      changes.push({
        property: 'cuttingDepth',
        oldValue: study1.cuttingDepth,
        newValue: study2.cuttingDepth
      });
    }

    return changes;
  }
}
