import { PermissionSubsection } from './permission-subsection';

export class PermissionSection {
  constructor(
    public hash?: string,
    public name?: string,
    public code?: string,
    public permissionSubsections?: PermissionSubsection[]
  ) {}

  clone() {
    return new PermissionSection(
      this.hash,
      this.name,
      this.code,
      this.permissionSubsections
    );
  }
}
