export class OfferLine {
  constructor(
    public id: number,
    public articleId: number,
    public description: string,
    public quantity: number,
    public amount: number,
  ) { }

  clone(): OfferLine {
    return new OfferLine(
      this.id,
      this.articleId,
      this.description,
      this.quantity,
      this.amount
    );
  }
}
