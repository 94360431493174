import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { hasAccessLevelForSubsection } from '../../core/utils/jwt.utils';

@Directive({
  selector: '[appHideByPermissionAndAccessLevel]'
})
export class HideByPermissionAndAccessLevelDirective implements OnInit {
  @Input() permission: string;
  @Input() accessLevel: string;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    const hasAccessLevel = hasAccessLevelForSubsection(this.permission, this.accessLevel);

    if (hasAccessLevel) {
      this.elementRef.nativeElement.remove();
    }
  }
}
