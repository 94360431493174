import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { getMoment } from '@/core/utils/date.utils';
import { hasRequiredField } from '@/core/utils/form.utils';

@Component({
  selector: 'app-datepicker-field',
  templateUrl: './datepicker-field.component.html',
  styleUrls: ['./datepicker-field.component.css']
})
export class DatepickerFieldComponent {
  @Input() id: string;
  @Input() controlName: string;
  @Input() key: string;
  @Input() form: FormGroup;
  @Input() class = 'field';
  @Input() type: 'both' | 'calendar' | 'timer' = 'both';
  @Input() minDate: number;
  @Input() maxDate: number;
  @Input() hideIcon = false;
  @Input() hideErrors = false;
  @Input() selectMode: 'single' | 'range' | 'rangeFrom' | 'rangeTo' = 'single';
  @Input() selectableDates: Date[] = [];
  @ViewChild('error') error;
  @Output() change = new EventEmitter<void>();

  get min() {
    return (this.minDate) ? getMoment(this.minDate) : null;
  }

  get max() {
    return (this.maxDate) ? getMoment(this.maxDate) : null;
  }

  hasRequiredField(control: AbstractControl) {
    return hasRequiredField(control);
  }

  onChangeEvent(event) {
    this.change.emit(event);
  }

  shouldFilterDates(): boolean {
    return this.selectableDates && this.selectableDates.length > 0;
  }

  filterDates = (d: Date): boolean => {
    return this.selectableDates.some(selectableDate => selectableDate.getTime() === d.getTime());
  };

}
