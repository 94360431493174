import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditProcessComponent } from './edit-process/edit-process.component';
import { ProcessesTableComponent } from './process-table/processes-table.component';
import { ProcessResolver } from './process.resolver';
import { ProcessesResolver } from './processes.resolver';

const processesRoutes: Routes = [
  {
    path: '',
    component: ProcessesTableComponent,
    resolve: { processes: ProcessesResolver }
  },
  {
    path: 'new',
    component: EditProcessComponent
  },
  {
    path: ':hash',
    data: { editMode: true },
    component: EditProcessComponent,
    resolve: { process: ProcessResolver }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(processesRoutes)
  ]
})

export class ProcessesRoutingModule { }
