import { Material } from '../material';
import { mapCollection } from '../../utils/collection.utils';
import { MaterialDenomination } from '../material-denomination';

export class MaterialDenominationBuilder {
  static fromJson(json: any): MaterialDenomination {
    return (json)
      ? new MaterialDenomination(
        json.hash,
        json.name,
      )
      : null;
  }

  static fromList(list: any): MaterialDenomination[] {
    return Array.isArray(list)
      ? mapCollection(MaterialDenominationBuilder.fromJson, list)
      : [];
  }
}
