export class Sector {
  constructor(
    public id?: number,
    public name?: string
  ) { }

  clone() {
    return new Sector(
      this.id,
      this.name
    );
  }
}
