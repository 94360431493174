import { mapCollection } from '../../utils/collection.utils';
import { PieceType } from '../piece-type';

export class PieceTypeBuilder {
  static fromJson(json: any) {
    return (json)
    ? new PieceType(
        json.hash,
        json.name
      )
      : null;
  }

  static fromList(list): PieceType[] {
    return Array.isArray(list)
      ? mapCollection(PieceTypeBuilder.fromJson, list)
      : [];
  }
}
