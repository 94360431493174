import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANG } from '../config/constants';
import { setLang } from './storage.service';

@Injectable()
export class TranslationService {
  constructor(
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang(DEFAULT_LANG.value);
  }

  useLang(lang: string) {
    setLang(lang);
    this.translateService.use(lang);
  }

  async getTranslation(key: string, params?: { [key: string]: any }) {
    return await this.translateService.get(key, params).toPromise();
  }

  getTranslationSync(key: string) {
    return this.translateService.instant(key);
  }
}
