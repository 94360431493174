import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PieceTypesRoutingModule } from './piece-types-routing.module';
import { PieceTypesTableComponent } from './piece-types-table/piece-types-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared/shared.module';
import { PieceTypesService } from './piece-types.service';
import { PieceTypesResolver } from './piece-types-table/piece-types.resolver';
import { EditPieceTypeComponent } from './edit-piece-type/edit-piece-type.component';
import { PieceTypeResolver } from './edit-piece-type/piece-type.resolver';
import { EditPieceTypeFormComponent } from './edit-piece-type/edit-piece-type-form/edit-piece-type-form.component';

@NgModule({
  declarations: [PieceTypesTableComponent, EditPieceTypeComponent, EditPieceTypeFormComponent],
  imports: [
    CommonModule,
    PieceTypesRoutingModule,
    TranslateModule,
    SharedModule
  ],
  providers: [
    PieceTypesService,
    PieceTypesResolver,
    PieceTypeResolver
  ]
})
export class PieceTypesModule { }
