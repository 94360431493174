export class ProjectGroupCommercial {
    constructor(
      public hash?: string,
      public name?: string,
      public id?: number
    ) { }

    clone() {
      return new ProjectGroupCommercial(
        this.hash,
        this.name,
        this.id
      );
    }
  }
