import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { CommercialBuilder } from '../../models/builder/commercial.builder';

@Injectable()
export class CommercialApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}commercials`;
  }

  getCommercials() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(CommercialBuilder.fromList)
      );
  }

  getActiveCommercials() {
    return this.httpClient.get(`${this.apiUrl}/active`)
      .pipe(
        map(CommercialBuilder.fromList)
      );
  }
}
