import { Customer } from './customer';
import { Invoice } from './invoice';

export class DeliveryNote {
  constructor(
    public id: number,
    public number: number,
    public totalPrice: number,
    public customer: Customer,
    public reviewed: boolean,
    public invoice?: Invoice,
  ) {}

  clone(): DeliveryNote {
    return new DeliveryNote(
      this.id,
      this.number,
      this.totalPrice,
      this.customer,
      this.reviewed,
      this.invoice
    );
  }
}
