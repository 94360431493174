export class Machine {
  constructor(
    public hash?: string,
    public name?: string,
    public power?: string,
    public type?: string,
    public spindleClamp?: string,
    public restrictions?: string,
    public isHorizontal?: boolean,
    public isVertical?: boolean,
    public isRotative?: boolean,
    public noOfAxis?: number,
    public orientations?: string
  ) {}

  clone() {
    return new Machine(
      this.hash,
      this.name,
      this.power,
      this.type,
      this.spindleClamp,
      this.restrictions,
      this.isHorizontal,
      this.isVertical,
      this.isRotative,
      this.noOfAxis
    );
  }
}
