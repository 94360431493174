import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CompetitorBuilder } from '../../models/builder/competitor.builder';
import { environment } from '../../../../environments/environment';
import { Competitor } from '../../models/competitor';

const getCompetitorBody = (competitor: Competitor) => ({
  name: competitor.name,
  description: competitor.description,
});

@Injectable()
export class CompetitorApiService {
  private apiUrl: string;

  constructor(
    private  httpClient: HttpClient
  ) {

    this.apiUrl = `${environment.apiUrl}competitors`;
  }

  getCompetitors() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(CompetitorBuilder.fromList)
      );
  }
  getCompetitor(hash: string) {
    return this.httpClient.get(`${this.apiUrl}/${ hash }`)
      .pipe(
        map(CompetitorBuilder.fromJson)
      );
  }

  deleteCompetitor(hash: string) {
    return this.httpClient.delete(`${ this.apiUrl }/${ hash }`);
  }

  updateCompetitor(competitor: Competitor) {
    return this.httpClient.put(`${ this.apiUrl }/${ competitor.hash }`, getCompetitorBody(competitor));
  }

  createCompetitor(competitor: Competitor) {
    return this.httpClient.post(this.apiUrl, getCompetitorBody(competitor))
      .pipe(
        map(CompetitorBuilder.fromJson)
      );
  }

}
