import { mapCollection } from '../../utils/collection.utils';
import { WorkGroupBuilder } from './work-group.builder';
import { InternalAction } from '../internal-action';

export class InternalActionBuilder {
  static fromJson(json: any): InternalAction {
    return (json)
      ? new InternalAction(
          json.hash,
          json.subject,
          json.date,
          json.time,
          json.reminderDate,
          json.notifyWorkGroupUsers,
          json.comments,
          json.cost,
          WorkGroupBuilder.fromJson(json.workGroup),
          json.actionType,
          json.filesCount,
        )
      : null;
  }

  static fromList(list: any): InternalAction[] {
    return Array.isArray(list)
      ? mapCollection(InternalActionBuilder.fromJson, list)
      : [];
  }
}
