import { ACCESS_LEVEL_HIDDEN } from '../config/access-level-constants';
import { ROLE_ADMIN, ROLE_EXTERNAL_USER } from '../config/privileges-constants';
import { UserPermission } from '../models/user-permision';
import { getSession } from '../services/storage.service';

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const decoded = decodeURIComponent(escape(window.atob(base64)));

  return JSON.parse(decoded);
};

const getParsedToken = () => {
  const session = getSession();

  return (session) ? parseJwt(session.token) : null;
};

export const getFirstName = () => {
  const decoded = getParsedToken() || {};

  return decoded.firstName || null;
};

export const getLastName = () => {
  const decoded = getParsedToken() || {};

  return decoded.lastName || null;
};

export const getFullName = () => {
  const firstName = getFirstName() || '';
  const lastName = getLastName() || '';

  return `${firstName} ${lastName}`;
};

export const getUserHash = () => {
  const decoded = getParsedToken() || {};

  return decoded.sub || null;
};

export const getUserPermissions = (): UserPermission[] => {
  const decoded = getParsedToken() || {};

  let userPermissions = [];
  if (decoded && decoded.userPermissions) {
    userPermissions = decoded.userPermissions;
  }

  return userPermissions;
};

export const hasAccessLevelForSubsection = (subsectionCode: string, accessLevelCode: string): boolean => {
  return getUserPermissions().some((userPermission) =>
    userPermission.subsectionCode === subsectionCode
    && userPermission.accessLevelCode === accessLevelCode
  );
};

export const hasReadAccessForSubsection = (subsectionCode: string): boolean => {
  const userPermission = getUserPermissions().find(value => value.subsectionCode === subsectionCode);

  return userPermission && userPermission.accessLevelCode !== ACCESS_LEVEL_HIDDEN;
};

export const hasReadAccessForAnySubsection = (subsectionCodes: string[]): boolean => {
  for (let i = 0; i < subsectionCodes.length; i++) {
    if (hasReadAccessForSubsection(subsectionCodes[i])) {
      return true;
    }
  }

  return false;
};

const getPrivileges = (): string[] => {
  const decoded = getParsedToken() || {};

  let privileges = [];
  if (decoded && decoded.privileges) {
    privileges = decoded.privileges.split(',');
  }

  return privileges;
};

const hasPrivilege = (privilege: string) => {
  const privileges = getPrivileges();

  return privileges.includes(privilege);
};

export const hasAdminRole = () => hasPrivilege(ROLE_ADMIN);

export const hasExternalUserRole = () => hasPrivilege(ROLE_EXTERNAL_USER);
