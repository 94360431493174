import { mapCollection } from '../../utils/collection.utils';
import { Competitor } from '../competitor';
import { Trial } from '../trial';
import { RoiComparator } from '../trial/roi-comparator';
import { TrialParameters } from '../trial/trial-parameters';
import { MachineBuilder } from './machine.builder';
import { MaterialDenominationBuilder } from './material-denomination.builder';
import { MaterialIsoBuilder } from './material-iso.builder';
import { MaterialBuilder } from './material.builder';
import { TrialLineBuilder } from './trial-line.buider';
import { TrialObjectiveBuilder } from './trial-objective.builder';

export class TrialBuilder {
  static fromJson(json: any): Trial {
    return (json)
      ? new Trial(
        json.hash,
        json.created,
        json.document,
        TrialObjectiveBuilder.fromJson(json.trialObjective),
        json.plate,
        json.jump,
        json.reamer,
        MaterialBuilder.fromJson(json.material),
        MachineBuilder.fromJson(json.machine),
        MaterialDenominationBuilder.fromJson(json.materialDenomination),
        MaterialIsoBuilder.fromJson(json.materialIso),
        json.materialIsoOld,
        new TrialParameters(
          json.toolDiameter,
          json.cuttingDepth,
          json.noOfParts,
          json.boresPerPart,
          json.totalBores,
          json.competitor ? new Competitor(json.competitor.hash, json.competitor.name) : null,
          json.urmaProduct,
        ),
        // Urma
        new RoiComparator(
          json.compensation,
          json.insert,
          json.insertErpOfferLineIds,
          json.noOfTeeth,
          json.cuttingSpeedOriginal,
          json.cuttingSpeedPercentage,
          json.cuttingSpeed,
          json.revolutions,
          json.feedPerTooth,
          json.tableFeed,
          json.machiningTimePerBore,
          json.toolLifePerInsert,
          json.machineCostPerHour,
          json.noOfInsertUsesPerSerie,
          json.noOfNewToolsPerSerie,
          json.totalToolCostPerSerie,
          json.insertChangeTime,
          json.adjustmentTime,
          json.runOutInterval,
          json.noOfPossibleReconditionings,
          json.totalCostPerInsertChange,
          json.reconditioningCost,
          json.logisticsCostPerReconditioning,
          json.totalCostForReconditioning,
          json.toolLifeFactorReconditioning,
          json.totalToolAndAdditionalCostForSerie,
          json.machiningTotalTime,
          json.machiningCost,
          json.costPerBore,
          json.totalToolCost,
          json.compensationErpOfferLineIds,
          json.machiningTimePerPart
        ),
        // Competitor
        new RoiComparator(
          json.c_compensation,
          json.c_insert,
          null,
          json.c_noOfTeeth,
          0,
          0,
          json.c_cuttingSpeed,
          json.c_revolutions,
          json.c_feedPerTooth,
          json.c_tableFeed,
          json.c_machiningTimePerBore,
          json.c_toolLifePerInsert,
          json.c_machineCostPerHour,
          json.c_noOfInsertUsesPerSerie,
          json.c_noOfNewToolsPerSerie,
          json.c_totalToolCostPerSerie,
          json.c_insertChangeTime,
          json.c_adjustmentTime,
          json.c_runOutInterval,
          json.c_noOfPossibleReconditionings,
          json.c_totalCostPerInsertChange,
          json.c_reconditioningCost,
          json.c_logisticsCostPerReconditioning,
          json.c_totalCostForReconditioning,
          json.c_toolLifeFactorReconditioning,
          json.c_totalToolAndAdditionalCostForSerie,
          json.c_machiningTotalTime,
          json.c_machiningCost,
          json.c_costPerBore,
          json.c_totalToolCost,
          json.c_machiningTimePerPart
        ),
        json.totalTooCostDiff,
        json.totalToolCostDiffPercent,
        json.savingOfMachiningTime,
        json.savingOfMachiningTimePercent,
        json.savingPerMachiningTime,
        json.savingPerBore,
        json.savingPerBorePercent,
        json.savingPerSerieOrYear,
        TrialLineBuilder.fromList(json.trialLines),
        TrialLineBuilder.fromJson(json.currentTrialLine),
      )
      : null;
  }

  static fromList(list): Trial[] {
    return Array.isArray(list)
      ? mapCollection(TrialBuilder.fromJson, list)
      : [];
  }
}
