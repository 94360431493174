import { mapCollection } from '../../utils/collection.utils';
import { PermissionPrivilege } from '../permission-privilege';
import { AccessLevelBuilder } from './access-level.builder';
import { PrivilegeBuilder } from './privilege.builder';

export class PermissionPrivilegeBuilder {
  static fromJson(json: any): PermissionPrivilege {
    return (json)
      ? new PermissionPrivilege(
          json.hash,
          PrivilegeBuilder.fromJson(json.privilege),
          AccessLevelBuilder.fromJson(json.accessLevel)
        )
      : null;
  }

  static fromList(list: any): PermissionPrivilege[] {
    return Array.isArray(list)
      ? mapCollection(PermissionPrivilegeBuilder.fromJson, list)
      : [];
  }
}
