import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { ACCESS_LEVEL_READ } from '../../../../core/config/access-level-constants';
import { PERMISSION_PROCESSES } from '../../../../core/config/permission-constants';
import { Process } from '../../../../core/models/process';
import { ToasterService } from '../../../../core/services/visual/toaster.service';
import { sortByNumberOrString } from '../../../../core/utils/collection.utils';
import { ConfirmModalComponent } from '../../../../shared/components/modals/confirm-modal/confirm-modal.component';
import { AdminRoutesInterface } from '../../admin-routes.interface';
import { ProcessService } from '../processes.service';

const deleteSuccessKey = 'admin.processes.delete.deleteSuccess';

@Component({
  selector: 'app-processes-table',
  templateUrl: './processes-table.component.html'
})
export class ProcessesTableComponent implements OnInit {
  processes$: Observable<Process[]>;
  sortField = 'date';
  sortReversed = false;
  private processToDelete: Process;
  accessLevel = ACCESS_LEVEL_READ;
  permission = PERMISSION_PROCESSES;

  @ViewChild(ConfirmModalComponent) deleteProcessModal: ConfirmModalComponent;

  constructor(
    private activeRoute: ActivatedRoute,
    private processService: ProcessService,
    private toasterService: ToasterService,
    private router: Router
  ) { }

  ngOnInit() {
    this.processes$ = this.activeRoute.data.pipe(pluck('processes'));
  }

  onEditProcess(process: Process) {
    if (process && process.hash) {
      this.router.navigate([process.hash], { relativeTo: this.activeRoute });
    }
  }

  async onDeleteProcessConfirmed() {
    try {
      await this.processService.deleteProcess(this.processToDelete.hash);
      this.toasterService.showSuccessTranslating(deleteSuccessKey, { name: this.processToDelete.name });
      this.processes$ = this.getProcesses();
    } catch (errors) {
      return;
    }
  }

  private getProcesses(): Observable<Process[]> {
    return this.processService.resolveProcesses();
  }

  onDelete(process: Process) {
    if (process) {
      this.processToDelete = process;
      this.deleteProcessModal.open();
    }
  }

  routes(): AdminRoutesInterface[] {
    return [
      {
        title: 'breadCrumbs.processes',
        link: 'processes',
      }
    ];
  }

  resetSortDirection() {
    this.sortReversed = false;
  }

  toggleSortDirection() {
    this.sortReversed = !this.sortReversed;
  }

  sortTable(fieldName: string) {
    if (fieldName === this.sortField) {
      this.toggleSortDirection();
    } else {
      this.resetSortDirection();
    }
    this.sortField = fieldName;

    this.processes$ = this.processes$
      .pipe(
        map(items => {
          return sortByNumberOrString(fieldName, this.sortReversed, items);
        })
      );
  }
}
