export class Application {
  constructor(
    public hash?: string,
    public name?: string,
  ) { }

  clone() {
    return new Application(
      this.hash,
      this.name,
    );
  }
}
