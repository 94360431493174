import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectGroupBuilder } from 'src/app/core/models/builder/project-group.builder';
import { ProjectGroup } from 'src/app/core/models/project-group';
import { environment } from 'src/environments/environment';

const createProjectGroupBody = (projectGroup: ProjectGroup) => ({
  customerId: projectGroup.customer,
  commercials: projectGroup.commercials.map(commercialId => { return { commercialId }; }),
  contacts: projectGroup.contacts,
  pieceName: projectGroup.pieceName,
  pieceTypeHash: projectGroup.pieceType,
  planeNumber: projectGroup.planeNumber,
  sectorId: projectGroup.sector,
});

const updateProjectGroupBody = (projectGroup: ProjectGroup) => ({
  customerId: projectGroup.customer,
  commercials: projectGroup.commercials.map(commercialId => { return { commercialId }; }),
  contacts: projectGroup.contacts,
  pieceName: projectGroup.pieceName,
  pieceTypeHash: projectGroup.pieceType,
  planeNumber: projectGroup.planeNumber,
  sectorId: projectGroup.sector,
});

@Injectable()
export class ProjectGroupApiService {
  private apiUrl: string;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = `${environment.apiUrl}project-groups`;
  }

  getProjectGroup(hash: string): Observable<ProjectGroup> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(ProjectGroupBuilder.fromJson)
      );
  }

  getProjectGroupByProject(projectHash: string): Observable<ProjectGroup> {
    return this.httpClient.get(`${this.apiUrl}/by-project/${projectHash}`)
      .pipe(
        map(ProjectGroupBuilder.fromJson)
      );
  }

  getFilteredProjectGroups(
    fromDate: string,
    toDate: string,
    customerId: number,
    closed: boolean,
    commercialId?: number,
    favorite?: boolean,
  ): Observable<ProjectGroup[]> {
    let params = new HttpParams();
    if (fromDate) { params = params.set('fromDate', fromDate); }
    if (toDate) { params = params.set('toDate', toDate); }
    if (customerId) { params = params.set('customer', customerId.toString()); }
    if (closed) { params = params.set('closed', closed); }
    if (commercialId) { params = params.set('commercial', commercialId.toString()); }
    if (favorite) { params = params.set('favorite', favorite); }

    return this.httpClient.get(this.apiUrl, { params })
      .pipe(
        map(ProjectGroupBuilder.fromList)
      );
  }

  createProjectGroup(projectGroup: ProjectGroup, image: File): Observable<ProjectGroup> {
    const postBody = new FormData();
    postBody.append('projectGroup', JSON.stringify(createProjectGroupBody(projectGroup)));
    postBody.append('image', (image) ? image : null);

    return this.httpClient.post(this.apiUrl, postBody)
      .pipe(
        map(ProjectGroupBuilder.fromJson)
      );
  }

  updateProjectGroup(projectGroup: ProjectGroup, image: File) {
    const postBody = new FormData();
    postBody.append('projectGroup', JSON.stringify(updateProjectGroupBody(projectGroup)));
    postBody.append('image', (image) ? image : null);

    return this.httpClient.post(`${this.apiUrl}/${projectGroup.hash}`, postBody)
      .pipe(
        map(ProjectGroupBuilder.fromJson)
      );
  }

  cloneProjectGroup(projectGroup: ProjectGroup, image: File) {
    const postBody = new FormData();
    postBody.append('projectGroup', JSON.stringify(updateProjectGroupBody(projectGroup)));
    postBody.append('image', (image) ? image : null);

    return this.httpClient.post(`${this.apiUrl}/${projectGroup.hash}/clone`, postBody)
      .pipe(
        map(ProjectGroupBuilder.fromJson)
      );
  }

  updateProjectGroupDropboxFolder(projectGroupHash: string, dropboxFolderId: string): Observable<Object> {
    return this.httpClient.put(`${this.apiUrl}/${projectGroupHash}/dropbox`, { dropboxFolderId });
  }

  updateProjectGroupFavorite(projectGroupHash: string, favorite: boolean): Observable<Object> {
    return this.httpClient.put(`${this.apiUrl}/${projectGroupHash}/favorite`, { favorite });
  }

  updateProjectGroupStatus(projectGroupHash: string, closeStatus: string) {
    return this.httpClient.put(`${this.apiUrl}/${projectGroupHash}/status`, { closeStatus });
  }
}
