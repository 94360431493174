import { DeliveryNoteGroupBuilder } from '@/core/models/builder/delivery-note-group-builder';
import { ProjectDeliveryNoteBuilder } from '@/core/models/builder/project-delivery.builder';
import { DeliveryNoteGroup } from '@/core/models/delivery-note-grouped';
import { ProjectDeliveryNote } from '@/core/models/project-delivery-note';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class DeliveryNotesApiService {
  private apiUrl: string;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = `${environment.apiUrl}delivery-notes`;
  }

  getDeliveryNoteGroups(from: string, to: string, commercial: number): Observable<DeliveryNoteGroup[]> {
    let params = new HttpParams();

    params = params.append('from', from);
    params = params.append('to', to);
    if (commercial !== null && commercial.toString() !== '0') {
      params = params.append('commercial_id', commercial);
    }

    return this.httpClient.get(`${this.apiUrl}/grouped`, { params }).pipe(map(DeliveryNoteGroupBuilder.fromList));
  }

  getDeliveryNotesExcelByDateRange(from: string, to: string, commercial: number) {
    let params = new HttpParams();

    params = params.append('from', from);
    params = params.append('to', to);
    if (commercial !== null && commercial.toString() !== '0') {
      params = params.append('commercial_id', commercial);
    }
    return this.httpClient.get(`${this.apiUrl}/excel`, { params, responseType: 'arraybuffer' });
  }

  getDeliveryNotesByNumbers(deliveryNotesNumbers: number[]): Observable<ProjectDeliveryNote[]> {
    const deliveryNoteNumbers = deliveryNotesNumbers.filter(number => number !== undefined && number !== null);
    const params: HttpParams = new HttpParams()
      .set('deliveryNoteNumbers', deliveryNoteNumbers.join(','));

    return this.httpClient.get(`${this.apiUrl}/by-numbers`, { params })
      .pipe(
        map(ProjectDeliveryNoteBuilder.fromList)
      );
  }

  getBlockedDeliveryNotesAmount(from: string, to: string): Observable<number> {
    let params = new HttpParams();

    params = params.append('from', from);
    params = params.append('to', to);

    return this.httpClient.get<number>(`${this.apiUrl}/blocked-amount`, { params });
  }
}
