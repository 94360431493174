export class ProjectCommercial {
  constructor(
    public hash?: string,
    public name?: string,
    public id?: number
  ) { }

  clone() {
    return new ProjectCommercial(
      this.hash,
      this.name,
      this.id
    );
  }
}
