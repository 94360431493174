<form [formGroup]="form" class="form">
  <div class="container">
    <div class="title title--grey" translate>admin.pieceTypes.edit.formTitle</div>
    <div class="fields">
      <div class="field">
        <app-input-text-field
          [id]="'name'"
          [form]="form"
          [controlName]="'name'"
          [key]="'admin.pieceTypes.edit.name'"
        ></app-input-text-field>
      </div>
      </div>
    </div>

  <!-- ==================== ACTION BAR ====================== -->
  <div class="action-bar">
    <div class="container">
      <div class="action-bar__actions">
        <div class="action-bar__actions__right">
          <button type="button" appBackButton class="button button-grey" translate>admin.pieceTypes.edit.cancel</button>
          <button type="button" (click)="onSubmit()" [disabled]="form.invalid" class="button button-primary" translate>admin.pieceTypes.edit.save</button>
        </div>
      </div>
    </div>
  </div>
</form>
