import { mapCollection } from '../../utils/collection.utils';
import { WorkGroup } from '../work-group';
import { UserBuilder } from './user.builder';

export class WorkGroupBuilder {
  static fromJson(json: any) {
    return (json)
      ? new WorkGroup(
          json.hash,
          json.name,
          UserBuilder.fromList(json.users),
        )
      : null;
  }

  static fromList(list): WorkGroup[] {
    return Array.isArray(list)
      ? mapCollection(WorkGroupBuilder.fromJson, list)
      : [];
  }
}
