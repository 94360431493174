<section id="page-admin-list" class="main-page main-page--header-medium main-page--action-bar-active">

  <div class="container">
    <div class="table">
      <table  *ngIf="(processes$ | async)?.length > 0">
        <thead>
        <tr>
          <th class="cursor-pointer" (click)="sortTable('name')" translate>admin.processes.table.fields.name</th>
          <th class="cursor-pointer" translate>admin.processes.table.fields.internal</th>
          <th class="cursor-pointer" translate>admin.processes.table.fields.external</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let process of processes$ | async">
          <td>{{process.name}}</td>
          <td><span class="font-size-large">{{process.isInternal === true ? '&#10004;' : ''}}</span></td>
          <td><span class="font-size-large">{{process.isExternal === true ? '&#10004;' : ''}}</span></td>
          <td class="actions">
            <a appHideByPermissionAndAccessLevel [accessLevel]="accessLevel" [permission]="permission" (click)="onEditProcess(process)" class="icon icon--edit"></a>
            <a appHideByPermissionAndAccessLevel [accessLevel]="accessLevel" [permission]="permission" (click)="onDelete(process)" class="icon icon--trash"></a>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="table-empty" *ngIf="(processes$ | async)?.length === 0">
        <div class="info text-center"><p translate>shared.table.empty</p></div>
      </div>
    </div>

  </div>

  <!-- ==================== ACTION BAR ====================== -->
  <div class="action-bar" appHideByPermissionAndAccessLevel [accessLevel]="accessLevel" [permission]="permission">
    <div class="container">
      <div class="action-bar__actions">
        <div class="action-bar__actions__left">
        </div>
        <div class="action-bar__actions__right">
          <a class="button button-primary" routerLink="new" translate>admin.processes.edit.create</a>
        </div>
      </div>
    </div>
  </div>
  <!-- ==================== /ACTION BAR ====================== -->
</section>

<app-confirm-modal
  [title]="'admin.processes.delete.modalTitle'"
  [message]="'admin.processes.delete.modalMessage'"
  (confirm)="onDeleteProcessConfirmed()"
></app-confirm-modal>
