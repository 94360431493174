import { mapCollection } from '../../utils/collection.utils';
import { PermissionSection } from '../permission-section';
import { PermissionSubsectionBuilder } from './permission-subsection.builder';

export class PermissionSectionBuilder {
  static fromJson(json: any): PermissionSection {
    return (json)
      ? new PermissionSection(
          json.hash,
          json.name,
          json.code,
          PermissionSubsectionBuilder.fromList(json.subsections)
        )
      : null;
  }

  static fromList(list: any): PermissionSection[] {
    return Array.isArray(list)
      ? mapCollection(PermissionSectionBuilder.fromJson, list)
      : [];
  }
}
