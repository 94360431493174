import { Privilege } from './privilege';

export class User {
  constructor(
    public hash?: string,
    public firstName?: string,
    public lastName?: string,
    public nick?: string,
    public email?: string,
    public privilege?: Privilege
  ) {}

  clone() {
    return new User(
      this.hash,
      this.firstName,
      this.lastName,
      this.nick,
      this.email,
      this.privilege
    );
  }
}
