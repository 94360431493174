import {
  ACTION_ICONS,
  NOTIFICATIONS_PROJECT_ICON,
  NOTIFICATIONS_REMINDER_ICON,
  NOTIFICATIONS_WORK_GROUP_ICON,
  NOTIFICATION_TYPE_COMMERCIAL_ACTION,
  NOTIFICATION_TYPE_COMMERCIAL_ACTION_REMINDER,
  NOTIFICATION_TYPE_INTERNAL_ACTION,
  NOTIFICATION_TYPE_INTERNAL_ACTION_REMINDER,
  NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_RESPONSE,
  NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_TEMPLATE,
  NOTIFICATION_TYPE_PROJECT_WITHOUT_INVOICE,
  NOTIFICATION_TYPE_PROJECT_WITHOUT_OFFER,
  NOTIFICATION_TYPE_PROJECT_WITHOUT_SUCCESSFUL_TRIAL,
  NOTIFICATION_TYPE_WORK_GROUP,
  ROUTES,
  SECTION_ANALYSIS,
  SECTION_STARTUP,
} from '@/core/config/constants';
import { CommercialAction } from './commercial-action';
import { InternalAction } from './internal-action';
import { Project } from './project';
import { WorkGroup } from './work-group';

const DEFAULT_NOTIFICATION_ICON = 'icon--phone';

export class Notification {
  constructor(
    public hash?: string,
    public type?: string,
    public referenceHash?: string,
    public reference?: CommercialAction | InternalAction | WorkGroup | Project,
    public text?: string,
    public date?: Date,
    public isRead?: boolean,
    public isDone?: boolean,
    public createdAt?: Date,
  ) { }

  clone() {
    return new Notification(
      this.hash,
      this.type,
      this.referenceHash,
      this.reference,
      this.text,
      this.date,
      this.isRead,
      this.isDone,
      this.createdAt,
    );
  }

  get icon(): string {
    if (this.isWorkGroupNotification) {
      return NOTIFICATIONS_WORK_GROUP_ICON;
    }

    if (this.isReminderNotificationType) {
      return NOTIFICATIONS_REMINDER_ICON;
    }

    if (this.isProjectNotification) {
      return NOTIFICATIONS_PROJECT_ICON;
    }

    const currentActionType = (this.reference && 'commercialActionType' in this.reference)
      ? this.reference.commercialActionType.name
      : null;

    return ACTION_ICONS.find(actionType => actionType.action === currentActionType?.toLowerCase())?.icon ?? DEFAULT_NOTIFICATION_ICON;
  }

  get customerOrGroup(): string {
    if (this.reference instanceof CommercialAction) {
      return this.reference?.customer?.name;
    }
    if (this.reference instanceof InternalAction) {
      return this.reference?.workGroup?.name;
    }
    if (this.reference instanceof WorkGroup) {
      return this.reference?.name;
    }
    if (this.reference instanceof Project) {
      return this.reference?.customer?.name;
    }

    return null;
  }

  get subject(): string {
    return (this.reference instanceof InternalAction && !this.isInternalActionReminderNotification)
      || (this.reference instanceof CommercialAction && !this.isCommercialActionReminderNotification)
      ? this.reference?.subject ?? this.text
      : this.text;
  }

  get referenceRoute(): string[] {
    if (this.reference instanceof CommercialAction) {
      return [ROUTES.COMMERCIAL_ACTIONS, this.referenceHash];
    } else if (this.reference instanceof InternalAction) {
      return [ROUTES.INTERNAL_ACTIONS, this.referenceHash];
    } else if (this.reference instanceof WorkGroup) {
      return [ROUTES.INTERNAL_ACTIONS, this.referenceHash];
    } else if (this.reference instanceof Project) {
      if (this.type === NOTIFICATION_TYPE_PROJECT_WITHOUT_OFFER) {
        return [ROUTES.PROJECT_ANALYSIS_OFFER(this.referenceHash)];
      } else if (this.type === NOTIFICATION_TYPE_PROJECT_WITHOUT_INVOICE) {
        return [ROUTES.PROJECT_INVOICES(this.referenceHash, this.reference.status)];
      } else if (this.type === NOTIFICATION_TYPE_PROJECT_WITHOUT_SUCCESSFUL_TRIAL) {
        const targetSection = [SECTION_ANALYSIS, SECTION_STARTUP].includes(this.reference.status)
          ? this.reference.status
          : SECTION_ANALYSIS;

        return [ROUTES.PROJECT_WITHOUT_SUCCESSFUL_TRIAL(this.referenceHash, targetSection)];
      }
      if (this.type === NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_TEMPLATE
        || this.type === NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_RESPONSE
      ) {
        return [ROUTES.PROJECT_PENDING_SUPPLIER_ACTIONS(this.referenceHash, this.reference.status)];
      }
    }

    return [];
  }

  get isWorkGroupNotification(): boolean {
    return this.type === NOTIFICATION_TYPE_WORK_GROUP;
  }

  get isInternalActionNotification(): boolean {
    return this.type === NOTIFICATION_TYPE_INTERNAL_ACTION;
  }

  get isCommercialActionNotification(): boolean {
    return this.type === NOTIFICATION_TYPE_COMMERCIAL_ACTION;
  }

  get isCommercialActionReminderNotification(): boolean {
    return this.type === NOTIFICATION_TYPE_COMMERCIAL_ACTION_REMINDER;
  }

  get isInternalActionReminderNotification(): boolean {
    return this.type === NOTIFICATION_TYPE_INTERNAL_ACTION_REMINDER;
  }

  get isReminderNotificationType(): boolean {
    return this.isCommercialActionReminderNotification || this.isInternalActionReminderNotification;
  }

  get isProjectNotification(): boolean {
    return (
      this.type === NOTIFICATION_TYPE_PROJECT_WITHOUT_OFFER ||
      this.type === NOTIFICATION_TYPE_PROJECT_WITHOUT_SUCCESSFUL_TRIAL ||
      this.type === NOTIFICATION_TYPE_PROJECT_WITHOUT_INVOICE ||
      this.type === NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_TEMPLATE ||
      this.type === NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_RESPONSE
    );
  }
}
