import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TrialObjective } from '../../models/trial-objective';
import { TrialObjectiveBuilder } from '../../models/builder/trial-objective.builder';

const getTrialObjectiveBody = (trialObjective: TrialObjective) => ({
  name: trialObjective.name,
  defaultValue: trialObjective.defaultValue,
});

@Injectable()
export class TrialObjectiveApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}trial-objectives`;
  }

  getTrialObjective(hash: string): Observable<TrialObjective> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(TrialObjectiveBuilder.fromJson)
      );
  }

  getTrialObjectives(): Observable<TrialObjective[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(TrialObjectiveBuilder.fromList)
      );
  }

  deleteTrialObjective(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  updateTrialObjective(trialObjective: TrialObjective): Observable<TrialObjective> {
    return this.httpClient.put(`${this.apiUrl}/${trialObjective.hash}`, getTrialObjectiveBody(trialObjective))
      .pipe(
        map(TrialObjectiveBuilder.fromJson)
      );
  }

  createTrialObjective(trialObjective: TrialObjective): Observable<TrialObjective> {
    return this.httpClient.post(this.apiUrl, getTrialObjectiveBody(trialObjective))
      .pipe(
        map(TrialObjectiveBuilder.fromJson)
      );
  }
}
