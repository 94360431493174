import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SupplierBuilder } from '../../models/builder/supplier.builder';
import { Supplier } from '../../models/supplier';

@Injectable()
export class SupplierApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}suppliers`;
  }

  getSuppliers(): Observable<Supplier[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(SupplierBuilder.fromList)
      );
  }

  getSupplier(hash: string): Observable<Supplier> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(SupplierBuilder.fromJson)
      );
  }

  getSupplierTemplateFile(): Observable<ArrayBuffer> {
    return this.httpClient.get(`${this.apiUrl}/template`, { responseType: 'arraybuffer' });
  }
}
