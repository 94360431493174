import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { MachineBuilder } from '../../models/builder/machine.builder';
import { Machine } from '../../models/machine';

const getMachineBody = (machine: Machine) => ({
  name: machine.name,
  power: machine.power,
  type: machine.type,
  spindleClamp: machine.spindleClamp,
  restrictions: machine.restrictions,
  isHorizontal: machine.isHorizontal,
  isVertical: machine.isVertical,
  isRotative: machine.isRotative,
  noOfAxis: machine.noOfAxis
});

@Injectable()
export class MachineApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}machines`;
  }

  getMachine(hash: string) {
    return this.httpClient.get(`${ this.apiUrl }/${ hash }`)
      .pipe(
        map(MachineBuilder.fromJson)
      );
  }

  getMachines() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(MachineBuilder.fromList)
      );
  }

  deleteMachine(hash: string) {
    return this.httpClient.delete(`${ this.apiUrl }/${ hash }`);
  }

  updateMachine(machine: Machine) {
    return this.httpClient.put(`${ this.apiUrl }/${ machine.hash }`, getMachineBody(machine));
  }

  createMachine(machine: Machine) {
    return this.httpClient.post(this.apiUrl, getMachineBody(machine))
      .pipe(
        map(MachineBuilder.fromJson)
      );
  }
}
