import { mapCollection } from '../../utils/collection.utils';
import { AccessLevel } from '../access-level';

export class AccessLevelBuilder {
  static fromJson(json: any): AccessLevel {
    return (json)
      ? new AccessLevel(
          json.hash,
          json.name,
          json.code
        )
      : null;
  }

  static fromList(list: any): AccessLevel[] {
    return Array.isArray(list)
      ? mapCollection(AccessLevelBuilder.fromJson, list)
      : [];
  }
}
