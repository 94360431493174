import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PieceType } from '../../../../../core/models/piece-type';
import { ValidationService } from '../../../../../core/services/validation/validation.service';

const formMapping = Object.freeze({
  name: 'name',
});

@Component({
  selector: 'app-edit-piece-type-form',
  templateUrl: './edit-piece-type-form.component.html'
})
export class EditPieceTypeFormComponent implements OnInit, OnChanges {
  @Input() pieceType: PieceType;
  @Input() errors: any;
  @Input() edit: boolean;
  @Output() save = new EventEmitter<PieceType>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]]
    });

    this.updateform();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('errors')) {
      this.validationService.showFieldErrors(this.errors, this.form, formMapping);
    }
  }

  private updateform() {
    const value = {
      name: (this.pieceType) ? this.pieceType.name : '',
    };

    this.form.reset(value);
  }

  onSubmit() {
    const pieceType = (this.pieceType) ? this.pieceType.clone() : new PieceType();
    const value = this.form.value;
    pieceType.name = value.name;
    this.save.emit(pieceType);
  }
}
