import { TrialLine } from '@/core/models/trial-line';
import { TrialLineResultInterface } from '@/core/models/trial-line-result.interface';

export const getTrialLineBody = (trialLine: TrialLine) => ({
  trialHash: trialLine.trialHash,
  axialDepthCut: trialLine.axialDepthCut,
  previousProcess: trialLine.previousProcess,
  pressure: trialLine.pressure,
  cuttingSpeed: trialLine.cuttingSpeed,
  revolutions: trialLine.revolutions,
  feedPerTooth: trialLine.feedPerTooth,
  feedPerRevolution: trialLine.feedPerRevolution,
  tableFeed: trialLine.tableFeed,
  output: trialLine.output,
  machiningTime: trialLine.machiningTime,
  notes: trialLine.notes,
  noOfParts: trialLine.noOfParts,
  costPerBore: trialLine.costPerBore,
  rollerRadius: trialLine.rollerRadius,
});

export const getTrialLineResultBody = (trialLineResult: TrialLineResultInterface) => ({
  resultTotalBores: trialLineResult.resultTotalBores,
  resultRoughness: trialLineResult.resultRoughness,
  resultDiameter: trialLineResult.resultDiameter,
  resultIsOk: trialLineResult.resultIsOk
});
