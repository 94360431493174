import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { OptionInterface } from '../../../../core/models/option.interface';
import { hasRequiredField } from '../../../../core/utils/form.utils';

@Component({
  selector: 'app-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  styleUrls: ['./autocomplete-field.component.css']
})
export class AutocompleteFieldComponent implements OnInit {
  @Input() id: string;
  @Input() controlName: string;
  @Input() key: string;
  @Input() form: FormGroup;
  @Input() options: OptionInterface[];
  @Input() class = 'field';
  @Input() allowClear = false;
  @Input() notFoundText = 'shared.autocomplete.notFound';
  @Input() placeholder: string;
  @Input() hideLabel = false;
  @Input() hideErrors = false;
  @Input() searchable = true;
  @Input() multiple = false;
  @Input() addTag: boolean | ((term: string) => any | Promise<any>) = false;
  @Input() addTagText = 'shared.autocomplete.addTag';
  @Input() readonly = false;
  @Output() search = new EventEmitter<string>();
  @Output() change = new EventEmitter<void>();
  @Output() clear = new EventEmitter<void>();
  @ViewChild('error') error;

  constructor(
    private el: ElementRef,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.el.nativeElement.querySelector('input[autocomplete]').setAttribute('autocomplete', 'chrome-off');
    });
  }

  hasRequiredField(control: AbstractControl) {
    return hasRequiredField(control);
  }
}
