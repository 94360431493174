import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OfferBuilder } from 'src/app/core/models/builder/offer.builder';
import { Offer } from 'src/app/core/models/offer';
import { environment } from 'src/environments/environment';

@Injectable()
export class OfferApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}offers`;
  }

  getOfferByNumberAndRevisionNumber(offerNumber: number, offerRevisionNumber: number): Observable<Offer> {
    return this.httpClient.get(`${this.apiUrl}/${offerNumber}/revision/${offerRevisionNumber}`)
      .pipe(
        map(OfferBuilder.fromJson)
      );
  }
}
