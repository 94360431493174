<div
  class="checkbox-group"
  [formGroup]="form"
  *ngIf="form.get(controlName) as control"
  [class.error]="control.touched && control.errors"
  [class.field--required]="hasRequiredField(control)">
  <label [for]="id" *ngIf="!hideLabel">
    {{ key | translate }}
    <span class="required" *ngIf="hasRequiredField(control)"></span>
  </label>
  <ng-container *ngFor="let option of options">
    <div class="checkbox-group__item">
      <input type="radio" [formControlName]="controlName" [id]="option.id" [value]="option.value">
      <label [for]="option.id">{{ option.label }}</label>
    </div>
  </ng-container>
  <div class="box-error" *ngIf="!hideErrors && control.touched">
    <p class="error" *ngIf="control.hasError('required')" translate>error.required</p>
    <p class="error" *ngIf="control.hasError('custom')">{{ control.getError('custom') }}</p>
  </div>
</div>
