import { Directive, Input, OnChanges, OnInit, SimpleChanges, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appResultBackgroundColor]'
})
export class ResultBackgroundColorDirective implements OnInit, OnChanges {
  @Input() result: number;
  @Input() successResult: 'positive' | 'negative';

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['result']) {
      this.renderer.removeClass(this.elRef.nativeElement, 'success');
      this.renderer.removeClass(this.elRef.nativeElement, 'error');
      this.checkValue();
    }
  }

  ngOnInit(): void {
    this.checkValue();
  }

  checkValue() {
    if (this.result != null) {
      this.successResult === 'positive' ? this.checkPositive() : this.checkNegative();
    }
  }

  checkPositive() {
    (this.result > 0) ? this.renderer.addClass(this.elRef.nativeElement, 'success') :
      this.renderer.addClass(this.elRef.nativeElement, 'error');
  }

  checkNegative() {
    (this.result > 0) ? this.renderer.addClass(this.elRef.nativeElement, 'error') :
      this.renderer.addClass(this.elRef.nativeElement, 'success');
  }

}
