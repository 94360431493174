import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ADMIN_SUBSECTIONS } from '../config/permission-constants';
import { hasReadAccessForSubsection } from '../utils/jwt.utils';

@Injectable()
export class AdminAccessGuard implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkCanActivate(_route.data.section);
  }

  private checkCanActivate(subsection: string): boolean {
    if (hasReadAccessForSubsection(subsection)) {
      return true;
    }

    for (let i = 0; i < ADMIN_SUBSECTIONS.length; i++) {
      if (hasReadAccessForSubsection(ADMIN_SUBSECTIONS[i])) {
        this.redirectToFirstVisibleRoute(ADMIN_SUBSECTIONS[i]);
        return true;
      }
    }

    return false;
  }

  private redirectToFirstVisibleRoute(subsectionCode: string) {
    const subsectionRoute = subsectionCode.toLowerCase().replace('_', '');
    this.router.navigateByUrl(`/admin/${subsectionRoute}`);
  }
}
