import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { WorkGroup } from '../../models/work-group';
import { WorkGroupBuilder } from '../../models/builder/work-group.builder';

const getWorkGroupBody = (workGroup: WorkGroup) => ({
  name: workGroup.name,
  users: workGroup.users,
});

@Injectable()
export class WorkGroupApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}work-groups`;
  }

  getWorkGroups() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(WorkGroupBuilder.fromList)
      );
  }

  getWorkGroupsByUser(): Observable<WorkGroup[]> {
    return this.httpClient.get(`${this.apiUrl}/by-user`)
      .pipe(
        map(WorkGroupBuilder.fromList)
      );
  }

  getWorkGroup(hash: string): Observable<WorkGroup> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(WorkGroupBuilder.fromJson)
      );
  }

  deleteWorkGroup(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  updateWorkGroup(workGroup: WorkGroup): Observable<WorkGroup> {
    return this.httpClient.put(`${this.apiUrl}/${workGroup.hash}`, getWorkGroupBody(workGroup))
      .pipe(
        map(WorkGroupBuilder.fromJson)
      );
  }

  createWorkGroup(workGroup: WorkGroup): Observable<WorkGroup> {
    return this.httpClient.post(this.apiUrl, getWorkGroupBody(workGroup))
      .pipe(
        map(WorkGroupBuilder.fromJson)
      );
  }
}
