import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { SortInterface } from '../../sort-control/sort.interface';
import { ColumnComponent } from './column/column.component';

@Component({
  selector: 'app-sortable-table',
  templateUrl: './sortable-table.component.html',
  styleUrls: ['./sortable-table.component.css']
})
export class SortableTableComponent implements OnChanges {
  @Input() elements: any[];
  @Input() mainColumnIndex = 0;
  @Input() selectedOrder: SortInterface;
  @Input() rowClass: (value) => string = null;
  @Output() sort = new EventEmitter<SortInterface>();
  @ContentChildren(ColumnComponent) columns: QueryList<ColumnComponent>;
  @ViewChild(PerfectScrollbarDirective) content: PerfectScrollbarDirective;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.elements) {
      this.content.scrollToTop();
    }
  }

  getRowClass(row: any) {
    return (this.rowClass) ? this.rowClass(row) : '';
  }

}
