import { ProjectSinkingReasonBuilder } from '@/core/models/builder/project-sinking-reason.builder';
import { ProjectSinkingReason } from '@/core/models/project-sinking-reason';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const getProjectSinkingReasonBody = (projectSinkingReason: ProjectSinkingReason) => ({
  name: projectSinkingReason.name,
});

@Injectable()
export class ProjectSinkingReasonApiService {
  private apiUrl: string;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = `${environment.apiUrl}project-sinking-reason`;
  }

  getAll(): Observable<ProjectSinkingReason[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(ProjectSinkingReasonBuilder.fromList)
      );
  }

  get(hash: string): Observable<ProjectSinkingReason> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(ProjectSinkingReasonBuilder.fromJson)
      );
  }

  create(projectSinkingReason: ProjectSinkingReason): Observable<ProjectSinkingReason> {
    return this.httpClient.post(this.apiUrl, getProjectSinkingReasonBody(projectSinkingReason))
      .pipe(
        map(ProjectSinkingReasonBuilder.fromJson)
      );
  }

  delete(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  update(projectSinkingReason: ProjectSinkingReason): Observable<ProjectSinkingReason> {
    return this.httpClient.put(`${this.apiUrl}/${projectSinkingReason.hash}`, getProjectSinkingReasonBody(projectSinkingReason))
      .pipe(
        map(ProjectSinkingReasonBuilder.fromJson)
      );
  }
}
