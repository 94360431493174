import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { AppErrorHandler } from './error/app-error.handler';
import { TranslateLocalLoader } from './factories/translate-local-loader';
import { AdminAccessGuard } from './guards/admin-access.guard';
import { DashboardAccessGuard } from './guards/dashboard-access.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { NotLoggedInGuard } from './guards/not-logged-in.guard';
import { CompetitorApiService } from './services/api/Competitor-api.service';
import { ApplicationApiService } from './services/api/application-api.service';
import { AuthApiService } from './services/api/auth-api.service';
import { CommercialActionApiService } from './services/api/commercial-action-api.service';
import { CommercialActionFileApiService } from './services/api/commercial-action-file-api.service';
import { CommercialActionTypeApiService } from './services/api/commercial-action-type-api.service';
import { CommercialApiService } from './services/api/commercial-api.service';
import { ContactApiService } from './services/api/contact-api.service';
import { CustomerApiService } from './services/api/customer-api.service';
import { CustomerGroupApiService } from './services/api/customer-group-api.service';
import { DeliveryNotesApiService } from './services/api/delivery-notes-api.service';
import { DocumentApiService } from './services/api/documents-api.service';
import { ErrorApiService } from './services/api/error-api.service';
import { FolderApiService } from './services/api/folder-api.service';
import { ForecastApiService } from './services/api/forecast-api.service';
import { HardnessApiService } from './services/api/hardness-api.service';
import { InternalActionApiService } from './services/api/internal-action-api.service';
import { InternalActionFileApiService } from './services/api/internal-action-file-api.service';
import { InvoiceApiService } from './services/api/invoices-api.service';
import { MachineApiService } from './services/api/machine-api.service';
import { MachiningStudyApiService } from './services/api/machining-study-api.service';
import { MaterialApiService } from './services/api/material-api.service';
import { NotificationApiService } from './services/api/notification-api.service';
import { OfferApiService } from './services/api/offer-api.service';
import { PieceTypeApiService } from './services/api/piece-type.api.service';
import { PreMachinedMethodApiService } from './services/api/pre-machined-method-api.service';
import { PrivilegeApiService } from './services/api/privilege-api.service';
import { ProcessApiService } from './services/api/process-api.service';
import { ProjectApiService } from './services/api/project-api.service';
import { ProjectGroupApiService } from './services/api/project-group-api.service';
import { ProjectSinkingReasonApiService } from './services/api/project-sinking-reason-api.service';
import { ProvinceApiService } from './services/api/provice-api.service';
import { SectorApiService } from './services/api/sector-api.service';
import { SpecificationApiService } from './services/api/specification-api.service';
import { SupplierApiService } from './services/api/supplier-api.service';
import { ToleranceValueApiService } from './services/api/tolerance-value-api.service';
import { TrialApiService } from './services/api/trial-api.service';
import { TrialLineApiService } from './services/api/trial-line-api.service';
import { TrialLineFileApiService } from './services/api/trial-line-file-api.service';
import { TrialObjectiveApiService } from './services/api/trial-objective-api.service';
import { UserApiService } from './services/api/user-api.service';
import { WorkGroupApiService } from './services/api/work-group-api.service';
import { RefreshTokenService } from './services/authentication/refresh-token.service';
import { EmptyResponseInterceptor } from './services/base/interceptors/empty-response.interceptor';
import { ErrorInterceptor } from './services/base/interceptors/error.interceptor';
import { JwtInterceptor } from './services/base/interceptors/jwt.interceptor';
import { PendingInterceptor } from './services/base/interceptors/pending.interceptor';
import { RefreshTokenInterceptor } from './services/base/interceptors/refresh-token.interceptor';
import { PendingService } from './services/base/pending.service';
import { ResolverService } from './services/base/resolver.service';
import { OfflineCommercialActionService } from './services/offline/offline-commercial-action.service';
import { OfflineService } from './services/offline/offline.service';
import { OfflineStorageService } from './services/offline/storage/offline-storage.service';
import { ToleranceValueService } from './services/tolerance-value.service';
import { TranslationService } from './services/translation.service';
import { ValidationService } from './services/validation/validation.service';
import { AppService } from './services/visual/app.service';
import { CollapseService } from './services/visual/collapse.service';
import { ToasterService } from './services/visual/toaster.service';
import { ExpensesApiService } from './services/api/expenses-api.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};

@NgModule({
  imports: [
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      maxOpened: 1,
      positionClass: 'toast-top-center',
      preventDuplicates: false,
      timeOut: environment.toasterTimeout
    }),
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: TranslateLocalLoader }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker
    }),
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    // Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: PendingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EmptyResponseInterceptor, multi: true },
    // Handlers
    { provide: ErrorHandler, useClass: AppErrorHandler },
    // Perfect scroll config
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    // API Services
    ErrorApiService,
    AuthApiService,
    ApplicationApiService,
    InvoiceApiService,
    MaterialApiService,
    MachineApiService,
    ProjectApiService,
    ProjectGroupApiService,
    CommercialActionApiService,
    CommercialActionTypeApiService,
    CustomerApiService,
    CommercialApiService,
    MachiningStudyApiService,
    DocumentApiService,
    FolderApiService,
    InternalActionApiService,
    InternalActionFileApiService,
    TrialApiService,
    TrialLineApiService,
    CompetitorApiService,
    UserApiService,
    ProcessApiService,
    PieceTypeApiService,
    SectorApiService,
    CommercialActionFileApiService,
    ContactApiService,
    WorkGroupApiService,
    SupplierApiService,
    NotificationApiService,
    PrivilegeApiService,
    ProvinceApiService,
    DeliveryNotesApiService,
    SpecificationApiService,
    PreMachinedMethodApiService,
    HardnessApiService,
    ToleranceValueApiService,
    TrialObjectiveApiService,
    OfferApiService,
    TrialLineFileApiService,
    ProjectSinkingReasonApiService,
    ForecastApiService,
    ExpensesApiService,
    // Offline services
    OfflineService,
    OfflineStorageService,
    OfflineCommercialActionService,
    CustomerGroupApiService,
    // Services
    AppService,
    CollapseService,
    PendingService,
    RefreshTokenService,
    ResolverService,
    ToasterService,
    TranslationService,
    ValidationService,
    ToleranceValueService,
    // Guards
    AdminAccessGuard,
    DashboardAccessGuard,
    LoggedInGuard,
    NotLoggedInGuard,
  ]
})
export class CoreModule { }
