export class ProjectInvoice {
  constructor(
    public invoiceNumber?: number
  ) {}

  clone(): ProjectInvoice {
    return new ProjectInvoice(
      this.invoiceNumber,
    );
  }
}
