import { CommercialActionFileBuilder } from '@/core/models/builder/commercial-action-file.builder';
import { CommercialActionBuilder } from '@/core/models/builder/commercial-action.builder';
import { CommercialAction } from '@/core/models/commercial-action';
import { CommercialActionFile } from '@/core/models/commercial-action-file';
import { NetworkStatusService } from '@/core/services/network/network-status.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const getCommercialActionFileBody = (commercialActionHash: String, fileId: string) => ({
  commercialActionHash,
  fileId,
});

@Injectable()
export class CommercialActionFileApiService {
  private apiUrl: string;
  constructor(
    private httpClient: HttpClient,
    private networkStatusService: NetworkStatusService,
  ) {
    this.apiUrl = `${environment.apiUrl}commercial-actions-files`;
  }

  createCommercialAction(commercialActionHash: String, fileId: string): Observable<CommercialAction> {
    return this.httpClient.post(this.apiUrl, getCommercialActionFileBody(commercialActionHash, fileId))
      .pipe(
        map(CommercialActionBuilder.fromJson)
      );
  }

  getCommercialActionFiles(commercialActionHash: string): Observable<CommercialActionFile[]> {
    if (!this.networkStatusService.hasNetwork) {
      return of([]);
    }

    const params = new HttpParams()
      .append('commercialActionHash', commercialActionHash);

    return this.httpClient.get(`${this.apiUrl}`, { params })
      .pipe(
        map(CommercialActionFileBuilder.fromList)
      );
  }

  deleteCommercialActionFile(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }
}
