import { mapCollection } from '../../utils/collection.utils';
import { CustomerGroup } from '../customer-group';
import { CustomerBuilder } from './customer.builder';

export class CustomerGroupBuilder {
  static fromJson(json: any) {
    return (json)
      ? new CustomerGroup(
        json.hash,
        json.userHash,
        json.userName,
        json.code,
        json.description,
        CustomerBuilder.fromList(json.customers),
      )
      : null;
  }

  static fromList(list: any): CustomerGroup[] {
    return Array.isArray(list)
      ? mapCollection(CustomerGroupBuilder.fromJson, list)
      : [];
  }
}
