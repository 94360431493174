export class Commercial {
  constructor(
    public id?: string,
    public name?: string,
  ) { }

  clone() {
    return new Commercial(
      this.id,
      this.name,
    );
  }
}
