import { mapCollection } from '../../utils/collection.utils';
import { parseNumber } from '../../utils/number.utils';
import { ProjectCommercial } from '../project-commercial';

export class ProjectCommercialBuilder {
  static fromJson(json): ProjectCommercial {
    return (json)
      ? new ProjectCommercial(
        json.hash,
        json.name,
        parseNumber(json.id)
      )
      : null;
  }

  static fromList(list): ProjectCommercial[] {
    return (Array.isArray(list))
      ? mapCollection(ProjectCommercialBuilder.fromJson, list)
      : [];
  }
}
