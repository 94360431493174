import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TrialBuilder } from 'src/app/core/models/builder/trial.builder';
import { Trial } from 'src/app/core/models/trial';
import { environment } from 'src/environments/environment';
import { TrialLine } from '../../models/trial-line';
import { buildCreateOrUpdateTrialBody, buildGetTrialPdfBody } from './builder/trial-request.builder';

@Injectable()
export class TrialApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}trials`;
  }

  getTrial(hash: string): Observable<Trial> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(TrialBuilder.fromJson)
      );
  }

  getTrials(projectHash: string): Observable<Trial[]> {
    let params = new HttpParams();
    if (projectHash) { params = params.set('projectHash', projectHash); }
    return this.httpClient.get(this.apiUrl, { params })
      .pipe(
        map(TrialBuilder.fromList)
      );
  }

  getTrialRoiPdfByHash(trialHash: string): Observable<ArrayBuffer> {
    return this.httpClient.get(`${this.apiUrl}/${trialHash}/pdf/roi`, { responseType: 'arraybuffer' });
  }

  getTrialRoiPdf(trial: Trial): Observable<ArrayBuffer> {
    return this.httpClient.post(`${this.apiUrl}/pdf/roi/${trial.hash}`, buildGetTrialPdfBody(trial), { responseType: 'arraybuffer' });
  }

  getTrialSheetPdf(trialHash: string): Observable<ArrayBuffer> {
    return this.httpClient.get(`${this.apiUrl}/${trialHash}/pdf/trial-sheet`, { responseType: 'arraybuffer' });
  }

  deleteTrial(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  updateTrial(trial: Trial, projectHash: string): Observable<Trial> {
    return this.httpClient.put(`${this.apiUrl}/${trial.hash}`, buildCreateOrUpdateTrialBody(trial, projectHash))
      .pipe(
        map(TrialBuilder.fromJson)
      );
  }

  updateTrialCurrentTrialLine(trialLine: TrialLine): Observable<any> {
    return this.httpClient.put(`${this.apiUrl}/update-current-trial-line/${trialLine.hash}`, null);
  }

  createTrial(trial: Trial, projectHash: string): Observable<Trial> {
    return this.httpClient.post(this.apiUrl, buildCreateOrUpdateTrialBody(trial, projectHash))
      .pipe(
        map(TrialBuilder.fromJson)
      );
  }
}
