export class NotifiedUser {
  constructor(
    public hash?: string,
    public firstName?: string,
    public lastName?: string,
  ) { }

  clone() {
    return new NotifiedUser(
      this.hash,
      this.firstName,
      this.lastName,
    );
  }
}
