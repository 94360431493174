<form [formGroup]="form" class="form">
  <div class="container">
    <div class="title title--grey" translate>admin.processes.edit.formTitle</div>
    <div class="fields">
      <div class="field">
        <app-input-text-field
          [id]="'name'"
          [form]="form"
          [controlName]="'name'"
          [key]="'admin.processes.edit.name'"
        ></app-input-text-field>
      </div>
      <div class="column-1-2">
        <div class="field">
          <label translate>admin.processes.edit.type</label>
          <div class="flex">
            <app-checkbox-field
              [id]="'isInternal'"
              [form]="form"
              [controlName]="'isInternal'"
              [key]="'admin.processes.processTypes.internal'"
            ></app-checkbox-field>
            <app-checkbox-field
              [id]="'isExternal'"
              [form]="form"
              [controlName]="'isExternal'"
              [key]="'admin.processes.processTypes.external'"
            ></app-checkbox-field>
          </div>
        </div>
      </div>
      <div class="column-1-2">
        <div class="field">
          <label translate>admin.processes.edit.fieldsVisibility</label>
          <div class="flex">
            <app-checkbox-field
              [id]="'axialDepthCutVisibility'"
              [form]="form"
              [controlName]="'axialDepthCutVisibility'"
              [key]="'admin.processes.edit.axialDepthCutVisibility'"
            ></app-checkbox-field>
            <app-checkbox-field
              [id]="'rollerRadiusVisibility'"
              [form]="form"
              [controlName]="'rollerRadiusVisibility'"
              [key]="'admin.processes.edit.rollerRadiusVisibility'"
            ></app-checkbox-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ==================== ACTION BAR ====================== -->
  <div class="action-bar">
    <div class="container">
      <div class="action-bar__actions">
        <div class="action-bar__actions__right">
          <button type="button" appBackButton class="button button-grey" translate>admin.processes.edit.cancel</button>
          <button type="button" (click)="onSubmit()" [disabled]="form.invalid" class="button button-primary" translate>admin.processes.edit.save</button>
        </div>
      </div>
    </div>
  </div>
</form>
