import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { CommercialActionTypeBuilder } from '../../models/builder/commercial-action-type.builder';

@Injectable()
export class CommercialActionTypeApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}commercial-action-types`;
  }

  getCommercialActionTypes() {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(CommercialActionTypeBuilder.fromList)
      );
  }
}
