import { Process } from '@/core/models/process';
import { ValidationService } from '@/core/services/validation/validation.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

const formMapping = Object.freeze({
  name: 'name',
});

@Component({
  selector: 'app-edit-process-form',
  templateUrl: './edit-process-form.component.html'
})
export class EditProcessFormComponent implements OnInit, OnChanges {
  @Input() process: Process;
  @Input() errors: any;
  @Input() edit: boolean;
  @Output() save = new EventEmitter<Process>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      isInternal: [''],
      isExternal: [''],
      axialDepthCutVisibility: [''],
      rollerRadiusVisibility: [''],
     }, {
      validators: [this.atLeastOneCheckboxCheckedValidator()]
    });

    this.updateForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['name'] && this.form) {
      this.updateForm();
    }
    if (changes.hasOwnProperty('errors')) {
      this.validationService.showFieldErrors(this.errors, this.form, formMapping);
    }
  }

  private updateForm() {
    const value = {
      name: (this.process) ? this.process.name : '',
      isInternal: (this.process) ? this.process.isInternal : false,
      isExternal: (this.process) ? this.process.isExternal : false,
      axialDepthCutVisibility: (this.process) ? this.process.axialDepthCutVisibility : false,
      rollerRadiusVisibility: (this.process) ? this.process.rollerRadiusVisibility : false,
    };

    this.form.reset(value);
  }

  onSubmit() {
    const process = (this.process) ? this.process.clone() : new Process({});
    const value = this.form.value;
    process.name = value.name;
    process.isInternal = value.isInternal;
    process.isExternal = value.isExternal;
    process.axialDepthCutVisibility = value.axialDepthCutVisibility;
    process.rollerRadiusVisibility = value.rollerRadiusVisibility;
    this.save.emit(process);
  }

  private atLeastOneCheckboxCheckedValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const isInternal = formGroup.get('isInternal').value;
      const isExternal = formGroup.get('isExternal').value;

      if (!isInternal && !isExternal) {
        return { atLeastOneCheckboxChecked: true };
      }

      return null;
    };
   }
}
