import { Directive, ElementRef } from '@angular/core';
import { hasExternalUserRole } from '../../core/utils/jwt.utils';

@Directive({
  selector: '[appHideToExternalUsers]'
})
export class HideToExternalUsersDirective {
  constructor(private elementRef: ElementRef) {
    if (hasExternalUserRole()) {
      this.elementRef.nativeElement.remove();
    }
  }
}
