import { AccessLevel } from './access-level';
import { Privilege } from './privilege';
import { PermissionSubsection } from './permission-subsection';

export class PermissionPrivilege {
  constructor(
    public hash?: string,
    public privilege?: Privilege,
    public accessLevel?: AccessLevel,
    public permissionSubsection?: PermissionSubsection
  ) { }

  clone(): PermissionPrivilege {
    return new PermissionPrivilege(
      this.hash,
      this.privilege,
      this.accessLevel,
      this.permissionSubsection
    );
  }
}
