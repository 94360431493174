import { mapCollection } from '../../utils/collection.utils';
import { Folder } from '../folder';

export class FolderBuilder {
  static fromJson(json) {
    return (json)
      ? new Folder(
        json.name,
        json.path,
        json.id
      )
      : null;
  }

  static fromList(list) {
    return (Array.isArray(list)) ? mapCollection(FolderBuilder.fromJson, list) : [];
  }
}
