import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PieceType } from '../../../../core/models/piece-type';
import { PieceTypesService } from '../piece-types.service';

@Injectable()
export class PieceTypeResolver implements Resolve<PieceType> {

  constructor(private pieceTypesService: PieceTypesService) {
  }

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<PieceType> | Promise<PieceType> | PieceType {
    const hash = route.params['hash'];
    return this.pieceTypesService.resolvePieceType(hash);
  }
}
