import { mapCollection } from '../../utils/collection.utils';
import { CommercialAction } from '../commercial-action';
import { CommercialActionChildBuilder } from './commercial-action-child.builder';
import { ContactBuilder } from './contact.builder';
import { CustomerBuilder } from './customer.builder';
import { NotifiedUserBuilder } from './notified-user.builder';
import { ProjectBuilder } from './project.builder';

export class CommercialActionBuilder {
  static fromJson(json: any): CommercialAction | null {
    return (json)
      ? new CommercialAction(
          json.hash,
          json.subject,
          json.date,
          json.startTime,
          json.time,
          json.reminderDate,
          json.comments,
          json.user,
          json.commercialActionType,
          json.cost,
          CustomerBuilder.fromJson(json.customer),
          ContactBuilder.fromList(json.contacts),
          NotifiedUserBuilder.fromList(json.notifiedUsers),
          ProjectBuilder.fromJson(json.project),
          json.filesCount,
          json.parentHash,
          CommercialActionChildBuilder.fromList(json.children),
        )
      : null;
  }

  static fromList(list: any): CommercialAction[] {
    return Array.isArray(list)
      ? mapCollection(CommercialActionBuilder.fromJson, list)
      : [];
  }
}
