import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AccessLevel } from '../../models/access-level';
import { AccessLevelBuilder } from '../../models/builder/access-level.builder';
import { PermissionPrivilegeBuilder } from '../../models/builder/permission-privilege.builder';
import { PermissionSectionBuilder } from '../../models/builder/permission-section.builder';
import { PrivilegeBuilder } from '../../models/builder/privilege.builder';
import { PermissionPrivilege } from '../../models/permission-privilege';
import { PermissionSection } from '../../models/permission-section';
import { Privilege } from '../../models/privilege';

const getPermissionPrivilegeBody = (permissionPrivilege: PermissionPrivilege) => ({
  accessLevelHash: permissionPrivilege.accessLevel.hash
});

const getPrivilegeBody = (privilege: Privilege) => ({
  code: privilege.code,
  name: privilege.name
});

@Injectable()
export class PrivilegeApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}privileges`;
  }

  deletePrivilege(hash: string): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  getPrivileges(): Observable<Privilege[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(PrivilegeBuilder.fromList)
      );
  }

  getPrivilege(hash: string): Observable<Privilege> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(PrivilegeBuilder.fromJson)
      );
  }

  createPrivilege(privilege: Privilege): Observable<Privilege> {
    return this.httpClient.post(this.apiUrl, getPrivilegeBody(privilege))
      .pipe(
        map(PrivilegeBuilder.fromJson)
      );
  }

  updatePrivilege(privilege: Privilege): Observable<Privilege> {
    return this.httpClient.put(`${this.apiUrl}/${privilege.hash}`, getPrivilegeBody(privilege))
      .pipe(
        map(PrivilegeBuilder.fromJson)
      );
  }

  getPermissionSections(): Observable<PermissionSection[]> {
    return this.httpClient.get(`${this.apiUrl}/sections`)
      .pipe(
        map(PermissionSectionBuilder.fromList)
      );
  }

  getAccessLevels(): Observable<AccessLevel[]> {
    return this.httpClient.get(`${this.apiUrl}/access-levels`)
      .pipe(
        map(AccessLevelBuilder.fromList)
      );
  }

  updatePermissionPrivilege(permissionPrivilege: PermissionPrivilege): Observable<PermissionPrivilege> {
    return this.httpClient.put(`${this.apiUrl}/permission-privilege/${permissionPrivilege.hash}`,
      getPermissionPrivilegeBody(permissionPrivilege))
      .pipe(
        map(PermissionPrivilegeBuilder.fromJson)
      );
  }
}
