import { mapCollection } from '../../utils/collection.utils';
import { PreMachinedMethod } from '../pre-machined-method';

export class PreMachinedMethodBuilder {
  static fromJson(json: any): PreMachinedMethod {
    return (json)
    ? new PreMachinedMethod(
        json.hash,
        json.name
      )
      : null;
  }

  static fromList(list: string[]): PreMachinedMethod[] {
    return Array.isArray(list)
      ? mapCollection(PreMachinedMethodBuilder.fromJson, list)
      : [];
  }
}
