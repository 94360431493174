import { mapCollection } from '../../utils/collection.utils';
import { Document } from '../document';

export class DocumentBuilder {
  static fromJson(json): Document {
    return (json)
      ? new Document(
        json.name,
        json.path,
      )
      : null;
  }

  static fromList(list): Document[] {
    return (Array.isArray(list))
      ? mapCollection(DocumentBuilder.fromJson, list)
      : [];
  }
}
