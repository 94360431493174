<main id="page-login">
  <section>
    <article>
      <img alt="" src="assets/images/logo.svg">
      <div>
        <div *ngIf="(resetPassword$ | async)">
          <app-reset-password-form *ngIf="!done" (reset)="onResetPassword($event)" [errors]="fieldErrors">
          </app-reset-password-form>
          <app-reset-password-done *ngIf="done"></app-reset-password-done>
        </div>
        <app-reset-password-invalid *ngIf="(resetPassword$ | async) !== true"></app-reset-password-invalid>
      </div>
    </article>
  </section>
</main>
