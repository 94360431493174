export class PieceType {
  constructor(
    public hash?: string,
    public name?: string,
  ) {}

  clone() {
    return new PieceType(
      this.hash,
      this.name,
    );
  }
}
