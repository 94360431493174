import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent {
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
  ) { }

  onClick() {
    this.router.navigate(['../'], { relativeTo: this.activeRoute });
  }
}
