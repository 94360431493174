import { mapCollection } from '../../utils/collection.utils';
import { DeliveryNoteGroup } from '../delivery-note-grouped';
import { DeliveryNoteBuilder } from './delivery-note.builder';

export class DeliveryNoteGroupBuilder {
  static fromJson(json: any): DeliveryNoteGroup {
    return json
      ? new DeliveryNoteGroup(
          json.customerId,
          json.customerName,
          json.totalPriceEstimated,
          json.totalPrice,
          json.reviewed,
          DeliveryNoteBuilder.fromList(json.deliveryNotes),
        )
      : null;
  }

  static fromList(list: any): DeliveryNoteGroup[] {
    return Array.isArray(list) ? mapCollection(DeliveryNoteGroupBuilder.fromJson, list) : [];
  }
}
