<app-input-field
  [form]="form"
  type="email"
  [id]="id"
  [controlName]="controlName"
  [key]="key"
  [class]="class"
  [minlength]="minlength"
  [maxlength]="maxlength"
  [hideLabel]="hideLabel"
  [hideErrors]="hideErrors"
  [readonly]="readonly"
></app-input-field>
