import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { routerErrorHandler } from './core/factories/router-error-handler.factory';
import { NotFoundComponent } from './not-found/not-found.component';

const appRoutes: Routes = [
  { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: '404', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes, {
        errorHandler: routerErrorHandler,
        preloadingStrategy: PreloadAllModules,
        onSameUrlNavigation: 'reload',
        relativeLinkResolution: 'legacy',
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
