import { Injectable } from '@angular/core';
import { ProjectApiService } from '../../../../../core/services/api/project-api.service';

@Injectable()
export class ProjectHeaderSharedService {
  minimized = false;

  constructor(
    private projectApiService: ProjectApiService
  ) { }

  deleteProject(hash: string) {
    return this.projectApiService.deleteProject(hash).toPromise();
  }
}
