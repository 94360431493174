import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ContactBuilder } from '../../models/builder/contact.builder';
import { CustomerBuilder } from '../../models/builder/customer.builder';
import { Contact } from '../../models/contact';
import { Customer } from '../../models/customer';

@Injectable()
export class CustomerApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}customers`;
  }

  getCustomerContacts(customerId: number): Observable<Contact[]> {
    return this.httpClient.get(`${this.apiUrl}/${customerId}/contacts`)
      .pipe(
        map(ContactBuilder.fromList)
      );
  }

  getCustomers(): Observable<Customer[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(CustomerBuilder.fromList)
      );
  }

  getPotentialCustomers(
    customer: string | null,
    contact: string | null,
    phone: string | null,
    email: string | null,
    zone: string | null,
    city: string | null,
    provinceId: number | null,
  ): Observable<Customer[]> {
    let params = new HttpParams();

    if (customer) {
      params = params.append('customer', customer);
    }

    if (contact) {
      params = params.append('contact', contact);
    }

    if (phone) {
      params = params.append('phone', phone);
    }

    if (email) {
      params = params.append('email', email);
    }

    if (zone) {
      params = params.append('zone', zone);
    }

    if (city) {
      params = params.append('city', city);
    }

    if (provinceId) {
      params = params.append('provinceId', provinceId.toString());
    }

    return this.httpClient.get(`${this.apiUrl}/potentials`, { params })
      .pipe(
        map(CustomerBuilder.fromList)
      );
  }

  deleteUninterestedCustomer(customer: Customer): Observable<Object> {
    return this.httpClient.delete(`${this.apiUrl}/uninterested/${ customer.id }`);
  }

  createUninterestedCustomer(customer: Customer): Observable<Customer> {
    return this.httpClient.post(`${this.apiUrl}/uninterested/`, { customerId: customer.id })
      .pipe(
        map(CustomerBuilder.fromJson)
      );
  }
}
