import { OfferLine } from './offer-line';

export class Offer {
  constructor(
    public number: number,
    public offerLines: OfferLine[],
  ) { }

  clone(): Offer {
    return new Offer(
      this.number,
      this.offerLines,
    );
  }
}
