export class Province {
  constructor(
    public id?: number,
    public name?: string,
  ) { }

  clone(): Province {
    return new Province(
      this.id,
      this.name,
    );
  }
}
