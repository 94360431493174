export class Material {
  constructor(
    public hash?: string,
    public group?: string,
    public subgroup?: string,
    public denomination?: string,
  ) {}

  clone() {
    return new Material(
      this.hash,
      this.group,
      this.subgroup,
      this.denomination
    );
  }
}
