import { MachiningStudyBuilder } from '@/core/models/builder/machining-study.builder';
import { MachiningStudy } from '@/core/models/machining-study';
import { RecalculatedTrials } from '@/dashboard/projects/projects-shared/components/trials-shared/trials-shared.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getMachiningStudyBody } from './builder/machining-study.builder';
import { getTrialLineResultBody } from './builder/trial-line-request.builder';
import { buildCreateOrUpdateTrialBody } from './builder/trial-request.builder';

@Injectable()
export class MachiningStudyApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}machining-study`;
  }

  getMachiningStudy(hash: string): Observable<MachiningStudy> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(MachiningStudyBuilder.fromJson)
      );
  }

  createMachiningStudy(machiningStudy: MachiningStudy): Observable<MachiningStudy> {
    return this.httpClient.post(this.apiUrl, getMachiningStudyBody(machiningStudy))
      .pipe(
        map(MachiningStudyBuilder.fromJson)
      );
  }

  downloadMachiningStudyPdf(hash: string, locale: string) {
    let params = new HttpParams();
    params = params.set('hash', hash);
    params = params.set('locale', locale);

    return this.httpClient.get(`${this.apiUrl}/pdf`, { responseType: 'arraybuffer', params });
  }

  updateMachiningStudy(machiningStudy: MachiningStudy): Observable<MachiningStudy> {
    return this.httpClient.put(`${this.apiUrl}/${machiningStudy.hash}`, getMachiningStudyBody(machiningStudy))
      .pipe(
        map(MachiningStudyBuilder.fromJson)
      );
  }

  updateMachiningStudyAndTrialsAndTrialLineResults(
    machiningStudy: MachiningStudy,
    recalculatedTrials: RecalculatedTrials,
  ) {
    return this.httpClient.put(`${this.apiUrl}/${machiningStudy.hash}/with-trials`,
      {
        machiningStudy: getMachiningStudyBody(machiningStudy),
        trials: recalculatedTrials.trials.map(trial => {
          const body = buildCreateOrUpdateTrialBody(trial, machiningStudy.project.hash);
          return { ...body, hash: trial.hash };
        }),
        trialLineResults: recalculatedTrials.trialLineResults.map(trialLineResult => {
          const body = getTrialLineResultBody(trialLineResult);
          return { ...body, hash: trialLineResult.trialLineHash };
        })
      })
      .pipe(
        map(MachiningStudyBuilder.fromJson)
      );
  }
}
