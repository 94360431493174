<div
  class="field field--checkbox"
  [formGroup]="form"
  *ngIf="form.get(controlName) as control"
  [class.error]="control.touched && control.errors"
  [class.field--required]="hasRequiredField(control)">
  <input
    type="checkbox"
    [id]="id"
    [formControlName]="controlName"
  >
  <label [for]="id">
    {{ key | translate }}
    <span class="required" *ngIf="hasRequiredField(control)"></span>
  </label>
  <div class="box-error" *ngIf="!hideErrors && control.touched">
    <p class="error" *ngIf="control.hasError('required')" translate>error.required</p>
    <p class="error" *ngIf="control.hasError('email')" translate>error.email</p>
    <p class="error" *ngIf="control.hasError('maxlength')" translate>error.maxlength</p>
    <p class="error" *ngIf="control.hasError('minlength')" translate>error.minlength</p>
    <p class="error" *ngIf="control.hasError('pattern')" translate>error.pattern</p>
  </div>
</div>
