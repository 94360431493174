import { mapCollection } from '../../utils/collection.utils';
import { Application } from '../application';

export class ApplicationBuilder {
  static fromJson(json: any) {
    return (json)
      ? new Application(
        json.hash,
        json.name,
      )
      : null;
  }

  static fromList(list): Application[] {
    return Array.isArray(list)
      ? mapCollection(ApplicationBuilder.fromJson, list)
      : [];
  }
}
