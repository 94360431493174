import { Trial } from '@/core/models/trial';
import { CreateOrUpdateTrialRequest } from '../request/create-or-update-trial.request';
import { GetTrialPdfRequest } from '../request/get-trial-pdf.request';

export const buildCreateOrUpdateTrialBody = (trial: Trial, projectHash: string): CreateOrUpdateTrialRequest => ({
  // Base
  projectHash,
  document: trial.document,
  trialObjectiveHash: trial.trialObjective ? trial.trialObjective.hash : null,
  plate: trial.plate,
  jump: trial.jump,
  reamer: trial.reamer,
  machineHash: trial.machine ? trial.machine.hash : null,
  materialHash: trial.material ? trial.material.hash : null,
  materialDenominationHash: trial.materialDenomination ? trial.materialDenomination.hash : null,
  materialIsoHash: trial.materialIso ? trial.materialIso.hash : null,
  materialIsoOld: trial.materialIsoOld,
  // ROI Parameters
  toolDiameter: trial.parameters.toolDiameter,
  noOfParts: trial.parameters.noOfParts,
  boresPerPart: trial.parameters.boresPerPart,
  totalBores: trial.parameters.totalBores,
  urmaProduct: trial.parameters.urmaProduct,
  competitorHash: trial.parameters.competitor ? trial.parameters.competitor.hash : null,
  cuttingDepth: trial.parameters.cuttingDepth,
  // ROI urma
  // Step 3
  noOfTeeth: trial.urma.noOfTeeth,
  cuttingSpeedOriginal: trial.urma.cuttingSpeedOriginal,
  cuttingSpeedPercentage: trial.urma.cuttingSpeedPercentage,
  cuttingSpeed: trial.urma.cuttingSpeed,
  revolutions: trial.urma.revolutions,
  feedPerTooth: trial.urma.feedPerTooth,
  tableFeed: trial.urma.tableFeed,
  // Step 4
  machiningTimePerBore: trial.urma.machiningTimePerBore,
  machiningTimePerPart: trial.urma.machiningTimePerPart,
  // Step 5
  compensation: trial.urma.compensation,
  compensationErpOfferLineIds: trial.urma.compensationErpOfferLineIds,
  insert: trial.urma.insert,
  insertErpOfferLineIds: trial.urma.insertErpOfferLineIds,
  totalToolCost: trial.urma.totalToolCost,
  // Step 6
  toolLifePerInsert: trial.urma.toolLifePerInsert,
  machineCostPerHour: trial.urma.machineCostPerHour,
  noOfInsertUsesPerSerie: trial.urma.noOfInsertUsesPerSerie,
  noOfNewToolsPerSerie: trial.urma.noOfNewToolsPerSerie,
  totalToolCostPerSerie: trial.urma.totalToolCostPerSerie,
  // Step 7
  insertChangeTime: trial.urma.insertChangeTime,
  adjustmentTime: trial.urma.adjustmentTime,
  runOutInterval: trial.urma.runOutInterval,
  // Step 8
  noOfPossibleReconditionings: trial.urma.noOfPossibleReconditionings,
  totalCostPerInsertChange: trial.urma.totalCostPerInsertChange,
  reconditioningCost: trial.urma.reconditioningCost,
  logisticsCostPerReconditioning: trial.urma.logisticsCostPerReconditioning,
  totalCostForReconditioning: trial.urma.totalCostForReconditioning,
  toolLifeFactorReconditioning: trial.urma.toolLifeFactorReconditioning,
  // Step 9
  totalToolAndAdditionalCostForSerie: trial.urma.totalToolAndAdditionalCostForSerie,
  machiningTotalTime: trial.urma.machiningTotalTime,
  machiningCost: trial.urma.machiningCost,
  costPerBore: trial.urma.costPerBore,
  // ROI Competitor
  // Step 3
  c_noOfTeeth: trial.competitor.noOfTeeth,
  c_cuttingSpeed: trial.competitor.cuttingSpeed,
  c_revolutions: trial.competitor.revolutions,
  c_feedPerTooth: trial.competitor.feedPerTooth,
  c_tableFeed: trial.competitor.tableFeed,
  // Step 4
  c_machiningTimePerBore: trial.competitor.machiningTimePerBore,
  c_machiningTimePerPart: trial.competitor.machiningTimePerPart,
  // Step 5
  c_compensation: trial.competitor.compensation,
  c_insert: trial.competitor.insert,
  c_totalToolCost: trial.competitor.totalToolCost,
  // Step 6
  c_toolLifePerInsert: trial.competitor.toolLifePerInsert,
  c_machineCostPerHour: trial.competitor.machineCostPerHour,
  c_noOfInsertUsesPerSerie: trial.competitor.noOfInsertUsesPerSerie,
  c_noOfNewToolsPerSerie: trial.competitor.noOfNewToolsPerSerie,
  c_totalToolCostPerSerie: trial.competitor.totalToolCostPerSerie,
  // Step 7
  c_insertChangeTime: trial.competitor.insertChangeTime,
  c_adjustmentTime: trial.competitor.adjustmentTime,
  c_runOutInterval: trial.competitor.runOutInterval,
  // Step 8
  c_noOfPossibleReconditionings: trial.competitor.noOfPossibleReconditionings,
  c_totalCostPerInsertChange: trial.competitor.totalCostPerInsertChange,
  c_reconditioningCost: trial.competitor.reconditioningCost,
  c_logisticsCostPerReconditioning: trial.competitor.logisticsCostPerReconditioning,
  c_totalCostForReconditioning: trial.competitor.totalCostForReconditioning,
  c_toolLifeFactorReconditioning: trial.competitor.toolLifeFactorReconditioning,
  // Step 9
  c_totalToolAndAdditionalCostForSerie: trial.competitor.totalToolAndAdditionalCostForSerie,
  c_machiningTotalTime: trial.competitor.machiningTotalTime,
  c_machiningCost: trial.competitor.machiningCost,
  c_costPerBore: trial.competitor.costPerBore,
  // ROI Results
  totalToolCostDiff: trial.totalToolCostDiff,
  totalToolCostDiffPercent: trial.totalToolCostDiffPercent,
  savingOfMachiningTime: trial.savingOfMachiningTime,
  savingOfMachiningTimePercent: trial.savingOfMachiningTimePercent,
  savingPerMachiningTime: trial.savingPerMachiningTime,
  savingPerBore: trial.savingPerBore,
  savingPerBorePercent: trial.savingPerBorePercent,
  savingPerSerieOrYear: trial.savingPerSerieOrYear,
});

export const buildGetTrialPdfBody = (trial: Trial): GetTrialPdfRequest => ({
  // ROI Parameters
  toolDiameter: trial.parameters.toolDiameter,
  noOfParts: trial.parameters.noOfParts,
  boresPerPart: trial.parameters.boresPerPart,
  totalBores: trial.parameters.totalBores,
  urmaProduct: trial.parameters.urmaProduct,
  competitorHash: trial.parameters.competitor ? trial.parameters.competitor.hash : null,
  cuttingDepth: trial.parameters.cuttingDepth,
  // ROI urma
  // Step 3
  noOfTeeth: trial.urma.noOfTeeth,
  cuttingSpeed: trial.urma.cuttingSpeed,
  revolutions: trial.urma.revolutions,
  feedPerTooth: trial.urma.feedPerTooth,
  tableFeed: trial.urma.tableFeed,
  // Step 4
  machiningTimePerBore: trial.urma.machiningTimePerBore,
  machiningTimePerPart: trial.urma.machiningTimePerPart,
  // Step 5
  compensation: trial.urma.compensation,
  insert: trial.urma.insert,
  totalToolCost: trial.urma.totalToolCost,
  // Step 6
  toolLifePerInsert: trial.urma.toolLifePerInsert,
  machineCostPerHour: trial.urma.machineCostPerHour,
  noOfInsertUsesPerSerie: trial.urma.noOfInsertUsesPerSerie,
  noOfNewToolsPerSerie: trial.urma.noOfNewToolsPerSerie,
  totalToolCostPerSerie: trial.urma.totalToolCostPerSerie,
  // Step 7
  insertChangeTime: trial.urma.insertChangeTime,
  adjustmentTime: trial.urma.adjustmentTime,
  runOutInterval: trial.urma.runOutInterval,
  // Step 8
  noOfPossibleReconditionings: trial.urma.noOfPossibleReconditionings,
  totalCostPerInsertChange: trial.urma.totalCostPerInsertChange,
  reconditioningCost: trial.urma.reconditioningCost,
  logisticsCostPerReconditioning: trial.urma.logisticsCostPerReconditioning,
  totalCostForReconditioning: trial.urma.totalCostForReconditioning,
  toolLifeFactorReconditioning: trial.urma.toolLifeFactorReconditioning,
  // Step 9
  totalToolAndAdditionalCostForSerie: trial.urma.totalToolAndAdditionalCostForSerie,
  machiningTotalTime: trial.urma.machiningTotalTime,
  machiningCost: trial.urma.machiningCost,
  costPerBore: trial.urma.costPerBore,
  // ROI Competitor
  // Step 3
  c_noOfTeeth: trial.competitor.noOfTeeth,
  c_cuttingSpeed: trial.competitor.cuttingSpeed,
  c_revolutions: trial.competitor.revolutions,
  c_feedPerTooth: trial.competitor.feedPerTooth,
  c_tableFeed: trial.competitor.tableFeed,
  // Step 4
  c_machiningTimePerBore: trial.competitor.machiningTimePerBore,
  c_machiningTimePerPart: trial.competitor.machiningTimePerPart,
  // Step 5
  c_compensation: trial.competitor.compensation,
  c_insert: trial.competitor.insert,
  c_totalToolCost: trial.competitor.totalToolCost,
  // Step 6
  c_toolLifePerInsert: trial.competitor.toolLifePerInsert,
  c_machineCostPerHour: trial.competitor.machineCostPerHour,
  c_noOfInsertUsesPerSerie: trial.competitor.noOfInsertUsesPerSerie,
  c_noOfNewToolsPerSerie: trial.competitor.noOfNewToolsPerSerie,
  c_totalToolCostPerSerie: trial.competitor.totalToolCostPerSerie,
  // Step 7
  c_insertChangeTime: trial.competitor.insertChangeTime,
  c_adjustmentTime: trial.competitor.adjustmentTime,
  c_runOutInterval: trial.competitor.runOutInterval,
  // Step 8
  c_noOfPossibleReconditionings: trial.competitor.noOfPossibleReconditionings,
  c_totalCostPerInsertChange: trial.competitor.totalCostPerInsertChange,
  c_reconditioningCost: trial.competitor.reconditioningCost,
  c_logisticsCostPerReconditioning: trial.competitor.logisticsCostPerReconditioning,
  c_totalCostForReconditioning: trial.competitor.totalCostForReconditioning,
  c_toolLifeFactorReconditioning: trial.competitor.toolLifeFactorReconditioning,
  // Step 9
  c_totalToolAndAdditionalCostForSerie: trial.competitor.totalToolAndAdditionalCostForSerie,
  c_machiningTotalTime: trial.competitor.machiningTotalTime,
  c_machiningCost: trial.competitor.machiningCost,
  c_costPerBore: trial.competitor.costPerBore,
  // ROI Results
  totalToolCostDiff: trial.totalToolCostDiff,
  totalToolCostDiffPercent: trial.totalToolCostDiffPercent,
  savingOfMachiningTime: trial.savingOfMachiningTime,
  savingOfMachiningTimePercent: trial.savingOfMachiningTimePercent,
  savingPerMachiningTime: trial.savingPerMachiningTime,
  savingPerBore: trial.savingPerBore,
  savingPerBorePercent: trial.savingPerBorePercent,
  savingPerSerieOrYear: trial.savingPerSerieOrYear,
});
