import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-text-field',
  templateUrl: './input-text-field.component.html',
  styleUrls: ['./input-text-field.component.css']
})
export class InputTextFieldComponent implements OnInit {
  @Input() id: string;
  @Input() controlName: string;
  @Input() key: string;
  @Input() form: FormGroup;
  @Input() class = 'field';
  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() disableAutocomplete = false;
  @Input() hideErrors = false;
  @Input() hideLabel = false;
  @Input() readonly = false;
  @Input() list: string;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    if(this.list){
      setTimeout(() => {
        this.el.nativeElement.querySelector('input').setAttribute('list', this.list);
      });
    }

    if (this.disableAutocomplete) {
      setTimeout(() => {
        this.el.nativeElement.querySelector('input').setAttribute('autocomplete', 'off');
      });
    }
  }

}
