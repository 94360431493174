import { Notification } from '@/core/models/notification';
import { NotificationApiService } from '@/core/services/api/notification-api.service';
import { ResolverService } from '@/core/services/base/resolver.service';
import { NetworkStatusService } from '@/core/services/network/network-status.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class NotificationsService {
  public pendingNotificationsCount = new BehaviorSubject<number>(0);

  constructor(
    private notificationApiService: NotificationApiService,
    private resolverService: ResolverService,
    private networkStatusService: NetworkStatusService,
  ) { }

  readNotification(hash: string) {
    return this.notificationApiService.readNotification(hash).toPromise();
  }

  markNotificationDone(hash: string) {
    return this.notificationApiService.markNotificationDone(hash).toPromise();
  }

  markNotificationUndone(hash: string) {
    return this.notificationApiService.markNotificationUndone(hash).toPromise();
  }

  resolveNotifications(): Observable<Notification[]> {
    this.resolverService.onResolverStart();
    return this.notificationApiService.getNotifications()
      .pipe(
        catchError((error) => throwError(error)),
        finalize(this.resolverService.onResolverStop)
      );
  }

  refreshPendingNotificationsCount(): void {
    if (!this.networkStatusService.hasNetwork) {
      return;
    }

    this.notificationApiService.getNotifications().subscribe((notifications: Notification[]) => {
      this.updatePendingNotificationsCount(notifications);
    });
  }

  updatePendingNotificationsCount(notifications: Notification[]): void {
    const count = notifications.filter(n => n.isDone === false).length;
    this.pendingNotificationsCount.next(count);
  }
}
