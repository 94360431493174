import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule } from 'ng-pick-datetime-ex';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DragScrollModule } from 'ngx-drag-scroll';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { AutocompleteFieldComponent } from './components/forms/autocomplete-field/autocomplete-field.component';
import { CheckboxFieldComponent } from './components/forms/checkbox-field/checkbox-field.component';
import { DatepickerFieldComponent } from './components/forms/datepicker-field/datepicker-field.component';
import { FieldErrorsComponent } from './components/forms/field-errors/field-errors.component';
import { FileFieldComponent } from './components/forms/file-field/file-field.component';
import { FileInputComponent } from './components/forms/file-input/file-input.component';
import { InputEmailFieldComponent } from './components/forms/input-email-field/input-email-field.component';
import { InputFieldComponent } from './components/forms/input-field/input-field.component';
import { InputIntegerFieldComponent } from './components/forms/input-integer-field/input-integer-field.component';
import { InputNumberFieldComponent } from './components/forms/input-number-field/input-number-field.component';
import { InputPasswordFieldComponent } from './components/forms/input-password-field/input-password-field.component';
import { InputRangeFieldComponent } from './components/forms/input-range-field/input-range-field.component';
import { InputSearchFieldComponent } from './components/forms/input-search-field/input-search-field.component';
import { InputTextFieldComponent } from './components/forms/input-text-field/input-text-field.component';
import { InputTimeFieldComponent } from './components/forms/input-time-field/input-time-field.component';
import { RadioFieldComponent } from './components/forms/radio-field/radio-field.component';
import { SelectFieldComponent } from './components/forms/select-field/select-field.component';
import { TextareaFieldComponent } from './components/forms/textarea-field/textarea-field.component';
import { ChangePasswordModalComponent } from './components/modals/change-password-modal/change-password-modal.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { EditionModalComponent } from './components/modals/edition-modal/edition-modal.component';
import { FullscreenModalComponent } from './components/modals/fullscreen-modal/fullscreen-modal.component';
import { InfoModalComponent } from './components/modals/info-modal/info-modal.component';
import { PreviewModalComponent } from './components/modals/preview-modal/preview-modal.component';
import { YesNoModalComponent } from './components/modals/yes-no-modal/yes-no-modal.component';
import { SortControlComponent } from './components/sort-control/sort-control.component';
import { FormCellComponent } from './components/tables/form-table/form-cell/form-cell.component';
import { FormColumnComponent } from './components/tables/form-table/form-column/form-column.component';
import { FormTableColumnComponent } from './components/tables/form-table/form-table-column/form-table-column.component';
import { FormTableComponent } from './components/tables/form-table/form-table.component';
import { ColumnComponent } from './components/tables/sortable-table/column/column.component';
import { SortableCellComponent } from './components/tables/sortable-table/sortable-cell/sortable-cell.component';
import { SortableColumnComponent } from './components/tables/sortable-table/sortable-column/sortable-column.component';
import { SortableTableComponent } from './components/tables/sortable-table/sortable-table.component';
import { ConnectionStatusComponent } from './connection-status/connection-status.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { ColorMaterialDirective } from './directives/color-material.directive';
import { DigitDecimalNumberDirective } from './directives/digit-decimal-number.directive';
import { DragAndDropDirective } from './directives/drag-and-drop-directive';
import { HideOnProdDirective } from './directives/hide-on-prod.directive';
import { HideToExternalUsersDirective } from './directives/hide-to-external-users.directive';
import { HideWhenOfflineDirective } from './directives/hide-when-offline.directive';
import { HideByPermissionAndAccessLevelDirective } from './directives/hideByPermissionAndAccessLevel.directive';
import { HideIfCantEditProjectDirective } from './directives/hideIfCantEditProject.directive';
import { IntegerInputDirective } from './directives/integer-input.directive';
import { RequireReadAccessForAnySectionDirective } from './directives/require-read-access-for-any-section.directive';
import { ResultBackgroundColorDirective } from './directives/result-background-color.directive';
import { TitleDirective } from './directives/title.directive';
import { CapitalizeAllPipe } from './pipes/capitalize-all.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DayDiffPipe } from './pipes/day-diff.pipe';
import { IntLoopPipe } from './pipes/int-loop.pipe';
import { LocalizedDaysAgoPipe } from './pipes/localized-days-ago.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { TimePipe } from './pipes/time.pipe';
import { TruncatePipe } from './pipes/truncate-pipe';

@NgModule({
  declarations: [
    // Components
    AutocompleteFieldComponent,
    CheckboxFieldComponent,
    ColumnComponent,
    ConfirmModalComponent,
    ChangePasswordModalComponent,
    ConnectionStatusComponent,
    DatepickerFieldComponent,
    EditionModalComponent,
    FieldErrorsComponent,
    FileFieldComponent,
    FileInputComponent,
    FormCellComponent,
    FormColumnComponent,
    FormTableColumnComponent,
    FormTableComponent,
    FullscreenModalComponent,
    InfoModalComponent,
    InputEmailFieldComponent,
    InputFieldComponent,
    InputIntegerFieldComponent,
    InputNumberFieldComponent,
    InputPasswordFieldComponent,
    InputTextFieldComponent,
    InputTimeFieldComponent,
    InputRangeFieldComponent,
    InputSearchFieldComponent,
    PreviewModalComponent,
    RadioFieldComponent,
    SelectFieldComponent,
    SortableColumnComponent,
    SortableCellComponent,
    SortableTableComponent,
    SortControlComponent,
    TextareaFieldComponent,
    YesNoModalComponent,
    // Pipes
    CapitalizePipe,
    CapitalizeAllPipe,
    DayDiffPipe,
    IntLoopPipe,
    LocalizedDatePipe,
    LocalizedDaysAgoPipe,
    PercentagePipe,
    SortPipe,
    TimePipe,
    TruncatePipe,
    // Directives
    ColorMaterialDirective,
    DigitDecimalNumberDirective,
    DragAndDropDirective,
    HideOnProdDirective,
    IntegerInputDirective,
    TitleDirective,
    HideByPermissionAndAccessLevelDirective,
    HideIfCantEditProjectDirective,
    HideToExternalUsersDirective,
    RequireReadAccessForAnySectionDirective,
    BackButtonDirective,
    ResultBackgroundColorDirective,
    HideWhenOfflineDirective,
  ],
  imports: [
    CommonModule,
    DragScrollModule,
    NgSelectModule,
    NgxPaginationModule,
    NgxSmartModalModule.forChild(),
    OwlDateTimeModule,
    PdfViewerModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [
    // Modules
    CommonModule,
    DragScrollModule,
    NgxPaginationModule,
    NgxSmartModalModule,
    OwlDateTimeModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    // Components
    AutocompleteFieldComponent,
    CheckboxFieldComponent,
    ColumnComponent,
    ConnectionStatusComponent,
    DatepickerFieldComponent,
    EditionModalComponent,
    ChangePasswordModalComponent,
    FieldErrorsComponent,
    FileFieldComponent,
    FileInputComponent,
    FormTableColumnComponent,
    FormTableComponent,
    FullscreenModalComponent,
    InfoModalComponent,
    InputEmailFieldComponent,
    InputIntegerFieldComponent,
    InputNumberFieldComponent,
    InputPasswordFieldComponent,
    InputTextFieldComponent,
    InputTimeFieldComponent,
    InputRangeFieldComponent,
    InputSearchFieldComponent,
    PreviewModalComponent,
    RadioFieldComponent,
    SelectFieldComponent,
    SortableTableComponent,
    SortControlComponent,
    TextareaFieldComponent,
    ConfirmModalComponent,
    YesNoModalComponent,
    // Pipes
    CapitalizeAllPipe,
    CapitalizePipe,
    DayDiffPipe,
    IntLoopPipe,
    LocalizedDatePipe,
    LocalizedDaysAgoPipe,
    PercentagePipe,
    SortPipe,
    TimePipe,
    TruncatePipe,
    // Directives
    ColorMaterialDirective,
    DragAndDropDirective,
    HideOnProdDirective,
    IntegerInputDirective,
    TitleDirective,
    HideByPermissionAndAccessLevelDirective,
    HideIfCantEditProjectDirective,
    HideToExternalUsersDirective,
    RequireReadAccessForAnySectionDirective,
    BackButtonDirective,
    ResultBackgroundColorDirective,
    HideWhenOfflineDirective,
  ],
  providers: [
    // Pipes
    LocalizedDatePipe
  ]
})
export class SharedModule { }
