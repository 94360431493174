export const XLS_MIME_TYPE = 'application/vnd.ms-excel';

export const XLSX_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const EXCEL_MIME_TYPES = [ XLS_MIME_TYPE, XLSX_MIME_TYPE ];

export const toBlob = (data: ArrayBuffer) => new Blob([new Uint8Array(data)]);

export const  arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
