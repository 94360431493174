import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ACCESS_LEVEL_READ, ACCESS_LEVEL_WRITE } from 'src/app/core/config/access-level-constants';
import { PERMISSION_VIEW_ALL_PROJECTS } from 'src/app/core/config/permission-constants';
import { ProjectGroup } from 'src/app/core/models/project-group';
import { ProjectGroupCommercial } from 'src/app/core/models/project-group-commercial';
import { getUserCommercialId } from 'src/app/core/services/storage.service';
import { hasAccessLevelForSubsection } from 'src/app/core/utils/jwt.utils';

@Directive({
  selector: '[appHideIfCantEditProject]'
})
export class HideIfCantEditProjectDirective implements OnInit {
  @Input() projectGroup: ProjectGroup;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    if (this.isCommercialInProjectGroup(getUserCommercialId(), this.projectGroup) &&
      hasAccessLevelForSubsection(PERMISSION_VIEW_ALL_PROJECTS, ACCESS_LEVEL_READ)) {
      return;
    }

    if (hasAccessLevelForSubsection(PERMISSION_VIEW_ALL_PROJECTS, ACCESS_LEVEL_WRITE)) {
      return;
    }

    this.elementRef.nativeElement.remove();
  }

  private isCommercialInProjectGroup(commercialId: number | null, projectGroup: ProjectGroup) {
    if (commercialId === null) {
      return false;
    }

    return projectGroup?.commercials.some(
      (commercial: ProjectGroupCommercial) => commercial.id === commercialId
    );
  }
}
