import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UserBuilder } from '../../models/builder/user.builder';
import { User } from '../../models/User';

const getUserBody = (user: User, password: String, passwordRepeat: String) => ({
  firstName: user.firstName,
  lastName: user.lastName,
  nick: user.nick,
  email: user.email,
  privilegeHash: user.privilege.hash,
  password,
  passwordRepeat
});

@Injectable()
export class UserApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}users`;
  }

  deleteUser(hash: string) {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  getUsers(): Observable<User[]> {
    return this.httpClient.get(this.apiUrl)
      .pipe(
        map(UserBuilder.fromList)
      );
  }

  getUser(hash: string): Observable<User> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(UserBuilder.fromJson)
      );
  }

  updateUser(user: User) {
    return this.httpClient.put(`${this.apiUrl}/${user.hash}`, getUserBody(user, null, null));
  }

  createUser(user: User, password: String, passwordRepeat: String) {
    return this.httpClient.post(this.apiUrl, getUserBody(user, password, passwordRepeat))
      .pipe(
        map(UserBuilder.fromJson)
      );
  }
}
