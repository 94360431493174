import { Material } from '../material';
import { mapCollection } from '../../utils/collection.utils';

export class MaterialBuilder {
  static fromJson(json: any) {
    return (json)
    ? new Material(
        json.hash,
        json.group,
        json.subgroup,
        json.denomination
      )
      : null;
  }

  static fromList(list): Material[] {
    return Array.isArray(list)
      ? mapCollection(MaterialBuilder.fromJson, list)
      : [];
  }
}
