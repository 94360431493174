import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService {
  private onlineStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) {
    interval(10000).subscribe(() => {
      this.checkNetworkStatus();
    });
  }

  private checkNetworkStatus(): void {
    const headers = new HttpHeaders({
      cache: 'no-store'
    });

    this.http
      .head(`${environment.connectionCheckUrl}?nocache=${new Date().getTime()}`, { observe: 'response', headers })
      .pipe(
        map(() => true),
        catchError(() => of(false))
      )
      .subscribe(isOnline => {
        const currentStatus = this.onlineStatus.value;
        if (isOnline !== currentStatus) {
          this.updateOnlineStatus(isOnline);
        }
      });
  }

  private updateOnlineStatus(isOnline: boolean): void {
    this.onlineStatus.next(isOnline);
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'UPDATE_NETWORK_STATUS',
        payload: { isOffline: !isOnline },
      });
    }
  }

  get isOnline(): Observable<boolean> {
    return this.onlineStatus.asObservable();
  }

  get hasNetwork(): boolean {
    return this.onlineStatus.value;
  }
}
