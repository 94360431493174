import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgxSmartModalComponent } from 'ngx-smart-modal';

@Component({
  selector: 'app-yes-no-modal',
  templateUrl: './yes-no-modal.component.html'
})
export class YesNoModalComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() messageParams = {};
  @Input() cancelLabel = 'shared.yesNoModal.cancel';
  @Input() hideHeader = false;
  @Input() dismissable = true;
  @Input() escapable = true;
  @Input() modalClass = 'modal--yes-no';
  @Input() yesOptionLabel = 'shared.confirm.yes';
  @Input() noOptionLabel = 'shared.confirm.no';
  @Output() cancel = new EventEmitter<void>();
  @Output() yes = new EventEmitter<void>();
  @Output() no = new EventEmitter<void>();
  @ViewChild(NgxSmartModalComponent) modal: NgxSmartModalComponent;

  open() {
    this.modal.open();
  }

  close() {
    this.modal.close();
  }

  onClose() {
    this.cancel.emit();
  }

  onYes() {
    this.close();
    this.yes.emit();
  }

  onNo() {
    this.close();
    this.no.emit();
  }
}
