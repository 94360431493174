<table class="table" #table >
  <thead>
    <tr>
      <th
        *ngFor="let column of columns"
        appSortableColumn
        [title]="column.title"
        [icon]="column.icon"
        [property]="column.property"
        [type]="column.type"
        [sortable]="column.sortable"
        [defaultSorting]="column.defaultSorting"
        [selected]="selectedOrder"
        [style.width]="column.width"
        [style.min-width]="column.minWidth"
        (sort)="sort.emit($event)"
      ></th>
    </tr>
  </thead>
  <tbody class="scroll" perfectScrollbar>
    <tr *ngFor="let element of (elements | sort: selectedOrder)" [ngClass]="getRowClass(element)">
      <td
        *ngFor="let column of columns"
        [style.width]="column.width"
        [style.min-width]="column.minWidth"
        [ngClass]="column.styleColumn && column.styleColumn(element)"
        appTitle
      >
        <a *ngIf="column.linkable; else cell" (click)="column.clicked.emit(element)">
          <app-sortable-cell [column]="column" [element]="element"></app-sortable-cell>
        </a>
        <ng-template #cell>
          <app-sortable-cell [column]="column" [element]="element"></app-sortable-cell>
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>
