import { HttpParams } from '@angular/common/http';

/**
 * Encode special characters that can appear in Dropbox paths
 * and cause problems to the standard HttpParams encoding in GET methods.
 */
export const encodeDropboxUrl = (httpParams: HttpParams) =>
    httpParams.toString().replace(/\+/gi, '%2B');

export const encodeDropboxUrlString = (string: string) =>
  string.replace(/\+/gi, '%2B');
