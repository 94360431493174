import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDigitDecimalNumber]'
})
export class DigitDecimalNumberDirective {
  @Input() decimals: number;

  private allowedCharacteres: RegExp = new RegExp(/^\d|\.|\,/g);
  private specialKeys: string[] = [
    'Backspace', 'Tab', 'End', 'Home',
    'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight',
    'Cut', 'Copy', 'Paste', 'c', 'v', 'x', 'C', 'V', 'X'
  ];

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.el.nativeElement.type !== 'number') {
      return;
    }

    if (this.isSpecialCharacter(event.key) || this.isAllowedCharacter(event.key)) {
      return;
    }

    event.preventDefault();
  }

  @HostListener('blur', ['$event'])
  onBlur(event: KeyboardEvent) {
    if (this.decimals) {
      if (this.isSpecialCharacter(event.key)) {
        return;
      }
      const current: string = this.el.nativeElement.value;
      this.el.nativeElement.value = parseFloat(current).toFixed(this.decimals);
    }
  }

  private isSpecialCharacter(key: string): boolean {
    return this.specialKeys.indexOf(key) !== -1;
  }

  private isAllowedCharacter(key: string): boolean {
    return key.match(this.allowedCharacteres) != null;
  }
}
