import { CommercialActionBuilder } from '@/core/models/builder/commercial-action.builder';
import { CommercialAction } from '@/core/models/commercial-action';
import { CommercialActionApiService } from '@/core/services/api/commercial-action-api.service';
import { Injectable } from '@angular/core';
import { OfflineStorageService } from './storage/offline-storage.service';

@Injectable()
export class OfflineCommercialActionService {
  constructor(
    private offlineStorageService: OfflineStorageService,
    private commercialActionApiService: CommercialActionApiService,
  ) { }

  async getCollectionWithOfflineData(
    commercialActions: CommercialAction[],
    projectHash: string | null
  ): Promise<CommercialAction[]> {
    const updatedCommercialActions = [];

    commercialActions.forEach(async commercialAction => {
      updatedCommercialActions.push(await this.updateEntityWithOfflineEntityIfExists(commercialAction));
    });

    if (projectHash) {
      updatedCommercialActions.push(... await this.getOfflineEntitiesByProjectHash(projectHash));
    }

    return updatedCommercialActions;
  }

  async updateEntityWithOfflineEntityIfExists(commercialAction: CommercialAction): Promise<CommercialAction | null> {
    if (!commercialAction) {
      return null;
    }

    const storedCommercialAction = await this.offlineStorageService.findByHashAndType(
      commercialAction.hash,
      'commercial-action',
      CommercialActionBuilder.fromJson
    );

    return storedCommercialAction ?? commercialAction;
  }

  async getStoredEntity(commercialActionHash: string): Promise<CommercialAction | null> {
    return await this.offlineStorageService.findByHashAndType(
      commercialActionHash,
      'commercial-action',
      CommercialActionBuilder.fromJson
    );
  }

  async syncWithRemote() {
    // New commercial actions
    const newCommercialActions = await this.offlineStorageService.findByActionAndType(
      'commercial-action',
      'create',
      CommercialActionBuilder.fromJson
    );

    for (const commercialAction of newCommercialActions) {
      const commercialActionTemporalHash = commercialAction.hash;
      await this.commercialActionApiService.createCommercialAction(
        commercialAction,
        commercialAction.project?.hash,
        null,
        null
      ).toPromise();
      await this.offlineStorageService.deleteByHashAndType(commercialActionTemporalHash, 'commercial-action');
    }

    // Updated commercial actions
    const updatedCommercialActions = await this.offlineStorageService.findByActionAndType(
      'commercial-action',
      'update',
      CommercialActionBuilder.fromJson
    );

    for (const commercialAction of updatedCommercialActions) {
      const commercialActionHash = commercialAction.hash;
      await this.commercialActionApiService.updateCommercialAction(
        commercialAction,
        commercialAction.project?.hash
      ).toPromise();
      await this.offlineStorageService.deleteByHashAndType(commercialActionHash, 'commercial-action');
    }
  }


  private async getOfflineEntitiesByProjectHash(projectHash: string | null): Promise<CommercialAction[] | []> {
    const commercialActions = await this.offlineStorageService.findByActionAndType(
      'commercial-action',
      'create',
      CommercialActionBuilder.fromJson
    );
    return commercialActions.filter(
      (commercialAction: CommercialAction) => commercialAction.project?.hash === projectHash
    );
  }
}
