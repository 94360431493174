import { parseNumber } from '../../utils/number.utils';
import { ProjectGroupCommercial } from '../project-group-commercial';

export class ProjectGroupCommercialBuilder {
  static fromJson(json): ProjectGroupCommercial {
    return json ? new ProjectGroupCommercial(json.hash, json.name, parseNumber(json.id)) : null;
  }

  static fromList(list): ProjectGroupCommercial[] {
    return Array.isArray(list) ? list.map(ProjectGroupCommercialBuilder.fromJson) : [];
  }
}
