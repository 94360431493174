export const STORAGE_NAME = 'iraupen';
export const STORAGE_SYNC_TABLE_NAME = 'syncQueue';

export const STORED_ITEM_TYPES = [
  'commercial-action'
] as const;

export const STORED_ITEM_ACTIONS = [
  'create', 'update'
] as const;
