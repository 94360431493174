import { ForecastType } from '@/core/config/constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReportSerieBuilder } from '../../models/builder/report-serie.builder';
import { map } from 'rxjs/operators';
import { ReportSerie } from '../../models/report-serie';
import { Observable } from 'rxjs';

const updateForecastBody = (amount: number) => ({
  amount
});

@Injectable()
export class ForecastApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}forecasts`;
  }

  getForecast(type: ForecastType, year: number) {
    return this.httpClient.get(`${this.apiUrl}/${type}/${year}`)
      .pipe();
  }

  getForecastsBetweenYears(type: ForecastType, fromYear: number, toYear: number): Observable<ReportSerie> {
    let params = new HttpParams();

    params = params.append('fromYear', fromYear);
    params = params.append('toYear', toYear);

    return this.httpClient.get(`${this.apiUrl}/${type}/between-years`, { params }).pipe(
      map(ReportSerieBuilder.fromJson)
    );
  }

  updateForecast(type: ForecastType, year: number, amount: number) {
    return this.httpClient.post(`${this.apiUrl}/${type}/${year}`, updateForecastBody(amount));
  }
}
